import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState, useRef, createRef } from "react";
import { CardComponent } from "../Common/CardComponent";
import CustomSelect from "../Common/SelectComponent";
import CustomTextField from "../Common/TextFiledComponet";
import dashboardtopImg from "../../images/playerbanner.jpeg";
import Searchicon from "../../images/SearchIcon.svg";
import {
  getallCities,
  getallgroundData,
  removefavGround,
} from "../../firebaseFunction/groundDetails";
import { UpdateUserData, userData } from "../../firebaseFunction/userDetails";
import CustomButton from "../Common/ButtonComponent";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { findElementsWithSameProp } from "../../utils/helper";
import { getEventdetailsByType } from "../../firebaseFunction/eventDetails";
import { BookingCard } from "../CourtAdmin/BookingCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const gameTypes = [
  { label: "Cricket", value: "Cricket" },
  { label: "Badminton", value: "Badminton" },
  { label: "Football", value: "Football" },
  { label: "Volleyball", value: "Volleyball" },
  { label: "Hockey", value: "Hockey" },
  { label: "Basketball", value: "Basketball" },
  { label: "Archery", value: "Archery" },
  { label: "Tennis", value: "Tennis" },
  { label: "Table Tennis", value: "Table Tennis" },
  { label: "Softball", value: "Softball" },
];
function Dashboard() {
  const [groundData, setGroundData] = useState([]);
  const [cities, setCities] = useState([]);
  const [filterData, setFiletrData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationerror, setlocationerror] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [approvedBookings, setApprovedBookings] = useState([]);
  const [waitingdBookings, setWaitingdBookings] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(2);

  const [elApprovedRefs, setElApprovedRefs] = React.useState([]);
  const [elWaitingRefs, setElWaitingRefs] = React.useState([]);

  const today = new Date();

  let uid = localStorage.getItem("uid");

  const handleFav = async (data) => {
    if (uid == null || uid == undefined) {
      navigate("/login");
    }

    if (!data?.favarote) {
      const userDetails = userInfo;

      if (uid != null) {
        userDetails?.favarote
          ? userDetails?.favarote.push(data?.ground_id)
          : (userDetails.favarote = [data?.ground_id]);
        const updatedata = await UpdateUserData(userDetails, uid);
        if (updatedata?.status == "success") {
          console.log("update successfully");
          updateFavGrounds(updatedata.data);
        } else {
          console.error("update data failed");
        }
      }
    } else {
      const removedata = await removefavGround(uid, data?.ground_id);
      if (removedata?.status == "success") {
        updateFavGrounds(removedata.data);
      }
    }
  };

  const updateFavGrounds = (updatedUserData) => {
    filterData.forEach((item) => {
      item.favarote = updatedUserData?.favarote?.includes(item?.ground_id);
    });
  };

  const optionslocation = cities

    ?.sort((a, b) => {
      const cityA = a.cityName.toLowerCase();
      const cityB = b.cityName.toLowerCase();
      if (cityA < cityB) {
        return -1;
      }
      if (cityA > cityB) {
        return 1;
      }
      return 0;
    })
    .map((game, index) => ({
      value: game?.cityName?.toLowerCase(),
      label:
        game?.cityName[0]?.toUpperCase() +
        game?.cityName?.slice(1).toLowerCase(),
    }))
    .filter(
      (option, index, self) =>
        index === self.findIndex((t) => t.value === option.value)
    );
  const optionsGametype = groundData
    ?.map((game, index) => ({
      values: Array.isArray(game.game_type)
        ? [...new Set(game.game_type)]
        : [game.game_type],
      labels: Array.isArray(game.game_type)
        ? [...new Set(game.game_type)]
        : [game.game_type],
    }))
    .flatMap((game) =>
      game.values.map((value, index) => ({
        id: `${game.id}-${index + 1}`,
        value,
        label: game.labels[index],
      }))
    )
    .filter(
      (option, index, self) =>
        index === self.findIndex((t) => t.value === option.value)
    );

  const [selectedValue, setSelectedValue] = useState({
    location: "",
    date: today.toISOString().split("T")[0],
    gameType: "",
  });

  const handlelocationChange = (value) => {
    setSelectedValue({ ...selectedValue, location: value });
  };

  const handleGameChange = (value) => {
    setSelectedValue({ ...selectedValue, gameType: value });
  };

  const handleDataChange = (value) => {
    const today = new Date();
    const selectedDate = new Date(value);

    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < today.getTime()) {
      alert("Please select a future date.");
    } else {
      setSelectedValue({ ...selectedValue, date: value });
    }
  };

  const handleFilter = async () => {
    if (selectedValue?.location && selectedValue?.location.value != "") {
      setlocationerror(false);

      const response = await getallgroundData(
        userInfo,
        selectedValue.location?.value,
        selectedValue.gameType?.value,
        selectedValue.date
      );

      setFiletrData(response.value);
    } else {
      setlocationerror(true);
    }
  };

  const handleBookingClick = (index, item, status) => {};

  const getDashboardData = async () => {
    setLoading(true);
    await getLocation(true);
    let user;
    if (uid) {
      user = await userData(uid);
      setUserInfo(user);
      await eventData(uid);
    }

    // let city = selectedValue.location.value || user?.city;

    // if (city) {
    //   await fetchGroundData(user, city);
    // }

    // setSelectedValue({
    //   ...selectedValue,
    //   location: { label: _.capitalize(city), value: city },
    // });
  };

  const fetchGroundData = async (user, city) => {
    if (!loading && _.isEmpty(groundData)) {
      setLoading(true);
      let groundDataDetails = await getallgroundData(
        user,
        city,
        selectedValue.gameType?.value,
        selectedValue.date
      );

      let citieslist = await getallCities();

      groundDataDetails = groundDataDetails.value;
      setGroundData(groundDataDetails);
      setCities(citieslist);

      const data = groundDataDetails?.filter((item) => {
        let itemcity = item?.city;
        return city ? itemcity?.toLowerCase() == city?.toLowerCase() : true;
      });

      setFiletrData(data);
      setLoading(false);
    }
  };

  async function getLocation(loadData) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          await showPosition(position, loadData);
        },
        // () => {},
        async () => {
          const city = userInfo?.city;
          await fetchGroundData(userInfo, city);
        },
        { timeout: 5000 }
      );
    }
  }

  async function showPosition(position, loadData = false) {
    let city;
    if (position) {
      setLoading(true);
      const findcity = await fetch(
        `https://geocode.maps.co/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&api_key=660d28ba0465a124563952icn695655`
      );
      const addres = await findcity.json();
      let county = addres.address.county;
      city = county?.split(" ");
      city = city[0]?.toLowerCase();
    }

    if (loadData) {
      await fetchGroundData(userInfo, city);
    }
    if (position) {
      handlelocationChange({
        label: city[0].toUpperCase() + city.slice(1).toLowerCase(),
        value: city.toLowerCase(),
      });
    }
  }

  const eventData = async (userId) => {
    if (userId == null) {
      return;
    }
    setLoading(true);
    let startDate = moment().format("YYYY-MM-DDTHH:mm");
    let endOfMonth = moment().endOf("month").format("YYYY-MM-DDTHH:mm");

    let statusValue = ["Accepted", "Awaiting"];

    const otherFilters = [
      { key: "status", operator: "in", value: statusValue },
      { key: "start", operator: ">=", value: startDate },
      { key: "end", operator: "<=", value: endOfMonth },
    ];
    if (otherFilters && otherFilters.length > 0 && userId !== null) {
      const response = await getEventdetailsByType(
        userId,
        "user",
        { key: "start", dir: "asc" },
        null,
        otherFilters
      );
      const events = response?.data;

      if (events && events.length > 0) {
        const appBookings = findElementsWithSameProp(
          events.filter((item) => item.status === "Accepted")
        );
        const awaitBookings = findElementsWithSameProp(
          events.filter((item) => item.status === "Awaiting")
        );

        setElApprovedRefs((elApprovedRefs) =>
          Array(appBookings.length)
            .fill()
            .map((_, i) => elApprovedRefs[i] || createRef())
        );

        setElWaitingRefs((elWaitingRefs) =>
          Array(awaitBookings.length)
            .fill()
            .map((_, i) => elWaitingRefs[i] || createRef())
        );

        setApprovedBookings(appBookings);
        setWaitingdBookings(awaitBookings);
      }
      // setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const navigate = useNavigate();
  const isowner = localStorage.getItem("isowner");

  useEffect(() => {
    if (uid != null) {
      if (isowner == "true" || !uid) {
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <Box className="bg-[#FAFAFA] , fontFamily:'Outfit' ">
      <Box
        sx={{
          width: "100%",
          height: {
            md: "auto",
            xs: 430,
          },
        }}
      >
        <img src={dashboardtopImg} className="h-[280px] w-[100%]" alt="" />
        <Box
          sx={{
            display: "flex",
            flexWrap: { sm: "nowrap", xs: "wrap" },
            mx: "auto",
            padding: 1,
            borderRadius: "9px",
            backgroundColor: "#fff",
            width: { sm: "75%", xs: "90%" },
            position: "relative",
            bottom: {
              md: "50px",
              xs: 240,
            },
            boxShadow: 3,
            justifyContent: "center",
          }}
        >
          <div className=" block w-[100%]">
            <CustomSelect
              options={optionslocation}
              value={selectedValue?.location}
              onChange={handlelocationChange}
              label="Location"
              name="location"
              labelPosition="block"
              width="100%"
              style={{ padding: "0", height: "50px", marginBottom: "10px" }}
              placeholder={"Choose location"}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
            />
            {locationerror ? (
              <p className=" text-left text-[red] text-[14px]">
                Need to enter the loaction
              </p>
            ) : (
              ""
            )}
          </div>
          <CustomTextField
            width="100%"
            label="Date"
            type="date"
            height={10}
            labelPosition="block"
            value={selectedValue?.date}
            onChange={handleDataChange}
            placeholder={"Choose Date"}
            sx={{ border: "none", "& fieldset": { border: "none" } }}
          />
          <CustomSelect
            options={optionsGametype.length > 0 ? optionsGametype : gameTypes}
            value={selectedValue?.gameType}
            onChange={handleGameChange}
            label="Game Type"
            name="game_type"
            labelPosition="block"
            width="100%"
            style={{ padding: "0", height: "50px" }}
            placeholder={"Choose your game type"}
            sx={{ border: "none", "& fieldset": { border: "none" } }}
          />

          <Box
            onClick={() => handleFilter()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#097E52",
                borderRadius: "12px",
                width: "64px",
                height: "64px",
                alignItems: "center !important",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer ",
                display: { xs: "none", sm: "hidden" },
              }}
            >
              <img
                src={Searchicon}
                alt="search icon"
                className="w-[27px] h-[27px] "
              />
            </Box>
            <CustomButton
              text={"Search"}
              // icon={Searchicon}
              // iconWidth={"15px"}
              // iconPosition={"end"}
              onClick={() => handleFilter()}
              sx={{
                backgroundColor: "#097E52",
                borderRadius: "12px",
                width: "104px",
                height: "44px",
                color: "white",
                cursor: "pointer ",
                display: { xs: "hidden", sm: "none" },
                "&:hover": {
                  backgroundColor: "#097E52",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <div className=" w-[90%] mx-auto">
        {waitingdBookings?.length || approvedBookings?.length ? (
          <div className="grid min-[526px]:justify-center md:mx-0 md:grid-cols-2 lg:grid-cols-2 gap-3 mb-5">
            {approvedBookings?.length ? (
              <div>
                <p className=" text-[#192335] text-[30px] font-medium text-left">
                  Upcoming Bookings
                </p>
                <div className="flex flex-row md:h-[150px] md:w-[450px] w-[100%]">
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    emulateTouch={true}
                    centerMode={true}
                    showStatus={false}
                    onClickItem={(index, item) =>
                      handleBookingClick(index, item, "approved")
                    }
                    centerSlidePercentage={100}
                  >
                    {approvedBookings?.map((item, index) => {
                      // const bookingRef = useRef();
                      return (
                        <BookingCard
                          key={index}
                          bookingItem={item}
                          type={"Accepted"}
                          showMenu={false}
                          showShort={true}
                          ref={elApprovedRefs[index]}
                        />
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            ) : null}
            {waitingdBookings?.length ? (
              <div>
                <p className=" text-[#192335] text-[30px] font-medium text-left">
                  Pending Bookings
                </p>
                <div className="flex flex-row md:h-[150px] md:w-[450px] w-[100%]">
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    emulateTouch={true}
                    centerMode={false}
                    showStatus={false}
                    centerSlidePercentage={100}
                  >
                    {waitingdBookings?.map((item, index) => (
                      <BookingCard
                        key={index}
                        bookingItem={item}
                        type={"Awaiting"}
                        isAdmin={false}
                        showShort={true}
                      />
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {loading ? (
          <div className="justify-center items-center flex mb-8 h-[20vh]">
            <CircularProgress style={{ color: "#018352" }} />
          </div>
        ) : (
          <div className="mb-10">
            {filterData.length != 0 ? (
              <div>
                <div className="flex justify-center md:justify-start gap-5 items-center mt-7">
                  <p className=" text-[#192335] text-[30px] font-medium text-left">
                    Arenas Near You
                  </p>
                </div>
                <div
                  className={`grid lg:grid-cols-3 md:gap-1 gap-3 mt-3 sm:grid-cols-2 md:justify-items-start justify-items-center pb-10`}
                >
                  {filterData?.map((item, index) => (
                    <CardComponent
                      key={index}
                      data={item}
                      onClick={handleFav}
                      gameType={selectedValue.gameType}
                      type={"user"}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="h-[200px] m-auto pt-8 flex flex-col items-center">
                {" "}
                No grounds in this city
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}

export default Dashboard;
