import { ToastContainer } from "react-toastify";
import "./App.css";
import AppRouter from "./AppRouter";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Outfit",
      textTransform: "none",
      fontSize: 16,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App !font-Outfit">
        <AppRouter />
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
