import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Stack } from "@mui/material";
import profileIcon from "../images/profileIcon.svg";
import BookingIcon from "../images/myBookingIcon.svg";
import FavIcon from "../images/favIcon.svg";
import LogoutIcon from "../images/logoutIcon.svg";
import { userData } from "../firebaseFunction/userDetails";
import { useNavigate } from "react-router-dom";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetail, setuserDetail] = useState({});
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    if (uid == null) {
      navigate("/login");
    } else {
      if (userDetail?.owner) {
        navigate("/courtadmin/profile");
      } else {
        navigate("/players/profile");
      }
    }
  };
  const handleMyFavarote = () => {
    if (uid == null) {
      navigate("/login");
    } else {
      navigate("/players/myfavourite");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("uid");
    localStorage.removeItem("isowner");
    navigate("/login");
  };

  const handleMybooking = () => {
    if (uid == null) {
      navigate("/login");
    } else {
      if (userDetail.owner) {
        navigate("/courtadmin/bookingdetails");
      } else {
        navigate("/players/mybooking");
      }
    }
  };
  let uid = localStorage.getItem("uid");
  async function profileDetail() {
    const userProfile = await userData(uid);
    Promise.resolve();
    console.log(userProfile, "userProfile");
    if (userProfile?.status === "failure") {
      return;
    } else {
      setuserDetail(userProfile);
    }
  }
  useEffect(() => {
    profileDetail();
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 54, height: 54 }}
              src={userDetail?.profileimg}
            ></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userDetail && (
          <>
            <MenuItem
              onClick={handleClose}
              sx={{
                bgcolor: "#F6F6F6",
                mt: "-1 !important",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Avatar src={userDetail?.profileimg} />
              <Stack direction={"column"}>
                <Typography>
                  {userDetail ? userDetail.username : "Profile"}
                </Typography>
                <Typography>
                  {userDetail ? userDetail.email : "profile@email.com"}
                </Typography>
              </Stack>
            </MenuItem>
            {userDetail?.owner ? (
              <>
                <MenuItem onClick={handleProfile}>
                  <img
                    className="w-[25px] h-[25px] mr-5 my-1"
                    src={profileIcon}
                    alt="icon"
                  />
                  My Profile
                </MenuItem>
                <Divider />
              </>
            ) : (
              <>
                <MenuItem onClick={handleProfile}>
                  <img
                    className="w-[25px] h-[25px] mr-5 my-1"
                    src={profileIcon}
                    alt="icon"
                  />
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleMybooking}>
                  <img
                    className="w-[25px] h-[25px] mr-5 my-1"
                    src={BookingIcon}
                    alt="icon"
                  />
                  My Bookings
                </MenuItem>
                <MenuItem onClick={handleMyFavarote}>
                  <img
                    className="w-[25px] h-[25px] mr-5 my-1"
                    src={FavIcon}
                    alt="icon"
                  />
                  My Favourite Court
                </MenuItem>
                <Divider />
              </>
            )}
          </>
        )}
        {uid == null ? (
          <MenuItem onClick={() => navigate("/login")}>
            <img
              className="w-[25px] h-[25px] mr-5 my-1"
              src={LogoutIcon}
              alt="icon"
            />
            Login
          </MenuItem>
        ) : (
          <MenuItem onClick={handleLogout}>
            <img
              className="w-[25px] h-[25px] mr-5 my-1"
              src={LogoutIcon}
              alt="icon"
            />
            Logout
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}
