import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import tickIocn from "../../images/TickIcon.svg";
import CustomButton from "../Common/ButtonComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getTimeFormatted } from "../Common/GetHours";
import Reactangle1 from "../../images/Rectangle1.svg";
import Reactangle2 from "../../images/Rectangle2.svg";
import Reactangle3 from "../../images/Rectangle3.svg";
import Reactangle4 from "../../images/Rectangle4.svg";
import locationicon from "../../images/locationIocn.svg";
import phoneIcon from "../../images/phoneIcon.svg";
import mailIcon from "../../images/mailIcon.svg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  getCourtSlotsByDate,
  getcourtslotdata,
  getgroundDataById,
} from "../../firebaseFunction/groundDetails";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AddCartdata,
  GetcartDataById,
  createNewEvent,
  getEventdetailsByCourt,
  getcourtevent,
  removeCartData,
  separateConsecutiveSecondElements,
} from "../../firebaseFunction/eventDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ArrowBack } from "@mui/icons-material";
import _, { keyBy } from "lodash";
import LoginModal from "../Login/loginmodal";
import SignupModal from "../signup/Signupmodal";
import moment from "moment";

function Booking() {
  const [tab, setTab] = useState("Book Slot");
  const { state } = useLocation();
  const { ground_id, gameType } = state;
  const [groundData, setGroundData] = useState();
  const [courtTime, setCourtTime] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [isloginmodal, setisloginmodal] = useState(true);

  const navigate = useNavigate();
  const [modalopen, setModalOpen] = useState(false);
  const [loginmodalopen, setLoginModalOpen] = useState(false);
  const [duplicateopen, setduplicateopen] = useState(false);
  const [paymodalopen, setPayModalOpen] = useState(false);
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState({
    Courts: "Select court",
    date: today.toISOString().split("T")[0],
    gameType: "Select game",
  });
  const [AccordionOpen, setAccordionOpen] = useState(false);
  const [filteredCourts, setFilterdCourts] = useState([]);

  const [alreadybooked, setAlreadybooked] = useState([]);
  const [bookedPop, setBookedpop] = useState(false);

  const todaydate = new Date().toISOString().split("T")[0];
  const handleChange = async (value) => {
    setTab(value);
    setSelectedValue({
      Courts: "Select court",
      date: today.toISOString().split("T")[0],
      gameType: "Select game",
    });

    getCourttime();
  };

  let optionsGameTypes = groundData?.game_type?.map((game, index) => ({
    id: index + 1,
    value: game,
    label: game,
  }));

  let optionsCourts = groundData?.court_details?.map((court, index) => ({
    id: index + 1,
    value: court.court_id,
    label: court.court_name,
    gameType: court.gametype,
  }));

  const handleReset = () => {
    setSelectedValue({
      Courts: "Select court",
      date: today.toISOString().split("T")[0],
      gameType: "Select game",
    });
    setCartData([]);
    setFilterdCourts([]);
    getCourttime();
  };
  const handleCartReset = async () => {
    setSelectedValue({
      Courts: "Select court",
      date: today.toISOString().split("T")[0],
      gameType: "Select game",
    });
    setCartData([]);
    cartData?.forEach(async (OuterItem) => {
      OuterItem?.forEach(async (InnerItem) => {
        await removeCartData(InnerItem.cart_id);
      });
    });
  };

  const handlePayment = async () => {
    if (cartData.flat().length >= 1) {
      setPayModalOpen(true);
    }
  };

  const handlepayment = async () => {
    let bookedDataList = [];
    const promises = cartData.map(async (item) => {
      let dat = await getcourtevent(item.court_id);
      let bookedData = dat?.filter((data) => data?.start === item.start);

      return bookedData;
    });
    const results = await Promise.all(promises);
    bookedDataList = results.flat();

    if (bookedDataList?.length) {
      setAlreadybooked(bookedDataList);
      setBookedpop(true);
      setPayModalOpen(false);
    } else {
      const response = await createNewEvent(cartData);

      if (response?.status == "Success") {
        handleReset();
        toast.success("Booking Success", {
          position: "top-right",
          autoClose: 2000,
        });

        grounddata();
        setPayModalOpen(false);
        setTimeout(() => {
          navigate("/players/mybooking");
        }, 3000);
      } else {
        toast.error("Booking Failed", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
  };

  const handleAddCart = async () => {
    if (uid == null) {
      // navigate("/login");
      setLoginModalOpen(true);
      return;
    }

    let data = courtTime?.filter((item) => item?.selected == true);

    if (
      selectedValue?.Courts != "Select court" &&
      // selectedValue?.date != today.toISOString().split("T")[0] &&
      selectedValue?.gameType != "Select game" &&
      data.length != 0
    ) {
      selectedValue.selectedSlot = data;
      let Addcartdatas = [];
      const courtDetails = groundData?.court_details?.filter(
        (item) => item.court_id == selectedValue?.Courts
      );
      selectedValue.courtDetails = courtDetails;

      data.forEach((slot) => {
        let cartItem = {
          court_id: selectedValue?.Courts,
          court_name: courtDetails[0]?.court_name,
          ground_id: courtDetails[0]?.ground_id,
          user_id: uid,
          amount: courtDetails[0]?.default_amount,

          start: `${selectedValue?.date}T${new Date(
            slot.start
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}`,
          end: `${selectedValue?.date}T${new Date(slot.end).toLocaleTimeString(
            "en-US",
            { hour: "2-digit", minute: "2-digit", hour12: false }
          )}`,
          gametype: selectedValue?.gameType,
          mapIndexx: `${selectedValue?.date.split("-")[2]}${
            selectedValue?.date.split("-")[1]
          }${selectedValue?.mapIndex}`,
        };
        Addcartdatas.push(cartItem);
      });

      let arrY = Addcartdatas?.map((cartitem) => {
        return cartData?.map((item) => {
          return item.map((inner) => {
            let tee = includesfn(inner, cartitem);
            return tee;
          });
        });
      });
      let arrX = arrY.map((arrYitem) => {
        return arrYitem.map((arrYinneritem) => {
          let returnvalue = arrYinneritem.includes(true);
          return returnvalue;
        });
      });
      let arrZ = arrX.map((arrXitem) => {
        let returnvalue = arrXitem.includes(true);
        return returnvalue;
      });

      if (arrZ.includes(true)) {
        console.error("Selected time is already in cart", "Addcartdatas");
        setduplicateopen(true);
      } else {
        setModalOpen(true);
      }
    } else {
      console.log("select appropriate values");
    }
  };

  const includesfn = (fn1, fn2) => {
    if (fn1.gametype == fn2.gametype) {
      if (fn1.court_name == fn2.court_name) {
        if (fn1.start === fn2.start) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function charToAlphabetPosition(char) {
    return char.toLowerCase().charCodeAt(0) - "a".charCodeAt(0) + 1;
  }

  function stringToAlphabetPositions(inputString) {
    inputString = inputString.toLowerCase();

    let result = "";
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i].match(/[a-z]/)) {
        result += charToAlphabetPosition(inputString[i]);
      }
    }

    return Number(result);
  }

  const handleAddcart = async () => {
    let data = courtTime?.filter((item) => item?.selected == true);

    if (
      selectedValue?.Courts != "" &&
      selectedValue?.date != "" &&
      selectedValue?.gameType != "" &&
      data.length != 0
    ) {
      selectedValue.selectedSlot = data;
      let Addcartdatas = [];
      const courtDetails = groundData?.court_details?.filter(
        (item) => item.court_id == selectedValue?.Courts
      );
      selectedValue.courtDetails = courtDetails;
      let courtNo;
      if (typeof selectedValue?.Courts.match(/\d+/g) == null) {
        courtNo = selectedValue?.Courts.match(/\d+/g).join("");
      } else {
        courtNo = stringToAlphabetPositions(selectedValue?.Courts.slice(0, 5));
      }
      let courtslot = await getcourtslotdata(selectedValue?.Courts, "user");

      data.forEach((slot) => {
        let cartItem = {
          court_id: selectedValue?.Courts,
          court_name: courtDetails[0]?.court_name,
          ground_id: courtDetails[0]?.ground_id,
          user_id: uid,
          amount: courtDetails[0]?.default_amount,
          start: `${selectedValue?.date}T${new Date(
            slot.start
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}`,
          end: `${selectedValue?.date}T${new Date(slot.end).toLocaleTimeString(
            "en-US",
            { hour: "2-digit", minute: "2-digit", hour12: false }
          )}`,
          gametype: selectedValue?.gameType,
          mapIndexx: Number(
            `${courtNo}${selectedValue?.date.split("-")[2]}${
              selectedValue?.date.split("-")[1]
            }${Number(slot.mapIndex) + 1}`
          ),
        };
        let currentslot = courtslot?.slotData?.find((item) => {
          return (
            (new Date(item.start) < new Date(cartItem.start) &&
              new Date(item.end) < new Date(cartItem.start)) == false &&
            (new Date(item.start) > new Date(cartItem.end) &&
              new Date(item.end) > new Date(cartItem.end)) == false
          );
        });
        if (currentslot) {
          cartItem.amount = currentslot.price;
        }

        Addcartdatas.push(cartItem);
      });

      const cartdata = await AddCartdata(Addcartdatas);

      if (cartdata?.status == "success") {
        const sepfunc = separateConsecutiveSecondElements(cartdata?.data);
        setTab("Cart");

        setCartData(sepfunc);
      }
      setModalOpen(false);
    }
  };

  const handleGamechange = (value) => {
    setSelectedValue({ ...selectedValue, gameType: value });
    let filteredcourt = optionsCourts?.filter((court) => {
      return court?.gameType?.includes(value);
    });

    setFilterdCourts(filteredcourt);
  };

  const handleDateChange = async (value) => {
    value = value.target.value;
    const today = new Date();
    const selectedDate = new Date(value);

    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < today.getTime()) {
      alert("Please select a future date.");
    } else {
      setSelectedValue({ ...selectedValue, date: value });
      setCurrentDate(value);

      let date = groundData?.court_details?.filter((item) =>
        item?.fullCourtDates?.includes(value)
      );
      let courtids = [];
      date?.map((item) => {
        courtids.push(item.court_id);
      });

      let filtercourt = filteredCourts?.filter(
        (item) => courtids.includes(item.value) == false
      );
      setFilterdCourts(filtercourt);
    }
  };
  const handleCourtChange = async (value) => {
    setSelectedValue({ ...selectedValue, Courts: value });
  };

  const handleRemove = async (Oindex, index) => {
    if (index >= 0 && index < cartData?.length) {
      const updatedCartData = [...cartData];
      await removeCartData(updatedCartData[Oindex][index].cart_id);
      updatedCartData[Oindex].splice(index, 1);

      setCartData(updatedCartData);
      handleChange("Book Slot");
      handleChange("Cart");
    } else {
      console.error("Invalid index or index out of range.");
    }
  };

  const TotalCartPrice = (cartlist) => {
    return cartlist.flat().reduce((sum, obj) => sum + Number(obj.amount), 0);
  };

  const getCourttime = async () => {
    const date = selectedValue?.date;
    let start;
    let end;
    let currentDate = new Date();
    let currentTime = currentDate.getHours();
    let courtslot = await getCourtSlotsByDate(selectedValue?.Courts, date);

    let startDate = moment(date).format("YYYY-MM-DDTHH:mm");
    let endOfMonth = moment(date).endOf("day").format("YYYY-MM-DDTHH:mm");

    let statusValue = ["Accepted", "Awaiting"];

    const otherFilters = [
      { key: "status", operator: "in", value: statusValue },
      { key: "start", operator: ">=", value: startDate },
      { key: "end", operator: "<=", value: endOfMonth },
    ];

    const eventResponse = await getEventdetailsByCourt({
      courtIds: [selectedValue?.Courts],
      otherFilters,
    });

    const eventDataByDate = eventResponse?.data || [];

    if (typeof date === "object") {
      if (
        new Date(currentDate).toISOString().split("T")[0] ===
        new Date(date).toISOString().split("T")[0]
      ) {
        start =
          currentTime > parseInt(groundData?.start_time.slice(0, 2))
            ? `${date.getFullYear()}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${date
                .getDate()
                .toString()
                .padStart(2, "0")}T${currentTime
                .toString()
                .padStart(2, "0")}:00`
            : `${date.getFullYear()}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${date
                .getDate()
                .toString()
                .padStart(2, "0")}T${groundData?.start_time}`;
      } else {
        start = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
          groundData?.start_time
        }`;
      }

      end = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
        groundData?.end_time
      }`;
    } else {
      if (new Date(currentDate).toISOString().split("T")[0] === date) {
        start =
          currentTime > parseInt(groundData?.start_time.slice(0, 2))
            ? `${date}T${currentTime.toString().padStart(2, "0")}:00`
            : `${date}T${groundData?.start_time}`;
      } else {
        start = `${date}T${groundData?.start_time}`;
      }
      end = `${date}T${groundData?.end_time}`;
    }

    let daystart = new Date(start);
    let dayend = new Date(end);

    let hourdiff = ((dayend - daystart) / (1000 * 60 * 60)).toFixed(1);

    let eventss = [];
    let starttime = daystart;

    const default_amount = groundData?.court_details?.find(
      (c) => c.court_id === selectedValue?.Courts
    )?.default_amount;

    for (let j = 0; j < hourdiff; j++) {
      let en;
      en = {
        backgroundColor: "#fff",
        bordercolor: "#339A49",
        textColor: "#339A49",
        zIndex: "1",
        selected: false,
        isbooked: false,
        amount: "0",
      };
      en.start = new Date(starttime.getTime() + 1);
      en.end = new Date(starttime.getTime() + 60 * 60 * 1000);

      let currentslot = courtslot?.slotData?.find((item, index) => {
        let eventstart = new Date(en.start);
        let eventend = new Date(en.end);
        const itemstart = new Date(item.start);
        const itemend = new Date(item.end);
        eventstart = new Date(eventstart.setMilliseconds(0));
        eventend = new Date(eventend.setMilliseconds(0));

        return itemstart < eventend && itemend > eventstart;
      });

      if (currentslot) {
        en.amount = currentslot.price;
      } else {
        en.amount = default_amount;
      }

      eventss.push(en);
      starttime = new Date(en.end.getTime() + 1);
    }

    let eventarr = eventss?.map((court) => {
      eventDataByDate.length &&
        eventDataByDate?.map((event) => {
          let date1 = new Date(event?.start);
          let date2 = new Date(court?.start);

          if (
            Math.abs(date1.getTime() - date2.getTime()) <= 1000 &&
            event.status != "Canceled" &&
            event.status != "Cancelled" &&
            event.status != "Unblocked"
          ) {
            court.isbooked = true;
            court.backgroundColor = "#EEF0F3";
            court.textColor = "#64627C";
            court.bordercolor = "#64627C";
          }
        });

      if (
        new Date(court.start).getHours() == currentTime &&
        new Date(court.start).getDate() == currentDate.getDate()
      ) {
        court = {
          ...court,

          color: "#FFF",
          backgroundColor: "#FFF",
          bordercolor: "#00b4d8",
          textColor: "#00b4d8",
        };
      }
      return court;
    });

    setCourtTime(eventarr);
    setAccordionOpen(true);
  };

  const handlebooking = (value, index) => {
    const updatedCourtTime = [...courtTime];

    const currentTime = new Date();
    const currentTimehour = new Date().getHours();
    if (value.start <= currentTime) {
      alert("This booking time has already passed.");
      return;
    } else {
      updatedCourtTime[index] = {
        ...updatedCourtTime[index],
        mapIndex: index,
        selected: !updatedCourtTime[index].selected,
        color: !updatedCourtTime[index].selected ? "#FFF" : "#339A49",
        backgroundColor: !updatedCourtTime[index].selected ? "#339A49" : "#FFF",
        textColor: !updatedCourtTime[index].selected ? "#FFF" : "#339A49",
      };
      setCourtTime(updatedCourtTime);
    }
  };

  const grounddata = async () => {
    let groundData = await getgroundDataById(ground_id, "user", uid);

    setGroundData(groundData);

    const cartValue = await GetcartDataById(uid, ground_id);

    optionsGameTypes = groundData?.game_type?.map((game, index) => ({
      id: index + 1,
      value: game,
      label: game,
    }));

    optionsCourts = groundData?.court_details?.map((court, index) => ({
      id: index + 1,
      value: court.court_id,
      label: court.court_name,
      gameType: court.gametype,
    }));

    if (gameType) {
      handleGamechange(gameType);
    }

    const sepfunction = separateConsecutiveSecondElements(cartValue);
    setCartData(sepfunction);
  };

  useEffect(() => {
    grounddata();
  }, []);

  useEffect(() => {
    getCourttime();
  }, [selectedValue.Courts, selectedValue.date]);

  let isowner = localStorage.getItem("isowner");
  useEffect(() => {
    if (uid != null) {
      if (isowner == "true") {
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <Box sx={{ fontFamily: "Outfit" }} className="outfit-font">
      <div
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 pl-16">
          <div>
            <h1 className="text-[30px] font-[500] text-white">My Booking</h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <Link to="/">
              <p>Home</p>
            </Link>
            <p>{">"}</p> <p>{tab}</p>
          </div>
        </div>
      </div>
      <Box sx={{ width: "100%", p: 5 }}>
        <Typography
          sx={{
            display: "flex",
            fontSize: "30px",
            fontWeight: 600,
            color: "#192335",
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          {_.startCase(groundData?.groundname)}
          <img className="ml-2" src={tickIocn} alt="tixckIcon" />
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: { sm: "nowrap", xs: "wrap" },
            my: 2,
          }}
          gap={3}
        >
          <Stack direction={"row"}>
            <img src={locationicon} alt="icon" />
            <Typography
              sx={{
                color: "#8E94A2",
                fontSize: "12px",
                ml: 1,
                whiteSpace: "nowrap",
              }}
            >
              {groundData?.street_address}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <img src={phoneIcon} alt="icon" />
            <Typography sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}>
              {groundData?.phonenumber}
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <img src={mailIcon} alt="icon" />
            <Typography sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}>
              {groundData?.email}
            </Typography>
          </Stack>
        </Box>

        <Typography
          sx={{
            mt: 2,
            color: "#313A4A",
            backgroundColor: "#FAFAFA",
            borderRadius: "22px",
            p: 1,
            width: { sm: "550px" },
          }}
        >
          {`Thanks for choosing ${groundData?.groundname}. We are happy to see you here`}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#FAFAFA", pb: { sm: 3, xs: 1 } }}>
        <Box>
          <Stack
            direction={"row"}
            gap={5}
            sx={{
              ml: { sm: 5 },
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <CustomButton
              text={"Book Slot"}
              onClick={() => handleChange("Book Slot")}
              sx={
                tab == "Book Slot"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#192335" },
                    }
                  : {
                      backgroundColor: "#FFFFFF",
                      color: "#6B7385",
                      borderRadius: "8px",
                      "&:hover": { bgcolor: "#F9F9F9" },
                    }
              }
            />
            <CustomButton
              text={"Cart"}
              onClick={() => handleChange("Cart")}
              sx={
                tab == "Cart"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#192335" },
                    }
                  : {
                      backgroundColor: "#FFFFFF",
                      color: "#6B7385",
                      borderRadius: "8px",
                      "&:hover": { bgcolor: "#F9F9F9" },
                    }
              }
            />
          </Stack>
        </Box>
        {tab != "Cart" ? (
          <Box sx={{ width: "100%", p: { sm: 5, xs: 2 } }}>
            <Box>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Basic Info</Typography>
                </AccordionSummary>
                <Divider variant="middle" />
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 4,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor={"gameselect"}
                      variant="subtitle1"
                      component="div"
                      sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
                    >
                      Game Type
                    </InputLabel>
                    <FormControl
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                        backgroundColor: "#F9F9F9",
                        borderRadius: "4px",
                        color: "#757C8D",
                        fontSize: "14px",
                        fontFamily: "Outfit",
                        width: "100%",
                      }}
                    >
                      <Select
                        id="gameselect"
                        value={selectedValue?.gameType}
                        onChange={(e) => handleGamechange(e.target.value)}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                          backgroundColor: "#FAFAFA",
                          borderRadius: "8px",
                          textAlign: "start",
                        }}
                      >
                        {optionsGameTypes?.map((game) => {
                          return (
                            <MenuItem value={game.value}>{game.label}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                  <div className="w-full">
                    <div
                      className="text-left font-[600] mb-1 text-[#00000099] "
                      // sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
                    >
                      Date
                    </div>
                    <input
                      type="date"
                      id="datePicker"
                      min={todaydate} // Set minimum date to today
                      value={selectedValue?.date}
                      onChange={handleDateChange}
                      placeholder={"Choose Date"}
                      className="text-left text-[16px] bg-[#fafafa] w-full px-[17px] py-[14px] rounded-[4px] mt-1 "
                      sx={{
                        border: "none",
                        width: "100%",
                        heigth: "10px",
                        "& fieldset": { border: "none" },
                        backgroundColor: "#FAFAFA",
                        borderRadius: "8px",
                        textAlign: "start",
                      }}
                    />
                  </div>

                  <Box sx={{ width: "100%" }}>
                    <InputLabel
                      htmlFor={"courtselect"}
                      variant="subtitle1"
                      component="div"
                      sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
                    >
                      Courts
                    </InputLabel>
                    <FormControl
                      sx={{
                        border: "none",
                        "& fieldset": { border: "none" },
                        backgroundColor: "#F9F9F9",
                        borderRadius: "4px",
                        color: "#757C8D",
                        fontSize: "14px",
                        fontFamily: "Outfit",
                        width: "100%",
                      }}
                    >
                      <Select
                        id="courtselect"
                        value={selectedValue?.Courts}
                        label="Courts"
                        displayEmpty
                        onChange={(e) => handleCourtChange(e.target.value)}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                          backgroundColor: "#FAFAFA",
                          borderRadius: "8px",
                          textAlign: "start",
                        }}
                      >
                        {/* <MenuItem value={court.value}>{court.label}</MenuItem> */}
                        {filteredCourts.length > 0 ? (
                          filteredCourts?.map((court) => {
                            return (
                              <MenuItem value={court.value}>
                                {court.label}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem disabled>No Courts</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 2 }}>
              <Accordion expanded={AccordionOpen}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Available Timing</Typography>
                </AccordionSummary>
                <Divider variant="middle" sx={{ mb: 3 }} />
                <AccordionDetails
                  className={
                    selectedValue?.Courts != "Select court" &&
                    // selectedValue?.date == today.toISOString().split("T")[0] &&
                    selectedValue?.gameType != "Select game"
                      ? " grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2 max-[320px]:grid-cols-2"
                      : "w-full flex justify-center items-center"
                  }
                >
                  {selectedValue?.Courts != "Select court" &&
                  // selectedValue?.date == today.toISOString().split("T")[0] &&
                  selectedValue?.gameType != "Select game" ? (
                    courtTime?.map((item, index) => {
                      let gttime = getTimeFormatted(item?.start);
                      return (
                        <Button
                          sx={{
                            border: `1px solid ${item?.bordercolor}`,
                            color: item?.textColor,
                            backgroundColor: item?.backgroundColor,
                            ":hover": {
                              backgroundColor: item?.backgroundColor,
                            },
                          }}
                          disabled={item?.isbooked}
                          onClick={() => handlebooking(item, index)}
                        >
                          <div>
                            <p className="whitespace-nowrap">{gttime.Time}</p>
                            {item?.amount && (
                              <p className="text-black whitespace-nowrap">
                                &#8377; {item.amount}
                              </p>
                            )}
                          </div>
                        </Button>
                      );
                    })
                  ) : (
                    <div className="w-full flex justify-center items-center">
                      Select appropriate values
                    </div>
                  )}
                </AccordionDetails>
                <AccordionDetails>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    gap={{ sm: 4, xs: 2 }}
                  >
                    <Stack direction={"row"}>
                      <img src={Reactangle3} alt="icon" />
                      <Typography
                        sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}
                      >
                        Booked
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <img src={Reactangle2} alt="icon" />
                      <Typography
                        sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}
                      >
                        Available
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <img src={Reactangle1} alt="icon" />
                      <Typography
                        sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}
                      >
                        Selected
                      </Typography>
                    </Stack>
                    <Stack direction={"row"}>
                      <img src={Reactangle4} alt="icon" />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#64627C",
                          ml: 1,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Current slot
                      </Typography>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "90%",
                margin: "auto",
              }}
            >
              <CustomButton
                text={"Reset"}
                onClick={() => handleReset()}
                sx={{
                  backgroundColor: "#097E52",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#097E52" },
                }}
              />
              <CustomButton
                text={"Add to Cart"}
                onClick={() => handleAddCart()}
                sx={{
                  backgroundColor: "#192335",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#192335" },
                  ml: 2,
                }}
              />
            </Box>
          </Box>
        ) : (
          <>
            {cartData?.length ? (
              <Box sx={{ width: "100%", p: { sm: 5, xs: 2 } }}>
                {cartData?.map((OUteritem, Oindex) => {
                  return OUteritem.length ? (
                    <Box sx={{ my: { sm: 2, xs: 1 } }}>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography>Booking {Oindex + 1}</Typography>
                        </AccordionSummary>
                        <Divider variant="middle" />
                        {OUteritem?.map((item, index) => {
                          let sttime = getTimeFormatted(item.start);
                          let ettime = getTimeFormatted(item.end);
                          return (
                            <>
                              <AccordionDetails className=" flex ">
                                <Stack
                                  direction={{ sm: "row", xs: "column" }}
                                  gap={{ sm: 4, xs: 2 }}
                                  sx={{ textAlign: "left", pt: 2 }}
                                >
                                  <Box sx={{ width: "200px" }}>
                                    <Typography
                                      sx={{
                                        color: "#707070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item?.court_name}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ width: "200px" }}>
                                    {/* <Typography
                                      sx={{
                                        color: "#313A4A",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Date & Timing
                                    </Typography> */}
                                    <Typography
                                      sx={{
                                        color: "#707070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {sttime?.Date}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#707070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {sttime?.Time} to {ettime?.Time}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ width: "250px" }}>
                                    <Typography
                                      sx={{
                                        color: "#707070",
                                        fontSize: "14px",
                                      }}
                                    >
                                      &#8377; {item?.amount}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </AccordionDetails>

                              <AccordionDetails
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <CustomButton
                                  text={"Remove"}
                                  onClick={() => handleRemove(Oindex, index)}
                                  sx={{
                                    backgroundColor: "#E50000",
                                    color: "#FFFFFF",
                                    borderRadius: "8px",
                                    fontSize: "14px",
                                    "&:hover": { bgcolor: "#E50000" },
                                    m: 0,
                                  }}
                                />
                              </AccordionDetails>
                              <Divider variant="middle" />
                            </>
                          );
                        })}
                      </Accordion>
                    </Box>
                  ) : (
                    ""
                  );
                })}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <CustomButton
                    text={"Reset"}
                    onClick={() => handleCartReset()}
                    sx={{
                      backgroundColor: "#097E52",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#097E52" },
                    }}
                  />

                  {cartData.flat().length >= 1 ? (
                    <CustomButton
                      text={`Proceed \u20B9${TotalCartPrice(cartData)}`}
                      onClick={() => handlePayment()}
                      sx={{
                        backgroundColor: "#192335",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        fontSize: "14px",
                        "&:hover": { bgcolor: "#192335" },
                        ml: 2,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  height: "100px",
                  backgroundColor: "#fff",
                  py: 5,
                  width: "95%",
                  mx: "auto",
                  borderRadius: "9px",
                }}
              >
                <Typography>No Data</Typography>
              </Box>
            )}
          </>
        )}
        <ToastContainer />
      </Box>

      <Modal
        open={modalopen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Are you sure you want to add this item to your cart?
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleAddcart()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={paymodalopen}
        onClose={() => {
          setPayModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Are you sure you want to proceed with this payment?
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setPayModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setPayModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handlepayment()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={bookedPop}
        onClose={() => {
          setBookedpop(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[22px]">
              The Slot is Already Booked.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setBookedpop(false)}
            >
              X
            </p>
          </div>
          <div className="">
            {alreadybooked?.map((item) => {
              return (
                <div className="">
                  <p className=" text-[#0A1A38] text-[16px] ">
                    Court Name : {item?.court_name}
                  </p>
                  <p className=" text-[16px] ">
                    {getTimeFormatted(item?.start.slice(0, 10)).Date} -{" "}
                    {getTimeFormatted(item?.start).Time} to{" "}
                    {getTimeFormatted(item?.end).Time}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        open={duplicateopen}
        onClose={() => {
          setduplicateopen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Selected slot is already inyour cart.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setduplicateopen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Go to cart"}
              onClick={() => {
                setTab("Cart");
                setduplicateopen(false);
              }}
              sx={{
                backgroundColor: "#097E52",
                color: "#fff",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { color: "#000", bgcolor: "#097E52" },
                width: "40%",
                px: 6,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={loginmodalopen}
        onClose={() => {
          setLoginModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",
          height: "83.33%",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto w-4/6 md:w-5/6 max-[425px]:w-4/6 p-4">
          {isloginmodal ? (
            <LoginModal
              signupchange={() => {
                setisloginmodal(false);
              }}
              setuidfn={(value) => {
                setUid(value);
              }}
              onClosefn={() => {
                setLoginModalOpen(false);
              }}
            />
          ) : (
            <SignupModal
              loginchange={() => {
                setisloginmodal(true);
              }}
              setuidfn={(value) => {
                setUid(value);
              }}
              onClosefn={() => {
                setLoginModalOpen(false);
              }}
            />
          )}
        </div>
      </Modal>
    </Box>
  );
}

export default Booking;
