import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
      <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
      <p className="text-xl text-gray-600 mb-8">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
        Go to Home
      </Link>
    </div>  )
}

export default PageNotFound