import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { getadminconfig, signup, verifyOtp } from "../../firebaseFunction/auth";
import ra from "../../images/right-arrow.svg";
import { Divider, Typography } from "@mui/material";
import CustomButton from "../Common/ButtonComponent";
import { MuiOtpInput } from "mui-one-time-password-input";
import { loginverifyOtp } from "../../firebaseFunction/auth";
import CustomTextField from "../Common/TextFiledComponet";
import profileicon from "../../images/profileIcon.svg";
import mailicon from "../../images/mailIcon.svg";
import CustomSelect from "../Common/SelectComponent";

import Checkbox from "@mui/material/Checkbox";
import Logo from "../../images/Logo.svg";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MuiTelInput } from "mui-tel-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

function SignupForm() {
  const navigate = useNavigate();
  const [allowdamin, setAllowadmin] = useState("");
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [timeup, setTimeup] = useState(false);
  const [response, setResponse] = useState();
  const [formData, setFormData] = useState({
    profileimg: "",
    username: "",
    email: "",
    phonenumber: "",
    info: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    owner: undefined, //{ value: false, label: "No" },
    agreedtoterms: "",
  });

  const [error, setError] = useState("");
  const [valerror, setValerror] = useState(false);
  const [validation, setValidation] = useState({
    username: false,
    email: false,
    phonenumber: false,
    owner: false,
    agreedtoterms: false,
  });

  const [loader, setLoader] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const [userdata, setUserdata] = useState("");
  const [otp, setOtp] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();

    let tempval = {
      username: true,
      email: true,
      phonenumber: true,
      owner: { value: false, label: "No" },
      agreedtoterms: true,
    };

    if (formData.username == "") {
      // setValidation({ ...validation, username: true });
      tempval.username = false;
    }
    if (!formData.email.match(isValidEmail)) {
      tempval.email = false;
    }
    if (!isValidPhoneNumber(formData.phonenumber)) {
      tempval.phonenumber = false;
    }
    if (typeof formData.owner === Boolean) {
      tempval.owner = false;
    }
    if (formData.agreedtoterms == "" || formData.agreedtoterms == false) {
      tempval.agreedtoterms = false;
    }
    if (
      !tempval.username ||
      !tempval.email ||
      !tempval.phonenumber ||
      !tempval.owner ||
      !tempval.agreedtoterms
    ) {
      setValerror(true);
    }
    setValidation({ ...tempval });

    if (
      tempval.username &&
      tempval.email &&
      tempval.phonenumber &&
      tempval.owner &&
      tempval.agreedtoterms
    ) {
      try {
        formData.phonenumber = formData.phonenumber.replace(/\s+/g, "");
        // console.log(formData, "ggg");
        setDisableBtn(true);
        const data = await signup(formData);
        setDisableBtn(false);
        if (data?.data != null) {
          toast.success("OTP sent", { position: "top-right", autoClose: 2000 });
          setOpen(true);
          setUserdata(data);
        } else {
          console.error("Login failed:", data?.error);
          toast.error(`${data?.error}`, {
            position: "top-right",
          });
        }

        setError();
      } catch (error) {
        toast.error(`Signup Failed ${error}`, { position: "top-right" });
      }
    }
    // navigate("/login");
  };

  useEffect(() => {
    let interval = null;
    if (open) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      if (seconds == 0) setTimeup(true);
    } else if (!open && seconds !== 30) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [open, seconds]);

  const handlelogin = () => {
    navigate("/login");
  };
  const SignUpVerify = async () => {
    if (otp.length !== 6) {
      alert("Enter a valid otp");
    }
    setLoader(true);
    const data = await verifyOtp(otp, userdata);
    setLoader(false);

    if (data) {
      console.log(data.data, "bbbb");
      localStorage.setItem("uid", data?.data?.user_id);
      if (data?.data?.owner == true) {
        navigate("/courtadmin/dashboard");
        localStorage.setItem("isowner", data?.data?.owner);
      } else if (data?.data?.owner == false) {
        navigate("/Players/:dashboard");
      } else if (data?.data?.usertype == "venzoadmin") {
        navigate("/venzoadmin/profile");
      }
    }
  };
  const allowAdmin = async () => {
    // const value = await FetchData("config", ["*"]);
    // console.log(value,'hhhh')
    // setAllowadmin(value?.data[0]?.allowadmin);

    const config = await getadminconfig();
    // console.log(config.allowadmin, "config");
    setAllowadmin(config?.allowadmin);
  };

  useEffect(() => {
    allowAdmin();
  }, []);
  const handleotpverify = async () => {
    // setOpen(false)
    const userDetails = await loginverifyOtp(otp, response);
    localStorage.setItem("uid", userDetails?.userData?.user_id);
    if (userDetails?.userData?.owner == true) {
      navigate("/dashboard");
    } else if (userDetails?.userData?.usertype == false) {
      navigate("/login");
    }
  };

  return (
    <div className="flex min-[525px]:flex-row flex-col items-center justify-center h-screen">
      <ToastContainer />
      {/* <Modal open={loader}>
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            className="flex justify-center"
          >
            <CircularProgress style={{ color: "#018352" }} />
          </Typography>
        </Box>
      </Modal> */}
      <div
        className={`min-[525px]:w-1/2 w-full min-[525px]:h-screen  h-2/5 bg-[url('../public/images/loginbg.png')] bg-no-repeat bg-cover flex justify-center items-center `}
      >
        <div className="min-[525px]:w-[75%]   max-[525px]:hidden divide-y divide-white/30 bg-black/45 backdrop-blur rounded-[18px] mt-[82px] p-4 font-normal text-white">
          <h2 className="text-[26px]  lg:pb-4  pb-2">Register</h2>
          {/* <Divider variant="middle" component="li" light={true} /> */}
          <p className="lg:text-xl  lg:pt-8 pt-2 px-2 ">
            Register now for our innovative sports software solutions, designed
            to tackle challenges in everyday sports activities and events.
          </p>
        </div>
        <div className="min-[525px]:hidden p-8">
          <img
            src="./images/logowhite.png"
            alt="logo"
            className="w-4/5 m-auto"
          />
        </div>
      </div>

      <div className="min-[525px]:w-1/2 w-full mx-auto p-10 max-[525px]:p-0   h-screen bg-[#F8F8F8] max-[525px]:bg-white max-[525px]:basis-2/3">
        <div className="w-full h-[100%] px-4  max-[525px]:px-0 flex flex-col gap-8 md:gap-4 items-center justify-center">
          <div
            className="flex flex-row items-center  max-[525px]:hidden"
            // sx={{ display: "flex", alignItems: "center", cursor: "pointer" ,display:{mobile:"hidden"} }}
          >
            <img className="w-[54px] h-[54px] " src={Logo} />
            <div sx={{ textAlign: "left", px: 1 }}>
              <Typography
                sx={{ color: "#018352", fontSize: { xs: "22px", sm: "25px" } }}
              >
                TurfMama
              </Typography>
              <Typography
                sx={{ color: "#1D1D1D", fontSize: { xs: "14px", sm: "16px" } }}
              >
                Fun starts here!
              </Typography>
            </div>
          </div>
          {loader ? (
            <CircularProgress style={{ color: "#018352" }} />
          ) : (
            <div className=" max-[525px]:h-full max-[525px]:rounded-t-2xl max-[525px]:mt-[-50px] w-full bg-white rounded-[14px] p-4 ">
              {!open ? (
                <>
                  <div className="items-start flex flex-col lg:pb-4 pb-2">
                    <h2 className="lg:text-[30px] text-[20px] font-[600]">
                      Get Started!
                    </h2>
                    <h3 className=" lg:text-[18px] text-[12px] text-[#898989] font-[300]">
                      Ignite your sports journey with TurfMama an get started
                      now.
                    </h3>
                  </div>
                  <CustomTextField
                    placeholder="Name"
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                    size="small"
                    name="username"
                    icon={profileicon}
                    iconPosition={"end"}
                    iconHeight={"20px"}
                    iconWeight={"17px"}
                    onChange={(e) => setFormData({ ...formData, username: e })}
                  />
                  {!validation.username && valerror ? (
                    <div className="text-[13px] text-red-600 text-left">
                      Enter name
                    </div>
                  ) : (
                    ""
                  )}
                  <CustomTextField
                    placeholder="Email"
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                      marginTop: "8px",
                    }}
                    size="small"
                    icon={mailicon}
                    iconPosition={"end"}
                    iconHeight={"20px"}
                    iconWeight={"17px"}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e });
                    }}
                  />
                  {!validation.email && valerror ? (
                    <div className="text-[13px] text-red-600 text-left">
                      Enter a valid email ID
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" mt-2  ">
                    <MuiTelInput
                      fullWidth
                      size="small"
                      sx={{
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                      inputProps={{ maxLength: 15 }}
                      placeholder="Mobile Number"
                      defaultCountry="IN"
                      limitMaxLength={10}
                      value={formData.phonenumber}
                      disableDropdown
                      onChange={(phonenumberi) => {
                        const currentcountrycode = phonenumberi
                          .trim(" ")
                          ?.slice(0, 3);
                        if (currentcountrycode !== "+91") {
                          phonenumberi = `+91 ${phonenumberi}`;
                        }
                        if (phonenumberi?.length <= 15) {
                          setFormData({
                            ...formData,
                            phonenumber: phonenumberi,
                          });
                        } else {
                          setFormData({
                            ...formData,
                            phonenumber: `+91${phonenumberi?.slice(3, 13)}`,
                          });
                        }
                      }}
                      rules={{
                        required: true,
                        validate: (n) => n.length <= 13,
                      }}
                    />
                    {/* <PhoneInput
                      placeholder="Mobile Number"
                      defaultCountry="IN"
                      value={formData.phonenumber}
                      onChange={(value) =>
                        setFormData({ ...formData, phonenumber: value })
                      }
                      sx={{
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                    /> */}
                  </div>
                  {!validation.phonenumber && valerror ? (
                    <div className="text-[13px] mt-0 text-red-600 text-left">
                      Enter a valid phone number
                    </div>
                  ) : (
                    ""
                  )}

                  <CustomSelect
                    options={[
                      { id: 2, value: true, label: "Yes" },
                      { id: 3, value: false, label: "No" },
                    ]}
                    value={formData?.owner}
                    placeholder={"Are you court owner ?"}
                    onChange={(e) => setFormData({ ...formData, owner: e })}
                    // icon={iconimg}
                    size="small"
                    sx={{
                      border: "none",
                      "& fieldset": { border: "none" },
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      color: "#757C8D",
                      fontSize: "14px",
                      fontFamily: "Outfit",
                      fontWeight: "500",
                      Height: "20px",
                      marginTop: "4px",
                    }}
                  />
                  {!validation.owner && valerror ? (
                    <div className="text-[13px] text-red-600 text-left">
                      Select appropriate value
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex flex-row mt-3 lg:mt-5">
                    <Checkbox
                      size="small"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          agreedtoterms: e.target.checked,
                        })
                      }
                      sx={{
                        verticalAlign: "top",
                        p: "0px !important",
                        alignItems: "flex-start",
                      }}
                    />
                    <div className="text-[#898989] leading-5 lg:mb-2 text-left lg:text-[18px] text-[14px]">
                      By continuing you indicate that you read and agreed to the
                      <span className="text-[#097E52] font-medium cursor-pointer">
                        Terms of Use
                      </span>
                    </div>
                  </div>
                  <div id="recaptcha-container"></div>

                  <CustomButton
                    text={"Get OTP"}
                    disabled={disableBtn}
                    icon={ra}
                    iconPosition={"end"}
                    iconWidth={"15px"}
                    onClick={handleSignup}
                    onTouchStart={handleSignup}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#192335",
                      width: "100%",
                      "&:hover": { bgcolor: "#192335" },
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="items-start flex flex-col">
                    <h2 className="lg:text-[30px] text-[25px] font-[600]">
                      6-digit Code
                    </h2>
                    <h3 className=" lg:text-[18px] text-[16px] text-[#898989] font-[300]">
                      Please enter the code we’ve sent to{" "}
                      <span className=" text-black font-[500]">
                        {formData.phonenumber}
                      </span>{" "}
                    </h3>
                  </div>
                  <div className="flex flex-col items-start">
                    <div className="p-4 lg:mt-10 mt-4 w-full">
                      <MuiOtpInput
                        value={otp}
                        onChange={(newvalue) => setOtp(newvalue)}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "auto !important",
                          textAlign: "center",
                        }}
                        length={6}
                      />
                    </div>
                    {/* <div id="recaptcha-container"></div> */}
                    {timeup ? (
                      <div className=" lg:text-[18px] text-[16px] text-[#898989] font-[300] cursor-pointer">
                        Didn't recieve code ?
                        <span
                          className="font-[500] text-black pl-2"
                          onClick={handleSignup}
                          onTouchStart={handleSignup}
                        >
                          Resend code
                        </span>
                      </div>
                    ) : (
                      <div className=" lg:text-[18px] text-[16px] text-[#898989] font-[300]">
                        Resend code in{" "}
                        <span className="font-[500] text-black">{`00:${seconds}`}</span>
                      </div>
                    )}
                  </div>
                  <CustomButton
                    text={"Signup & Log in"}
                    disabled={loader}
                    icon={ra}
                    iconPosition={"end"}
                    iconWidth={"15px"}
                    onClick={SignUpVerify}
                    onTouchStart={SignUpVerify}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#192335",
                      width: "100%",
                      "&:hover": { bgcolor: "#192335" },
                    }}
                  />
                </>
              )}

              <div className="w-full flex justify-center lg:my-2 my-0.5">
                <div className="w-[20%]">
                  <Divider sx={{ color: "#898989", fontSize: "16px" }}>
                    OR
                  </Divider>
                </div>
              </div>
              <div className="text-[#898989] lg:text-[18px] text-sm font-[300]">
                Have an Account ?
                <span className="pl-2 font-[500] text-[#097E52]">
                  <Link to="/login">Login</Link>
                </span>{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignupForm;
