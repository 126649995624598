import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, InputLabel } from "@mui/material";

const CustomTextField = ({
  min,
  iconPosition,
  icon,
  width,
  iconWidth,
  iconHeight,
  label,
  labelPosition,
  sx,
  value,
  onChange,
  placeholder,
  disabled,
  height,
  ...rest
}) => {
  const adornmentPosition =
    iconPosition === "start" ? "startAdornment" : "endAdornment";

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const inputProps = icon
    ? {
        [adornmentPosition]: (
          <InputAdornment position={iconPosition} className={"mx-[3px]"}>
            {typeof icon === "string" ? (
              <img
                src={icon}
                alt="Icon"
                style={{ width: iconWidth, height: iconHeight }}
              />
            ) : (
              React.cloneElement(icon, {
                style: { width: iconWidth, height: iconHeight },
              })
            )}
          </InputAdornment>
        ),
      }
    : {};

  return (
    <Box
      sx={{
        display: labelPosition === "flex" ? "flex" : "block",
        alignItems: "left",
        width: width,
        fontFamily: "Outfit",
      }}
    >
      <InputLabel
        variant="subtitle1"
        component="div"
        sx={{ textAlign: "left", fontSize: "16px", fontWeight: 600, mb: 1 }}
      >
        {label}
      </InputLabel>
      <TextField
        {...rest}
        InputProps={inputProps}
        fullWidth
        disabled={disabled ? disabled : false}
        value={value}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        min={min ? min : ""}
        sx={{
          "&.Mui-disabled": { color: "#FFF", backgroundColor: "#A9A9A9" },
          ...sx,
        }}
      />
    </Box>
  );
};

export default CustomTextField;
