import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import Booking from "./Booking";
import MyBooking from "./MyBooking";
import Profile from "./Profile";
import GroundDetails from "./GroundDetails";
import ReviewModal from "./ReviewModal";
import MyFavourite from "./MyFavarote";
import PageNotFound from "../PageNotFound";

function Content() {
  const params = useParams();
  console.log(params, "routeName");
  const routeName = params;

  const screens = {
    "/": <Dashboard />,
    dashboard: <Dashboard />,
    groundDetails: <GroundDetails />,
    booking: <Booking />,
    mybooking: <MyBooking />,
    profile: <Profile />,
    review: <ReviewModal />,
    myfavourite: <MyFavourite />,
  };

  return (
    <div className="overflow-y-auto text-[#000], font-Outfit">
      {screens[routeName?.id] ||
        screens[routeName?.page] ||
        screens[routeName?.dashboard] ||
        screens['dashboard']  }
    </div>
  );
}
export default Content;
