import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
function Players() {
  return (
    <div
      className="outfit-font"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default Players;
