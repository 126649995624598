import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CustomButton from "../Common/ButtonComponent";
import { getgroundDataForOwner } from "../../firebaseFunction/groundDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import groundimg from "../../images/groundicon.png";
import { CardComponent } from "../Common/CardComponent";
import moment from "moment";
import { getEventdetailsByArenas } from "../../firebaseFunction/eventDetails";
import { findElementsWithSameProp } from "../../utils/helper";
import { BookingCard } from "./BookingCard";
import _ from "lodash";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SlotApprovalModal from "./SlotApprovalModal";
function Dashboard() {
  const uid = localStorage.getItem("uid");
  const [groundData, setGrounddata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedBookings, setApprovedBookings] = useState([]);
  const [waitingdBookings, setWaitingdBookings] = useState([]);
  const [noData, setNoData] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState();
  const [statusopen, setstatusopen] = useState(false);

  const navigate = useNavigate();

  const getgroundDetails = async () => {
    try {
      if (!loading && _.isEmpty(groundData) && !noData && uid != null) {
        setLoading(true);
        let response = await getgroundDataForOwner(uid);

        setNoData(response?.length === 0);
        setLoading(false);
        setGrounddata(response);

        const groundIds = response?.map((r) => r.ground_id);
        if (!_.isEmpty(groundIds)) {
          await eventData(groundIds);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(`Unable to load data. Please contact TurfMama`, {
        position: "top-right",
      });
    }
  };

  const handleCreateground = () => {
    navigate("/courtadmin/myarena");
  };

  const isowner = localStorage.getItem("isowner");

  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        navigate("/players/dashboard");
      }
    } else {
      navigate("/players/dashboard");
    }
  }, [isowner, uid, navigate]);

  useEffect(() => {
    if (uid !== null) {
      getgroundDetails();
    }
  }, [uid]);

  const eventData = async (groundIds) => {
    setLoading(true);
    if (uid == null) {
      return;
    }

    let startDate = moment().format("YYYY-MM-DDTHH:mm");
    let endOfMonth = moment().endOf("month").format("YYYY-MM-DDTHH:mm");

    let statusValue = ["Accepted", "Awaiting"];

    const otherFilters = [
      { key: "status", operator: "in", value: statusValue },
      { key: "start", operator: ">=", value: startDate },
      { key: "end", operator: "<=", value: endOfMonth },
    ];
    if (otherFilters && otherFilters.length > 0) {
      const response1 = await getEventdetailsByArenas({
        groundIds: groundIds?.slice(0, 15),
        otherFilters,
        order: { key: "start", dir: "asc" },
      });

      let response2 = { data: [] };
      if (groundIds?.length > 15) {
        response2 = await getEventdetailsByArenas({
          groundIds: groundIds?.slice(15, groundIds?.length),
          otherFilters,
          order: { key: "start", dir: "asc" },
        });
      }
      const data = _.uniqBy(
        [...response1?.data, ...response2?.data],
        "event_id"
      );

      if (data) {
        setApprovedBookings(
          findElementsWithSameProp(
            data.filter((item) => item.status === "Accepted")
          )
        );
        setWaitingdBookings(
          findElementsWithSameProp(
            data.filter((item) => item.status === "Awaiting")
          )
        );
      }
    }

    setLoading(false);
  };

  const handleBookingClick = (index, item, status) => {
    if (status === "Accepted") {
      setSelectedEventData(approvedBookings[index]);
    } else if (status === "Awaiting") {
      setSelectedEventData(waitingdBookings[index]);
    }
    setstatusopen(true);
  };

  const showToast = (status) => {
    if (status === "Accepted") {
      toast.success("Selected Slot(s) Approved", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      toast.success("Selected Slot(s) Rejected", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className=" bg-[#F9F9F9] relative font-Outfit">
      <div
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 pl-16">
          <div>
            <h1 className="text-[30px] font-[500] text-white">Home</h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <Link to="/">
              <p>Home</p>
            </Link>{" "}
            <p>{">"}</p> <p>My Arenas</p>
          </div>
        </div>
      </div>
      <div className=" w-[90%] py-5 mx-auto">
        {waitingdBookings?.length || approvedBookings?.length ? (
          <div className="grid min-[526px]:justify-center md:mx-0 md:grid-cols-2 lg:grid-cols-2 gap-3 mb-5">
            {approvedBookings?.length ? (
              <div>
                <p className=" text-[#192335] text-[30px] font-medium text-left">
                  Upcoming Bookings
                </p>
                <div className="flex flex-row md:h-[150px] md:w-[450px] w-[100%]">
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    emulateTouch={true}
                    centerMode={true}
                    showStatus={false}
                    centerSlidePercentage={100}
                  >
                    {approvedBookings?.map((item, index) => (
                      <BookingCard
                        key={index}
                        bookingItem={item}
                        type={"Accepted"}
                        showShort={true}
                        // showMenu={true}
                      />
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : null}
            {waitingdBookings?.length ? (
              <div>
                <p className=" text-[#192335] text-[30px] font-medium text-left">
                  Pending Bookings
                </p>
                <div className="flex flex-row md:h-[150px] md:w-[450px] w-[100%]">
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    emulateTouch={true}
                    centerMode={false}
                    showStatus={false}
                    centerSlidePercentage={100}
                    onClickItem={(index, item) =>
                      handleBookingClick(index, item, "Awaiting")
                    }
                  >
                    {waitingdBookings?.map((item, index) => (
                      <BookingCard
                        key={index}
                        bookingItem={item}
                        type={"Awaiting"}
                        showShort={true}
                        showMenu={true}
                      />
                    ))}
                  </Carousel>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {groundData?.length ? (
          <div>
            <div className="flex justify-center md:justify-start gap-5 items-center mt-7">
              <p className=" text-[#192335] text-[30px] font-medium  ">
                My Arenas
              </p>
              <CustomButton
                text={"Add Arena"}
                onClick={() => handleCreateground()}
                sx={{
                  backgroundColor: "#192335",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#192335" },
                }}
              />
            </div>

            <div
              className={` grid lg:grid-cols-3 md:gap-1 gap-3 mt-3 sm:grid-cols-2 md:justify-items-start justify-items-center pb-10`}
            >
              {groundData?.map((item, index) => (
                <CardComponent key={index} data={item} type={""} />
              ))}
            </div>
          </div>
        ) : (
          <div className=" ">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                  Add Arena
                </Typography>
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />
              <AccordionDetails>
                <div className="border-dashed border-2 border-[#B8B8B8] ">
                  <div className="mx-auto justify-center flex flex-col w-[30%] my-2 ">
                    <img
                      className="w-[54px] h-[55px] mx-auto"
                      src={groundimg}
                      alt="ground img"
                    />
                    <CustomButton
                      text={"Add Arena"}
                      onClick={() => handleCreateground()}
                      sx={{
                        backgroundColor: "#192335",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        fontSize: "14px",
                        "&:hover": { bgcolor: "#192335" },
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
      {selectedEventData && (
        <SlotApprovalModal
          statusopen={statusopen}
          selectedEventData={selectedEventData}
          setSelectedEventData={setSelectedEventData}
          groundIds={groundData.map((g) => g.ground_id)}
          eventData={eventData}
          setstatusopen={setstatusopen}
          showToast={showToast}
        />
      )}
    </div>
  );
}

export default Dashboard;
