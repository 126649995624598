import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Stack,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import SearchIcon from "../../images/graySearchIcon.svg";
import EditIcon from "../../images/editIcon.svg";
import CustomPagination from "./PaginationComponent";
import { findElementsWithSameProp } from "../../utils/helper";

export const CustomTable = ({
  headers,
  data,
  Title,
  SubTitle,
  showEdit,
  itemsPerPage,
  oneditClick,
}) => {
  // console.log(data, "table");
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPages, setitemPerPages] = useState(itemsPerPage);

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = data?.filter((row) => {
    const values = Object.values(row);
    const lowercaseSearch = searchData.toLowerCase();

    return values.some((value) => {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();
        return lowercaseValue.includes(lowercaseSearch);
      }
      return false;
    });
  });
  const finalData = findElementsWithSameProp(filteredData);
  // console.log(filteredData, "ccc", finalData);
  const pageCount = Math.ceil(finalData.length / itemsPerPages);

  const paginatedData = finalData.slice(
    (currentPage - 1) * itemsPerPages,
    currentPage * itemsPerPages
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
    }
  };

  const handleItemPerPageChange = (data) => {
    setitemPerPages(data);
  };

  const handleedit = (row) => {
    oneditClick(row);
  };
  return (
    <Box sx={{ width: "90%", margin: "0 auto", fontFamily: "Outfit" }}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: 3,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ marginBottom: 3 }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Typography
              sx={{ fontSize: "18px", color: "#192335", fontWeight: 600 }}
            >
              {Title}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", color: "#757C8D", fontWeight: 400 }}
            >
              {SubTitle}
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            size="small"
            value={searchData}
            onChange={handleSearch}
            placeholder={" Search "}
            InputProps={{
              endAdornment: (
                <Button disableRipple>
                  <img src={SearchIcon} alt="Search" width={24} height={24} />
                </Button>
              ),
              sx: {
                "& fieldset": { border: "none" },
                backgroundColor: "#FAFAFA",
                borderRadius: "8px",
                width: "250px",
              },
            }}
          />
        </Stack>
        <Box sx={{ overflowX: "auto" }}>
          <Table
            sx={{
              borderRadius: "8px",
              fontFamily: "Outfit  !important",
            }}
          >
            <TableHead sx={{ backgroundColor: "#F9F9F6" }}>
              <TableRow>
                {headers?.map((header) => {
                  return (
                    <TableCell
                      key={header.key}
                      align={header.align}
                      sx={{
                        fontSize: "15px !important",
                        fontWeight: "600 !important",
                      }}
                    >
                      {header.label}
                    </TableCell>
                  );
                })}
                {showEdit && <TableCell align="center"></TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {paginatedData?.length ? (
                paginatedData?.map((row, rowIndex) => {
                  // console.log(row, "row", "ccc");

                  return (
                    <TableRow key={rowIndex}>
                      {headers?.map((header) => {
                        return (
                          <TableCell key={header.key} align={header.align}>
                            {header.valueRenderer
                              ? header.valueRenderer(row)
                              : row[header.key]}
                          </TableCell>
                        );
                      })}
                      {showEdit && (
                        <TableCell align="center">
                          <Tooltip title="Edit">
                            <Button
                              disableRipple
                              onClick={() => handleedit(row)}
                            >
                              <img
                                src={EditIcon}
                                alt="Edit"
                                width={25}
                                height={25}
                              />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length + (showEdit ? 1 : 0)}
                    align="center"
                  >
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
      {pageCount >= 1 && (
        <Box
          marginTop={2}
          sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: 3 }}
        >
          <CustomPagination
            onChange={handleItemPerPageChange}
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={handlePageChange}
            ItemsPerPages={itemsPerPages}
          />
        </Box>
      )}
    </Box>
  );
};

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      align: PropTypes.oneOf(["left", "center", "right"]),
      fontSize: PropTypes.string,
      valueRenderer: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  Title: PropTypes.string.isRequired,
  SubTitle: PropTypes.string.isRequired,
  showEdit: PropTypes.bool,
  itemsPerPage: PropTypes.number,
};

CustomTable.defaultProps = {
  showEdit: false,
  itemsPerPage: 10,
};
