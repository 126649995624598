import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import AdduserIcon from "../images/Addusercon.svg";
import CustomButton from "./Common/ButtonComponent";
import instaicon from "../images/instaIcon.svg";
import facebookIcon from "../images/facebookicon.svg";
import twittericon from "../images/twittericon.svg";
import linkedinIcon from "../images/linkedinIcon.svg";
import phoneIcon from "../images/phone.svg";
import email from "../images/email.svg";
import istoreIcon from "../images/istoreIcon.svg";
import playstoreIcon from "../images/playstoreIcon.svg";
import moment from "moment";

const footerContactStyle = {
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: 1.3,
  textDecoration: "none",
  marginBottom: "10px",
};
function Footer() {
  const handleClick = () => {};

  return (
    <Box sx={{ color: "#FFFFFF", backgroundColor: "#0A1A38", padding: "12px" }}>
      <Stack direction={"column"} justifyContent={"center"}>
        <Box>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: { xs: "20px", sm: "29px" },
              fontWeight: "500",
            }}
          >
            TurfMama: Your All-Access Pass to Sports
          </Typography>
          <Typography
            sx={{
              color: "#5E677B",
              fontSize: { xs: "15px", sm: "18px" },
              fontWeight: "400",
            }}
          >
            Join our community of sports enthusiasts Today!
          </Typography>
          <CustomButton
            text="Contact Us"
            onClick={handleClick}
            icon={AdduserIcon}
            iconPosition="start"
            iconWidth="14px"
            iconHeight="14px"
            sx={{
              color: "#fff",
              bgcolor: "#097E52",
              borderRadius: "8px",
              "&:hover": { bgcolor: "#097E52" },
            }}
          />
        </Box>
        <Divider
          variant="middle"
          style={{ backgroundColor: "#102346", height: 2 }}
        />
      </Stack>
      <div class="grid lg:grid-cols-3 lg:mx-20 max-[425px]:mx-2  md:grid-cols-3 max-[425px]:grid-cols-1 gap-4 max[425px]:gap-10 my-3">
        <div class="md:text-left">
          <h3 class="text-white text-lg mb-2 font-bold">Contact Us</h3>
          <div class="text-white flex gap-2 mt-4 justify-center lg:justify-start">
            <img class="w-[20px] h-[20px]" src={phoneIcon} alt="social icon" />{" "}
            <a
              href="tel:+919080795425"
              class="text-white"
              style={footerContactStyle}
            >
              +91 90 80 795 425
            </a>
          </div>
          <div class="text-white flex gap-2 mt-4 justify-center lg:justify-start">
            <img class="w-[20px] h-[20px]" src={email} alt="social icon" />{" "}
            <a href="mailto:turfmama2024@gmail.com" style={footerContactStyle}>
              turfmama2024@@gmail.com
            </a>
          </div>
          <div class="flex gap-4 mt-4 justify-center lg:justify-start">
            <div
              class="w-[40px] h-[40px] mr-2 flex justify-center items-center"
              style={{
                borderRadius: 20,
                backgroundColor: "#2A374F",
                lineHeight: "35px",
              }}
            >
              <a href="https://www.facebook.com/people/TurfMama/61563930496944/">
                <img
                  class="w-[24px] h-[24px]"
                  src={facebookIcon}
                  alt="social icon"
                />
              </a>
            </div>
            <div
              class="w-[40px] h-[40px] mr-2 flex justify-center items-center"
              style={{
                borderRadius: 20,
                backgroundColor: "#2A374F",
                lineHeight: "35px",
              }}
            >
              <a href="https://x.com/TurfMama">
                <img
                  class="w-[24px] h-[24px]"
                  src={twittericon}
                  alt="social icon"
                />
              </a>
            </div>
            <div
              class="w-[40px] h-[40px] mr-2 flex justify-center items-center"
              style={{
                borderRadius: 20,
                backgroundColor: "#2A374F",
                lineHeight: "35px",
              }}
            >
              <a href="https://www.linkedin.com/company/turfmama/">
                <img
                  class="w-[24px] h-[24px]"
                  src={linkedinIcon}
                  alt="social icon"
                />
              </a>
            </div>
            <div
              class="w-[40px] h-[40px] mr-2 flex justify-center items-center"
              style={{
                borderRadius: 20,
                backgroundColor: "#2A374F",
                lineHeight: "35px",
              }}
            >
              <a href="https://www.instagram.com/turfmama">
                <img
                  class="w-[24px] h-[24px]"
                  src={instaicon}
                  alt="social icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="md:text-left">
          <h2 class="text-white text-lg mb-2 font-bold">Other Links</h2>
          <p class="text-gray-500 text-sm font-normal">
            <a href="https://www.turfmama.com/index.html#top">Home</a>
          </p>
          <p class="text-gray-500 text-sm font-normal">
            <a href="https://www.turfmama.com/index.html#Features">Features</a>
          </p>
          <p class="text-gray-500 text-sm font-normal">
            <a href="https://www.turfmama.com/index.html#Convenience">
              Convenience
            </a>
          </p>
          <p class="text-gray-500 text-sm font-normal">
            <a href="https://www.turfmama.com/index.html#Review">Reviews</a>
          </p>
          <p class="text-gray-500 text-sm font-normal">
            <a href="https://www.turfmama.com/index.html#FAQ">FAQ</a>
          </p>
        </div>
        <div class="md:text-left flex flex-col items-center lg:items-start">
          <h2 class="text-white text-lg mb-2 font-bold">Download</h2>
          {/* <img class="w-[175px] h-[50px]" src={istoreIcon} alt="icon" /> */}
          <img class="w-[175px] h-[50px] mt-3" src={playstoreIcon} alt="icon" />
        </div>
      </div>
      <Divider style={{ backgroundColor: "#102346", height: 2 }} />
      <p class="my-2 text-gray-600 text-lg md:text-left lg:ml-20 max-[425px]:ml-2">
        &#169; {moment().year()} TurfMama - All Rights Reserved.
      </p>
    </Box>
  );
}

export default Footer;
