import {
  FetchData,
  FetchDataById,
  InsertData,
  UpdateData,
  fetchBulkData,
} from "./crud";
import { storage, db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { GeoPoint } from "firebase/firestore";
import { getEventdetailsByCourt, getcourtevent } from "./eventDetails";
import { UpdateUserData, userData } from "./userDetails";
import _ from "lodash";
import moment from "moment";

const ids = {
  user: "user_id",
  ground_details: "ground_id",
  courts: "court_id",
  masterslot: "slot_id",
  events: "event_id",
  config: "config_id",
  review: "review_id",
  Cart: "cart_id",
};
const GROUND_DETAILS = "ground_details";
const USER = "user";
const COURTS = "courts";
const REVIEW = "review";
const EVENTS = "events";

export const getallgroundData = async (userData, city, game_type, date) => {
  try {
    // Fetch ground data based on city and game type
    let data = await fetchBulkData(
      GROUND_DETAILS,
      city && "city",
      city && "==",
      city && city?.toLowerCase(),
      { key: "createdAt", dir: "desc" },
      city ? null : 10,
      [
        { key: "active", operator: "==", value: true },
        game_type
          ? { key: "game_type", operator: "array-contains", value: game_type }
          : null,
      ]
    );

    const owner_ids = _.uniq(data.map((d) => d.owner));
    const ground_ids = _.uniq(data.map((d) => d.ground_id));

    let courts = await fetchBulkData(
      COURTS,
      "ground_id",
      "in",
      ground_ids,
      null,
      null,
      [{ key: "isactive", operator: "==", value: true }]
    );

    let reviews = await fetchBulkData(REVIEW, "ground_id", "in", ground_ids);

    let owners = await fetchBulkData(
      USER,
      "docid",
      "in",
      owner_ids,
      null,
      null,
      [{ key: "isuseractive", operator: "==", value: true }]
    );

    // Filter grounds based on date and game type
    if (date && city) {
      const isToday = moment(date).isSame(new Date(), "day");
      let startDate = isToday
        ? moment().format("YYYY-MM-DDTHH:mm")
        : moment(date).startOf("day").format("YYYY-MM-DDTHH:mm");
      let endDate = moment(date).endOf("day").format("YYYY-MM-DDTHH:mm");
      const eventFilters = [
        { key: "status", operator: "in", value: ["Awaiting", "Accepted"] },
        { key: "start", operator: ">", value: startDate },
        { key: "end", operator: "<=", value: endDate },
        game_type && { key: "gametype", operator: "==", value: game_type },
      ];
      let bookedEventsForDate = await fetchBulkData(
        EVENTS,
        "ground_id",
        "in",
        ground_ids,
        { key: "start", dir: "asc" },
        null,
        eventFilters
      );

      data = data.filter((item) => {
        const courtCountForFilters = courts.filter(
          (court) =>
            court.ground_id === item.ground_id &&
            (game_type ? court.gametype.includes(game_type) : true)
        ).length;
        const startTime = isToday ? new Date().getHours() : item.start_time;
        const slotCountPerDay = parseInt(item.end_time) - parseInt(startTime);

        const bookedEventsForGround = bookedEventsForDate.filter(
          (e) => e.ground_id === item.ground_id
        ).length;

        const totalAvailableSlotsForFilters =
          parseInt(courtCountForFilters) * parseInt(slotCountPerDay);

        return totalAvailableSlotsForFilters - bookedEventsForGround > 0;
      });
    }

    // Construct the final array of court details
    let courtDetailsArray = data
      .map((item) => {
        const owner = owners.find((o) => o.user_id === item.owner);

        if (owner) {
          item.owner_data = owner;
          if (userData != null || userData != undefined) {
            item.favarote = userData?.favarote?.includes(item?.ground_id);
          }
        }

        let ground_courts = courts.filter(
          (c) => c.ground_id === item.ground_id
        );

        let review = consolidateReviews(
          reviews.filter((r) => r.ground_id === item.ground_id)
        );

        const minAmount = ground_courts.length
          ? ground_courts.reduce(
              (min, current) => Math.min(min, parseInt(current.default_amount)),
              Infinity
            )
          : 0;

        return {
          ...item,
          court_details: ground_courts,
          no_of_courts: ground_courts.length,
          starting_amount: minAmount,
          overallRating: review.overallRating,
          review: review.review,
        };
      })
      .filter((c) => c.no_of_courts > 0);

    return {
      value: courtDetailsArray,
      status: "success",
    };
  } catch (error) {
    console.error("getAllGroundData error", error);
    return { value: [], status: "error" };
  }
};

export const getgroundDataForOwner = async (uid) => {
  try {
    console.log(uid);
    let result = await fetchBulkData(GROUND_DETAILS, "owner", "==", uid);
    const filterdata = result;
    const groundIds = _.uniq(filterdata?.map((r) => r.ground_id));

    if (!_.isEmpty(groundIds)) {
      const courts = await fetchBulkData(COURTS, "ground_id", "in", groundIds);

      let reviews = await fetchBulkData(REVIEW, "ground_id", "in", groundIds);

      return filterdata?.map((item) => {
        const groundCourts = courts.filter(
          (c) => c.ground_id === item.ground_id
        );
        let groundReviews = consolidateReviews(
          reviews.filter((r) => r.ground_id === item.ground_id)
        );

        const minAmount = groundCourts.length
          ? groundCourts.reduce(
              (min, current) => Math.min(min, parseInt(current.default_amount)),
              Infinity
            )
          : 0;
        return {
          ...item,
          court_details: groundCourts,
          no_of_courts: groundCourts?.length,
          starting_amount: minAmount,
          overallRating: groundReviews?.overallRating,
          review: groundReviews?.review,
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    throw error;
  }
};

export const getgroundDataById = async (
  ground_id,
  user_type,
  uid,
  reviewWithUserName = false
) => {
  try {
    let data = await FetchDataById("ground_details", ground_id);
    let user;

    const anotherCollectionData = await FetchDataById("user", data?.owner);
    if (anotherCollectionData && anotherCollectionData.isuseractive == true) {
      data.owner_data = anotherCollectionData;
    }

    if (user_type == "user") {
      if (uid != null || uid != undefined) {
        const userData = await FetchDataById("user", uid);
        data.favarote = userData?.favarote?.includes(data?.ground_id);
      }

      let courts = await FetchData("courts", "ground_id", ground_id);
      let reviewData;

      if (reviewWithUserName) {
        let reviews = await getreview(ground_id);
        reviewData = reviews?.data;
      } else {
        let reviews = await fetchBulkData(REVIEW, "ground_id", "==", ground_id);
        reviewData = consolidateReviews(reviews);
      }

      if (user_type == "user") {
        courts = courts?.filter((item) => item.isactive);
        user = await userData(data?.owner);
        data.grd_ph = user?.phonenumber;
      }
      const minAmount = courts.length
        ? courts?.reduce(
            (min, current) => Math.min(min, parseInt(current.default_amount)),
            Infinity
          )
        : 0;
      data.starting_amount = minAmount;
      data.court_details = courts;
      data.no_of_courts = courts.length;
      data.overallRating = reviewData.overallRating;
      data.review = reviewData.review;
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const getCourtsForGround = async (ground_id) => {
  try {
    let courts = await FetchData("courts", "ground_id", ground_id);
    return courts;
  } catch (error) {
    return error;
  }
};

export const createGroundData = async (groundData) => {
  try {
    if (groundData?.latitude && groundData?.longitude) {
      groundData.location = new GeoPoint(
        parseFloat(groundData.latitude),
        parseFloat(groundData.longitude)
      );
      groundData.createdAt = new Date();
      delete groundData.latitude;
      delete groundData.longitude;

      const data = await InsertData("ground_details", groundData);
      return data;
    } else {
      return {
        data: null,
        error: "latitude or longitude is not given correctly",
      };
    }
  } catch (error) {
    return error;
  }
};

export const createCity = async (cityname) => {
  try {
    let existingCities = await getallCities();
    let alreadyExxist = existingCities.some(
      (item) => item.cityName == cityname.cityName
    );
    if (!alreadyExxist) {
      const data = await InsertData("cities", cityname);
      return data;
    } else {
      return undefined;
    }
  } catch (err) {
    return err;
  }
};
export const getallCities = async () => {
  try {
    let collectionRef = collection(db, "cities");
    let data = [];
    let querySnapshot;

    querySnapshot = await getDocs(collectionRef);

    data = querySnapshot.docs.map((doc) => {
      // return { CityId: doc.id, ...doc.data() };
      return doc.data();
    });

    return data;
  } catch (err) {
    console.error("getAllGroundData error", err);
    return [];
  }
};
export const UpdateGroundData = async (grounddata, ground_id) => {
  try {
    if (grounddata?.latitude && grounddata?.longitude) {
      grounddata.location = new GeoPoint(
        parseFloat(grounddata.latitude),
        parseFloat(grounddata.longitude)
      );
      delete grounddata.latitude;
      delete grounddata.longitude;
    }
    delete grounddata?.court_details;
    delete grounddata?.owner_data;
    delete grounddata?.review;
    delete grounddata?.overallRating;
    delete grounddata?.starting_amount;
    delete grounddata?.no_of_courts;

    let result = await UpdateData("ground_details", grounddata, ground_id);

    return result;
  } catch (error) {
    return error;
  }
};
const uriToBlob = (uri) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = () => {
      reject(new Error("uriToBlob failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });
};
export const uploadFile = async (uuid, name, file, bucketName) => {
  try {
    const storageRef = ref(storage, `${bucketName}/${uuid}/${name}`);
    if (typeof file == String) {
      // const blob = new Blob([file], {
      //   type: 'image/jpeg' // or whatever your Content-Type is
      // });
      const blob = uriToBlob(file);
      var uploadTask = uploadBytesResumable(storageRef, blob);
    } else {
      var uploadTask = uploadBytesResumable(storageRef, file);
    }
    // const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error(error);
          reject(error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(url);
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error during image upload:", error);
    throw error;
  }
};

export const deleteFile = async (filename, ground_id) => {
  try {
    var arr = filename.split("/");
    let name = arr[arr?.length - 1].split("?")[0];
    name = name.replaceAll("%2F", "/");

    try {
      const imageRef = ref(storage, name);
      deleteObject(imageRef).then(async () => {
        console.log("Image deleted successfully");
      });
    } catch (error) {
      console.error("Error deleting image:", error.message);
    }
  } catch (error) {
    return error;
  }
};

export const createNewCourt = async (ground_id, court_details) => {
  try {
    court_details.ground_id = ground_id;
    court_details.createdAt = new Date();
    court_details.isactive = true;

    const data = await InsertData("courts", court_details);
    return data;
  } catch (err) {
    return err;
  }
};

export const updatecourt = async (courtdata, court_id) => {
  try {
    delete courtdata.court_id;
    let result = await UpdateData("courts", courtdata, court_id);
    return result;
  } catch (err) {
    return err;
  }
};

export const getCourtData = async (court_id) => {
  // let court_id = 'un5RZcbqkqWSxI7GhHSm'
  try {
    const data = await FetchDataById("courts", court_id);
    return data;
  } catch (err) {
    return err;
  }
};

export const getGroundslotdata = async (ground_details) => {
  const courtIds = ground_details?.court_details?.map((item) => item.court_id);

  if (courtIds.length > 0) {
    const courtSlots = await fetchBulkData(
      "masterslot",
      "court_id",
      "in",
      courtIds,
      { key: "start", order: "asc" },
      null,
      [
        { key: "isActive", operator: "==", value: true },
        {
          key: "start",
          operator: ">=",
          value: moment().format("YYYY-MM-DDTHH:mm"),
        },
      ]
    );

    return ground_details.court_details.reduce((result, item) => {
      const courtSlotData = courtSlots.filter(
        (slot) => slot.court_id === item.court_id
      );
      if (courtSlotData) {
        courtSlotData.court_name = item?.court_name;
        courtSlotData.ground_name = ground_details.groundname;
        courtSlotData.slotData = courtSlotData;
        result[item.court_id] = courtSlotData;
      }
      return result;
    }, {});
  } else {
    return [];
  }
};

export const getcourtslotdata = async (court_id, user_type) => {
  try {
    const data = await FetchDataById("courts", court_id);
    const slotData = await fetchBulkData(
      "masterslot",
      "court_id",
      "in",
      [court_id],
      { key: "start", order: "asc" },
      null,
      [
        { key: "isActive", operator: "==", value: true },
        {
          key: "start",
          operator: ">=",
          value: moment().format("YYYY-MM-DDTHH:mm"),
        },
      ]
    );

    let eventData = await getcourtevent(court_id);
    if (user_type == "user") {
      eventData = eventData?.filter(
        (item) => item.status != "canceled" && item.status != "cancelled"
      );
    }
    return {
      slotData,
      eventData: eventData,
      default_amount: data?.default_amount,
    };
  } catch (err) {
    return err;
  }
};

export const getCourtSlotsByDate = async (court_id, date) => {
  try {
    const data = await FetchDataById("courts", court_id);
    const slotData = await fetchBulkData(
      "masterslot",
      "court_id",
      "in",
      [court_id],
      { key: "start", order: "asc" },
      null,
      [
        { key: "isActive", operator: "==", value: true },
        {
          key: "start",
          operator: ">=",
          value: moment(date).startOf("day").format("YYYY-MM-DDTHH:mm"),
        },
        {
          key: "end",
          operator: "<=",
          value: moment(date).endOf("day").format("YYYY-MM-DDTHH:mm"),
        },
      ]
    );

    return {
      slotData,
      default_amount: data?.default_amount,
    };
  } catch (err) {
    return err;
  }
};

export const createCourtSlot = async (court_id, slot_details) => {
  // let solt_details = {
  //     price: "1000",
  //     court_id: "6jvTt3n5Md9BHclu1okS",
  //     end: "2024-01-06T10:19",
  //     start: "2024-01-04T10:19",
  // }

  delete slot_details?.starttime;
  delete slot_details?.endtime;
  delete slot_details?.date;

  slot_details.court_id = court_id;
  slot_details.createdAt = new Date();
  slot_details.isActive = true;

  let availableSlots = await fetchBulkData(
    "masterslot",
    "court_id",
    "in",
    [court_id],
    { key: "start", order: "asc" },
    null,
    [
      { key: "isActive", operator: "==", value: true },
      {
        key: "start",
        operator: ">=",
        value: moment(slot_details.start).format("YYYY-MM-DDTHH:mm"),
      },
      {
        key: "end",
        operator: "<=",
        value: moment(slot_details.end).format("YYYY-MM-DDTHH:mm"),
      },
    ]
  );

  if (availableSlots?.length === 0) {
    const data = await InsertData("masterslot", slot_details);
    return { status: "success", data: data };
  } else {
    return {
      status: "failure",
      data: "Slot overlaps with existing slots. Choose a different time.",
    };
  }
};

const isTimeSlotAvailable = (newSlot, existingSlots) => {
  const notSame = existingSlots.filter((item) => {
    return item.slot_id != newSlot.slot_id;
  });

  const newStartTime = new Date(newSlot.start);
  const newEndTime = new Date(newSlot.end);
  const isExist = notSame.filter((item) => {
    return (
      item.isActive &&
      (new Date(item.start) < newStartTime &&
        new Date(item.end) < newStartTime) == false &&
      (new Date(item.start) > newEndTime && new Date(item.end) > newEndTime) ==
        false
    );
  });

  return isExist.length ? false : true;
};

export const updateslotdata = async (slot_id, slotdata) => {
  // slotdata.start = slotdata?.start;
  // slotdata.end = slotdata?.end;
  try {
    let availableSlots = await FetchData(
      "masterslot",
      "court_id",
      slotdata.court_id
    );
    slotdata.slot_id = slot_id;
    const isSlotAvailable = isTimeSlotAvailable(slotdata, availableSlots);

    if (isSlotAvailable) {
      let result = await UpdateData("masterslot", slotdata, slot_id);
      return { status: "success", data: result };
    } else {
      return {
        status: "failure",
        data: "Slot overlaps with existing slots. Choose a different time.",
      };
    }
  } catch (err) {
    return { status: "failure", data: err };
  }
};

export const deleteSlotDetails = async (slotData) => {
  try {
    const courtEvents = await getEventdetailsByCourt({
      courtIds: [slotData.court_id],
      otherFilters: [
        { key: "start", operator: ">=", value: slotData.start },
        { key: "end", operator: "<=", value: slotData.end },
      ],
    });

    if (_.isEmpty(courtEvents.data)) {
      slotData.isActive = false;
      const deleteVar = await UpdateData(
        "masterslot",
        slotData,
        slotData.slot_id
      );

      return "deleted";
    } else {
      return "not deleted";
    }
  } catch (err) {
    return err;
  }
};

function arePointsNear(checkPoint, centerPoint, km) {
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint._lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint._long - checkPoint._long) * kx;
  var dy = Math.abs(centerPoint._lat - checkPoint._lat) * ky;
  var distance = Math.sqrt(dx * dx + dy * dy);

  return distance <= km;
}

export const Createreivew = async (review) => {
  try {
    const availableReview = await getreview(review?.ground_id);
    let filtervalue = availableReview?.data?.review?.filter(
      (item) => item.userId == review?.userId
    );

    let data;
    if (filtervalue && filtervalue.length) {
      data = await UpdateData("review", review, filtervalue[0]?.review_id); //-----
    } else {
      review.createdAt = new Date();
      review.adminreply = "";
      data = await InsertData("review", review);
    }
    return { status: "success", data: data };
  } catch (error) {
    return { status: "failure", data: error };
  }
};

export const consolidateReviews = (data) => {
  let result;
  if (data) {
    const totalReviews = data.length;
    const totalRatings = data.reduce(
      (acc, review) => acc + parseFloat(review.rating),
      0
    );
    const overallRating = totalReviews ? totalRatings / totalReviews : 0;

    const roundedRating = Math.round(overallRating * 2) / 2;

    result = {
      review: data,
      overallRating: roundedRating ? parseFloat(roundedRating.toFixed(1)) : 0,
    };
  } else {
    result = {
      review: data,
      overallRating: 0,
    };
  }

  return result;
};

export const getreview = async (ground_id, data) => {
  try {
    if (!data) {
      data = await FetchData("review", "ground_id", ground_id);
    }

    let result;
    data.sort((a, b) => a.createdAt - b.createdAt);

    if (data) {
      await Promise.all(
        data?.map(async (item) => {
          let userdata = await FetchDataById("user", item?.userId);
          item.user_name = userdata?.username;
          item.profileimg = userdata?.profileimg;
        })
      );

      const totalReviews = data.length;
      const totalRatings = data.reduce(
        (acc, review) => acc + parseFloat(review.rating),
        0
      );
      const overallRating = totalReviews ? totalRatings / totalReviews : 0;

      const roundedRating = Math.round(overallRating * 2) / 2;

      result = {
        review: data,
        overallRating: roundedRating ? parseFloat(roundedRating.toFixed(1)) : 0,
      };
    } else {
      result = {
        review: data,
        overallRating: 0,
      };
    }

    return { status: "success", data: result };
  } catch (error) {
    return { status: "failure", data: error };
  }
};

export const updatereivew = async (review_id, reviewData) => {
  delete reviewData.user_name;
  delete reviewData.review_id;
  try {
    const data = await UpdateData("review", reviewData, review_id);
    return { status: "success", data: data };
  } catch (error) {
    return { status: "failure", data: error };
  }
};

export const getFavGround = async (uid) => {
  try {
    const userdata = await userData(uid);
    const table = "ground_details";
    let collectionRef = collection(db, table);

    if (userdata?.favarote?.length > 0) {
      collectionRef = query(
        collectionRef,
        where("ground_id", "in", userdata.favarote || [])
      );

      let querySnapshot = await getDocs(collectionRef);

      let grounds = querySnapshot.docs.map((doc) => {
        let favadd = doc.data();
        favadd.favarote = true;

        return { [ids[table]]: doc.id, ...favadd };
      });

      return grounds;
    } else {
      return [];
    }
  } catch (err) {
    return err;
  }
};

export const removefavGround = async (uid, ground_id) => {
  try {
    const userdata = await userData(uid);
    if (userdata) {
      userdata.favarote = userdata.favarote.filter(
        (item) => item !== ground_id
      );
    }

    const updatedata = await UpdateUserData(userdata, uid);
    return updatedata;
  } catch (err) {
    return err;
  }
};
