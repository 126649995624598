import React, { useEffect, useState } from "react";
import CustomTextField from "../Common/TextFiledComponet";
import uploadicon from "../../images/image_26.svg";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import CustomButton from "../Common/ButtonComponent";
import { useNavigate } from "react-router-dom";

function ReviewModal() {
  const [review, setReview] = useState({
    title: "",
    review: "",
    reviewimgs: [],
    ratings: "",
  });
  const handleClick = () => {
    console.log("clicked");
  };

  const navigate = useNavigate();

  const uid = localStorage.getItem("uid");
  const isowner = localStorage.getItem("isowner");
  useEffect(() => {
    if (uid != null) {
      if (isowner == "true") {
        console.log("admin true");
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);
  return (
    <div className="bg-none flex flex-col justify-center items-center ">
      <div className="bg-white flex flex-col justify-center items-center w-1/2 rounded-md ">
        <div className="flex w-full justify-start p-4">
          <p className="text-[#313A4A] text-[22px] font-[500]">
            Write a Review
          </p>
        </div>
        <div className="border-[1px] w-[100%] h-[1px] flex border-[#FAFAFA] justify-center "></div>
        <div className="flex flex-col items-start gap-2 w-full p-4">
          <label>Title of your review</label>
          <CustomTextField
            placeholder={
              "If you could say it in one sentence, what would you say?"
            }
            width={"100%"}
            value={review?.title}
            onChange={(e) => {
              setReview({ ...review, title: e });
            }}
            style={{
              backgroundColor: "#FAFAFA",
              color: "#B7B9BF",
              border: "none",
              borderRadius: "8px",
            }}
          />
        </div>
        <div className="flex flex-col items-start gap-2 w-full px-4">
          <label>Your Review</label>
          <textarea
            placeholder="Enter your review"
            value={review?.review}
            onChange={(e) => {
              setReview({ ...review, review: e.target.value });
            }}
            className="w-full border-none bg-[#FAFAFA] rounded-[8px] h-[150px] focus:outline-none p-2"
          ></textarea>
          <label className="text-[#73788C] text-[14px] font-[300]">
            100 characters remaining
          </label>
        </div>
        <div className="flex flex-col items-start gap-2 w-full p-4">
          <label>Upload Image</label>
          <input
            id="uploadimg"
            type="file"
            className="hidden "
            onClick={() => handleClick()}
          />

          <div
            htmlFor="uploadimg"
            className="h-[160px] w-full  border-dashed border-[2px] flex flex-col gap-1 justify-center items-center  rounded-md border-[#8F96A3]  "
          >
            <img
              src={uploadicon}
              alt="img icon"
              className="w-[45px] h-[45px]"
            />
            <label className="text-[#707889] text-[14px]">Upload Image</label>
          </div>
        </div>
        <div className="flex flex-col items-start my-2 w-full px-4">
          <label>Rating</label>

          <Rating
            name="read-only"
            value={review?.ratings}
            onChange={(e) => {
              setReview({ ...review, ratings: e.target.value });
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
        <div className="border-[1px] w-[100%] h-[1px] flex border-[#FAFAFA] justify-center "></div>
        <div className="flex w-full justify-end px-4">
          <CustomButton
            text={"Add Review"}
            //   onClick={() => handleChange("Ongoing")}
            sx={{
              backgroundColor: "#097E52",
              color: "#FFFFFF",
              fontSize: "14px",

              borderRadius: "8px",
              "&:hover": { bgcolor: "#097E52" },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewModal;
