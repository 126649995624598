import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import Favicon from "../../images/favIcon.svg";
import greenFavicon from "../../images/greenfavIcon.svg";
import LocationIcon from "../../images/locationIocn.svg";
import caldenderIcon from "../../images/myBookingIcon.svg";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { ICONS } from "../../utils/icons";

const iconsss = ICONS;

export const CardComponent = ({ data, onClick, type, gameType, width }) => {
  const [favloading, setfavLoading] = useState(false);
  const {
    review,
    groundname,
    overallRating,
    game_type,
    city,
    coverImage,
    street_address,
    starting_amount,
    favarote,
  } = data;

  const [raised, setRaised] = useState(false);
  const navigate = useNavigate();

  const handleFavIcon = async (data) => {
    setfavLoading(true);
    await onClick(data);
    setfavLoading(false);
  };

  const handleGroundClick = (grondData) => {
    if (type == "user")
      navigate(`/players/groundDetails?id=${grondData?.ground_id}`);
  };

  const goToBooking = (grondData) => {
    window.scrollTo(0, 0);

    navigate("/players/booking", {
      state: { ground_id: grondData?.ground_id, gameType: gameType },
    });
  };

  return (
    <Card
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
      raised={raised}
      zdepth={raised ? 1 : 0}
      sx={{
        minWidth: 250,
        width: width || "95%",
        margin: 1,
        fontFamily: "Outfit",
        cursor: "pointer",
      }}
    >
      {type == "user" ? (
        <div
          style={{ position: "relative" }}
          onClick={() => handleGroundClick(data)}
        >
          <CardMedia
            component="img"
            alt="Ground Image"
            image={coverImage?.[0]}
            sx={{ height: "200px" }}
          />
          <div
            style={{
              position: "absolute",
              color: "white",
              backgroundColor: "#097E52",
              padding: "2px 6px",
              fontSize: "12px",

              top: 10,
              right: "0%",
              transform: "translateX(-50%)",
            }}
          >
            {starting_amount != 0 ? <p>&#8377; {starting_amount}</p> : "NA"}
          </div>
          <div
            style={{
              position: "absolute",
              color: "white",
              backgroundColor: "#0CAEF4",
              padding: "2px 6px",
              fontSize: "12px",
              top: 10,
              left: "0%",
              transform: "translateX(20%)",
            }}
          >
            Featured
          </div>
        </div>
      ) : (
        <div
          style={{ position: "relative" }}
          onClick={() => handleGroundClick(data)}
        >
          <CardMedia
            component="img"
            alt="Ground Image"
            image={coverImage?.[0]}
            sx={{ height: "220px" }}
          />
          <div
            style={{
              position: "absolute",

              top: 10,
              right: "0%",
              transform: "translateX(0%)",
            }}
          >
            {/* <Button
              disableRipple
              onClick={() => {
                console.log(data, "cardcomp");
              }}
            >
              <img src={EditIcon} alt="Edit" width={25} height={25} />
            </Button> */}
          </div>
        </div>
      )}

      <CardContent sx={{ p: 2 }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Box display={"flex"} sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                color: "#FFFFFF",
                backgroundColor: "#FFAA01",
                borderRadius: "8px",
                fontSize: "12px",
                width: "27px",
                height: "27px",
                textAlign: "center",
                padding: 0.5,
              }}
            >
              {overallRating}
            </Typography>
            <Typography
              sx={{
                color: "#6B7385",
                fontSize: "14px",
                fontWeight: 400,
                paddingLeft: 1,
              }}
            >
              {review?.length} Reviews
            </Typography>
          </Box>
          {type == "user" && (
            <Box
              onClick={() => handleFavIcon(data)}
              sx={{
                border: 1,
                borderRadius: "50%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {!favloading ? (
                <img
                  src={favarote ? greenFavicon : Favicon}
                  alt="favicon"
                  className="w-[24px] h-[24px] p-1"
                />
              ) : (
                <CircularProgress
                  style={{
                    color: "#018352",
                    width: "24px",
                    height: "24px",
                    p: 2,
                  }}
                />
              )}
            </Box>
          )}
        </Stack>
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            textAlign: "left",
            paddingTop: "1",
            textTransform: "capitalize",
          }}
        >
          {groundname}
        </Typography>
        {/* <Typography
          variant="body2"
          color="text.secondary"
          sx={{ textAlign: "left", height: "80px", overflow: "Hidden" }}
        >
          {overview?.slice(0, 100)}...
        </Typography> */}
        <Stack
          direction={"row"}
          sx={
            type != "user" && {
              justifyContent: "space-between",
              alignSelf: "end",
            }
          }
        >
          <Stack
            direction={"row"}
            sx={{ marginTop: 1, textAlign: "center", alignItems: "center" }}
          >
            <img src={LocationIcon} className="w-[12px] h-[14px]" alt="icon" />
            <Typography
              variant="h5"
              sx={{ textAlign: "left", fontSize: "15px", marginLeft: 1 }}
            >
              {_.startCase(street_address)}, {_.capitalize(city)}
            </Typography>
          </Stack>
          {type != "user" && (
            <Stack
              sx={{ marginTop: 1, textAlign: "center", alignItems: "center" }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  alignItems: "center",
                  color: "#000000",
                  borderRadius: "12px",
                  border: "1px solid transparent",
                  transition: "color backgroundColor borderColor 0.3s",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  padding: 2,
                  "&:hover": {
                    color: "#097E52",
                    border: "1px solid #097E52",
                    backgroundColor: "#F9F9F6",
                    duration: "0.3s",
                  },
                }}
                onClick={
                  type != "user"
                    ? () => {
                        window.scrollTo(0, 0);
                        navigate("/courtadmin/myarena", {
                          state: data.ground_id,
                        });
                      }
                    : ""
                }
              >
                View More
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
      {type == "user" && <Divider variant="middle" />}
      {type == "user" && (
        <CardActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Stack
            direction={"row"}
            sx={{ textAlign: "center", alignItems: "center" }}
          >
            {/* <Avatar
              sx={{ width: "37px", height: "37px" }}
              src={owner_data?.profileimg}
            />
            <Typography sx={{ color: "#757C8D", marginLeft: 1 }}>
              {owner_data?.username}
            </Typography> */}
            {game_type?.slice(0, 3).map((item) => (
              // <Box
              //   sx={{
              //     display: "flex",
              //     flexDirection: "column",
              //     alignItems: "center",
              //     justifyContent: "center",
              //     width: "40px",
              //     height: "40px",
              //     borderRadius: "12px",
              //     border: 1,
              //     borderColor: "#FFF",
              //     // backgroundColor: "#F9F9F6",
              //   }}
              // >
              <img
                className="w-[36px] h-[36px] mx-auto pr-2 "
                src={iconsss[item]}
                alt="icon"
              />
              // </Box>
            ))}
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              textAlign: "center",
              alignItems: "center",
              color: "#000000",
              borderRadius: "12px",
              border: "1px solid transparent",
              transition: "color backgroundColor borderColor 0.3s",
              cursor: "pointer",
              whiteSpace: "nowrap",
              padding: 2,
              "&:hover": {
                color: "#097E52",
                border: "1px solid #097E52",
                backgroundColor: "#F9F9F6",
                duration: "0.3s",
              },
            }}
            className="cursor-pointer"
            onClick={() => goToBooking(data)}
          >
            <img src={caldenderIcon} className="w-[14px] h-[14px]" alt="icon" />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                pl: 1,
                transition: "color 0.3s",
              }}
            >
              Book Now
            </Typography>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
};
