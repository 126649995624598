import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFavGround,
  removefavGround,
} from "../../firebaseFunction/groundDetails";
import { CardComponent } from "../Common/CardComponent";
import { UpdateUserData, userData } from "../../firebaseFunction/userDetails";
import { CircularProgress } from "@mui/material";

function MyFavarote() {
  const [Favarotedata, setFavarotedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const uid = localStorage.getItem("uid");
  let isowner = localStorage.getItem("isowner");
  const navigate = useNavigate();
  const Favarote = async () => {
    const favdata = await getFavGround(uid);
    console.log("favd", favdata);
    setFavarotedata(favdata);
  };

  const handleFav = async (data) => {
    if (uid == null || uid == undefined) {
      navigate("/login");
    }

    if (!data?.favarote) {
      setLoading(true);
      const userDetails = await userData(uid);
      console.log(userDetails, data, "favd");
      if (uid != null) {
        userDetails?.favarote
          ? userDetails?.favarote.push(data?.ground_id)
          : (userDetails.favarote = [data?.ground_id]);
        const updatedata = await UpdateUserData(userDetails, uid);
        if (updatedata?.status == "success") {
          console.log("update successfully");
          Favarote();
        } else {
          console.error("update data failed");
        }
      }
      setLoading(false);
    } else {
      const removedata = await removefavGround(uid, data?.ground_id);
      if (removedata?.status == "success") {
        Favarote();
      }
    }
  };

  const handlenavigate = () => {
    if (isowner == "true") {
      navigate("/courtadmin/dashboard");
    } else {
      navigate("/players/dashboard");
    }
  };

  useEffect(() => {
    Favarote();
  }, []);

  useEffect(() => {
    if (uid != null) {
      if (isowner == "true") {
        console.log("admin true");
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);
  return (
    <div className=" bg-[#F9F9F9] relative font-Outfit">
      <div
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 min-[525px]:pl-16 max-[525px]:pl-12">
          <div>
            <h1 className="text-[30px] font-[500] text-white">My Favourite</h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <p onClick={handlenavigate} className="cursor-pointer">
              Home
            </p>
            <p>{">"}</p> <p>My Favourite</p>
          </div>
        </div>
      </div>
      <div>
        {Favarotedata.length != 0 ? (
          <div
            className={`grid min-[526px] max-[527px]:mx-auto min-[426px]:mx-10 md:grid-cols-2 lg:grid-cols-3 gap-3 mt-10`}
          >
            {Favarotedata?.map((item, index) => (
              <CardComponent
                key={index}
                data={item}
                onClick={handleFav}
                type={"user"}
              />
            ))}
          </div>
        ) : (
          <div className="h-[200px] m-auto pt-8 flex flex-col items-center">
            {" "}
            No Favarote is Available
          </div>
        )}
        {loading && <CircularProgress style={{ color: "#018352" }} />}
      </div>
    </div>
  );
}

export default MyFavarote;
