import _ from "lodash";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./Component/Login/login";
import SignupForm from "./Component/signup/signup";
import Players from "./Component/Players/Players";
import CourtAdmin from "./Component/CourtAdmin/CourtAdmin";
import PageNotFound from "./Component/PageNotFound";
import Maps from "./Component/Players/Maps";
// ----------------------------------------------------------------------

export default function AppRouter() {
  let uid = localStorage.getItem("uid");
  let owner = localStorage.getItem("isowner");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/players/:dashboard" element={<Players />} />
        <Route path="/courtadmin/:dashboard" element={<CourtAdmin />} />
        <Route
          path="/"
          element={owner && owner === true ? <CourtAdmin /> : <Players />}
        />
        <Route path="/maps" element={<Maps />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
