import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../Common/ButtonComponent";

import CourtDetails from "./CourtDetails";
import BookingDetails from "./BookingDetails";
import SlotDetails from "./SlotDetails";
import ReviewPage from "./ReviewPage";
import courticon from "../../images/courtdetaildark.svg";
import courticonwhite from "../../images/courtdetails.svg";
import bookingicon from "../../images/bookingdetails.svg";
import bookingiconwhite from "../../images/bookingwhite.svg";
import sloticon from "../../images/slotdetails.svg";
import sloticonwhite from "../../images/slotwhite.svg";
import reviewicon from "../../images/reviewarena.svg";
import reviewiconwhite from "../../images/reviewwhite.svg";
import _ from "lodash";

function MyArena() {
  const [tab, setTab] = useState("Arena");
  const [arenaDetails, setArenaDetails] = useState({});
  const [data, setdata] = useState({});
  const { state } = useLocation();
  const handleChange = (value) => {
    setTab(value);
  };
  const [filter, setFilter] = useState({
    id: "This Month",
    value: "This_Month",
    label: "This_Month",
  });
  const renderSwitch = (tab) => {
    switch (tab) {
      case "Arena":
        return <CourtDetails data={state} setArenaDetails={setArenaDetails} />;
      case "Bookings":
        return <BookingDetails groundId={state} arenaDetails={arenaDetails} />;
      case "Courts":
        return <SlotDetails groundId={state} arenaDetails={arenaDetails} />;
      case "Reviews":
        return <ReviewPage groundId={state} />;
      default:
        return "foo";
    }
  };
  const navigate = useNavigate();

  const uid = localStorage.getItem("uid");
  const isowner = localStorage.getItem("isowner");
  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        console.log("player true");
        navigate("/players/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <div className=" bg-[#F9F9F9]  font-Outfit">
      <div
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 pl-16 max-[350px]:pl-10">
          <div>
            <h1 className="text-[30px] font-[500] text-white">
              {_.startCase(arenaDetails?.groundname) || "My Arena"}
            </h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <Link to="/courtadmin/dashboard">
              <p>Home</p>
            </Link>{" "}
            <p>{">"}</p>{" "}
            <p>{_.startCase(arenaDetails?.groundname) || "My Arena"}</p>
            <p>{">"}</p> <p>{tab}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center  gap-4 ">
        <div className="bg-white flex flex-row justify-between px-8 items-center w-[100%] rounded-lg">
          <div className="flex flex-row gap-2 overflow-x-auto">
            <CustomButton
              text={"Arena"}
              icon={tab == "Arena" ? courticonwhite : courticon}
              iconPosition={"top"}
              iconHeight={"20px"}
              iconWidth={"20px"}
              onClick={() => handleChange("Arena")}
              sx={
                tab == "Arena"
                  ? {
                      backgroundColor: "#097E52",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      p: "16px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#097E52" },
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#192335",
                      borderRadius: "8px",
                      p: "16px",
                      "&:hover": { bgcolor: "#F9F9F9" },
                    }
              }
            />
            {state != null ? (
              <>
                <CustomButton
                  text={"Bookings"}
                  icon={tab == "Bookings" ? bookingiconwhite : bookingicon}
                  iconPosition={"top"}
                  iconHeight={"20px"}
                  iconWidth={"20px"}
                  onClick={() => handleChange("Bookings")}
                  sx={
                    tab == "Bookings"
                      ? {
                          backgroundColor: "#097E52",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#097E52" },
                        }
                      : {
                          backgroundColor: "#F9F9F9",
                          color: "#192335",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#F9F9F9" },
                        }
                  }
                />
                <CustomButton
                  text={"Courts"}
                  icon={tab == "Courts" ? sloticonwhite : sloticon}
                  iconPosition={"top"}
                  iconHeight={"20px"}
                  iconWidth={"20px"}
                  onClick={() => handleChange("Courts")}
                  sx={
                    tab == "Courts"
                      ? {
                          backgroundColor: "#097E52",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#097E52" },
                        }
                      : {
                          backgroundColor: "#F9F9F9",
                          color: "#192335",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#F9F9F9" },
                        }
                  }
                />
                <CustomButton
                  text={"Reviews"}
                  icon={tab == "Reviews" ? reviewiconwhite : reviewicon}
                  iconPosition={"top"}
                  iconHeight={"20px"}
                  iconWidth={"20px"}
                  onClick={() => handleChange("Reviews")}
                  sx={
                    tab == "Reviews"
                      ? {
                          backgroundColor: "#097E52",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#097E52" },
                        }
                      : {
                          backgroundColor: "#F9F9F9",
                          color: "#192335",
                          borderRadius: "8px",
                          p: "16px",
                          "&:hover": { bgcolor: "#F9F9F9" },
                        }
                  }
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {renderSwitch(tab)}
    </div>
  );
}

export default MyArena;
