import React, { useEffect, useState } from "react";
import CustomButton from "../Common/ButtonComponent";
import {
  createCourtSlot,
  createNewCourt,
  deleteSlotDetails,
  getCourtsForGround,
  getGroundslotdata,
  getcourtslotdata,
  updatecourt,
  updateslotdata,
} from "../../firebaseFunction/groundDetails";
import tick from "../../images/Ellipse 2835.svg";

import Reactangle1 from "../../images/Rectangle1.svg";
import Reactangle2 from "../../images/Rectangle2.svg";
import Reactangle3 from "../../images/Rectangle3.svg";
import Reactangle4 from "../../images/Rectangle4.svg";
import Reactangle5 from "../../images/Rectangle5.svg";

import CustomSelect from "../Common/SelectComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "../../images/addgreenIcon.svg";
import { getTimeFormatted } from "../Common/GetHours";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Divider,
  Box,
  Stack,
  Popover,
  Button,
  Typography,
  CircularProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomTextField from "../Common/TextFiledComponet";
import courticon from "../../images/courthorizontal.svg";
import delicon from "../../images/Group 1000004815.svg";
import editicon from "../../images/Group 1000004816.svg";
import { useNavigate } from "react-router-dom";
import courticons from "../../images/courtIcon.png";
import {
  changeEventStatus,
  createNewBlockEvent,
  createNewEvent,
  getcourtevent,
} from "../../firebaseFunction/eventDetails";
import editIcon from "../../images/editIcon.svg";
import _ from "lodash";
import { ICONS } from "../../utils/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SlotDetails({ groundId, arenaDetails }) {
  const [tab, setTab] = useState("Courts");
  const [groundData, setGroundData] = useState(arenaDetails);
  const [courtTime, setCourtTime] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [loader, setLoading] = useState(false);

  const [createCourt, setCreateCourt] = useState({
    gametype: [],
    court_name: "",
    default_amount: "",
  });
  const todaydate = new Date().toISOString().split("T")[0];

  const [editcourtdata, setEditcourtdata] = useState();
  const [editMode, setEditMode] = useState(false);
  const optionsCourts = groundData?.court_details?.map((court, index) => ({
    id: index + 1,
    value: court.court_id,
    label: court.court_name,
    gameType: court.gametype,
  }));

  const handleCourtChange = async (value) => {
    setSelectedValue({ ...selectedValue, Courts: value });
  };

  const handleEditCourt = async (item) => {
    setEditMode(true);
    setEditcourtdata(item);
    setCreateCourt({
      gametype: item?.gametype,
      court_name: item?.court_name,
      default_amount: item?.default_amount,
    });
    handleChange("Courts");
  };

  const [selectedValue, setSelectedValue] = useState({
    Courts: "",
    selectedEditslot: "",
  });

  const currentDate = new Date().toISOString().split("T")[0];
  const [availablecourt, setAvailablecourt] = useState({
    Courts: "",
    date: currentDate,
  });

  const handleavailablecourt = async (value) => {
    setAvailablecourt({ ...availablecourt, Courts: value });
    if (!_.isEmpty(availablecourt.date) && !_.isEmpty(value)) {
      setAccordionOpen(true);
      setLoading(true);
      const data = await getcourtevent(value?.value);
      setLoading(false);
      setEventData(data);
    } else {
      setEventData([]);
      setAccordionOpen(false);
      setLoading(false);
    }
  };
  const handleavialbleDateChange = async (value) => {
    value = value.target.value;

    const today = new Date();
    const selectedDate = new Date(value);
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate.getTime() < today.getTime()) {
      alert("Please select a future date.");
    } else {
      if (!_.isEmpty(value) && !_.isEmpty(availablecourt.Courts)) {
        setAccordionOpen(true);
        setLoading(true);
        setAvailablecourt({ ...availablecourt, date: value });
        getCourttime(groundData, value);
        setLoading(false);
      } else {
        setEventData([]);
        setAccordionOpen(false);
        setLoading(false);
      }
    }
  };

  const [createSlots, setCreateslots] = useState({
    price: "",
    date: "",
    starttime: "",
    endtime: "",
    isActive: true,
  });
  const [alreadybooked, setAlreadybooked] = useState([]);
  const [bookedPop, setBookedpop] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [AddCourtError, setAddCourtError] = useState(false);
  const [AddCourtTimingError, setAddCourtTimingError] = useState(false);
  const [SlotmodalOpen, setSlotModalOpen] = useState(false);
  const [CreateSlotWarning, setCreateSlotWarning] = useState(false);
  const [CreateEditSlotWarning, setCreateEditSlotWarning] = useState(false);
  const [updateSlotWarning, setupdateSlotWarning] = useState(false);
  const [courtslot, setCourtslot] = useState();
  const tabs = ["Courts", "Slots", "Available Timings"];
  const [gametype, setgametype] = useState([]);
  const [blockmodalopen, setblockModalOpen] = useState(false);
  const [blockerrormodalopen, setblockerrorModalOpen] = useState(false);
  const [unblockerrormodalopen, setunblockerrorModalOpen] = useState(false);
  const [unblockmodalopen, setunblockModalOpen] = useState(false);

  const handleChange = (value) => {
    setTab(value);
    var element = document.getElementById(value);
    element.scrollIntoView();
  };

  const handleDateChange = async (value) => {
    value = value.target.value;
    const today = new Date();
    const selectedDate = new Date(value);

    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    setCreateslots({ ...createSlots, date: value });
  };

  const iconsss = ICONS;

  const handleGameclick = (value) => {
    let availablegame = createCourt?.gametype;
    if (availablegame?.includes(value)) {
      let subarr = availablegame.filter((item) => item != value);
      setCreateCourt({ ...createCourt, gametype: subarr });
    } else {
      setCreateCourt((prevData) => ({
        ...prevData,
        gametype: [...(prevData.gametype || []), value],
      }));
    }
  };
  const handleAddCourt = async () => {
    if (
      createCourt.court_name == "" ||
      createCourt.default_amount == "" ||
      createCourt.gametype.length == 0
    ) {
      setAddCourtError(true);
    } else {
      setModalOpen(true);
      setAddCourtError(false);
    }
  };
  const handleAddCourtSlot = async () => {
    if (
      selectedValue?.Courts == "" ||
      createSlots?.price == "" ||
      createSlots?.date == "" ||
      createSlots?.starttime == "" ||
      createSlots?.endtime == ""
    ) {
      setAddCourtTimingError(true);
      toast.error("Past time is not allowed");
    } else {
      const startT = `${createSlots.date}T${createSlots.starttime}`;
      const endT = `${createSlots.date}T${createSlots.endtime}`;
      const newStartTime = new Date(startT);
      const newEndTime = new Date(endT);
      const currentDateTime = new Date();
      if (
        newStartTime > newEndTime ||
        newStartTime < currentDateTime ||
        newEndTime < currentDateTime
      ) {
        toast.error("Past time is not allowed");
        return;
      } else {
        setSlotModalOpen(true);
        setAddCourtTimingError(false);

        // if (courtDataBySlot.length != 0) {
        //   const isExist = courtDataBySlot.filter((item) => {
        //     return (
        //       (new Date(item.start) < newStartTime &&
        //         new Date(item.end) < newStartTime) == false &&
        //       (new Date(item.start) > newEndTime &&
        //         new Date(item.end) > newEndTime) == false
        //     );
        //   });
        //   if (!isExist.length) {
        //     setSlotModalOpen(true);
        //     setAddCourtTimingError(false);
        //   } else {
        //     setCreateEditSlotWarning(true);
        //   }
        // } else {
        //   setSlotModalOpen(true);
        //   setAddCourtTimingError(false);
        // }
      }
    }
  };

  const handleCourtslot = async () => {
    createSlots.start = `${createSlots?.date}T${createSlots?.starttime}`;
    createSlots.end = `${createSlots?.date}T${createSlots?.endtime}`;

    createSlots.court_id = selectedValue?.Courts?.value;

    if (
      selectedValue?.selectedEditslot == "" ||
      selectedValue?.selectedEditslot == undefined
    ) {
      setLoading(true);
      const data = await createCourtSlot(
        selectedValue?.Courts?.value,
        createSlots
      );
      setLoading(false);
      if (
        data?.data ==
        "Slot overlaps with existing slots. Choose a different time."
      ) {
        setCreateSlotWarning(true);
      } else {
        await getGroundSlotsData();
        setCreateSlotWarning(false);
        setCreateslots({
          price: "",
          date: "",
          starttime: "",
          endtime: "",
          isActive: true,
        });
        setSelectedValue({
          ...selectedValue,
          Courts: "",
          selectedEditslot: "",
        });
        setSlotModalOpen(false);
      }
    } else {
      setLoading(true);
      const update = await updateslotdata(
        selectedValue?.selectedEditslot,
        createSlots
      );
      setLoading(false);
      if (update.status == "failure") {
        setupdateSlotWarning(true);
      } else {
        await getGroundSlotsData();
        setupdateSlotWarning(false);
        setCreateslots({
          price: "",
          date: "",
          starttime: "",
          endtime: "",
          isActive: true,
        });
        setSelectedValue({
          ...selectedValue,
          Courts: "",
          selectedEditslot: "",
        });
        setSlotModalOpen(false);
      }
    }
  };

  const handleCreateCUpdateourt = async () => {
    if (
      editcourtdata != null &&
      Object.keys(editcourtdata).length != 0 &&
      editMode
    ) {
      editcourtdata.court_name = createCourt.court_name;
      editcourtdata.default_amount = createCourt.default_amount;
      editcourtdata.gametype = createCourt.gametype;

      setLoading(true);
      await updatecourt(editcourtdata, editcourtdata?.court_id);
      setLoading(false);
      setEditcourtdata(null);
      setEditMode(false);
      setCreateCourt({
        gametype: [],
        court_name: "",
        default_amount: "",
      });
    } else {
      setLoading(true);
      await createNewCourt(groundId, createCourt);
      setLoading(false);
      setCreateCourt({
        gametype: [],
        court_name: "",
        default_amount: "",
      });
    }
    grndData();
    setModalOpen(false);
  };

  const getGroundSlotsData = async (ground_details) => {
    setLoading(true);
    let slotData = await getGroundslotdata(ground_details || groundData);
    setLoading(false);
    setCourtslot(slotData);
  };

  const grndData = async () => {
    if (groundId != null) {
      setLoading(true);

      let court_details = await getCourtsForGround(groundId);
      let ground_details = { ...arenaDetails, court_details };
      setGroundData(ground_details);
      setgametype(ground_details?.game_type);

      await getGroundSlotsData(ground_details);

      setLoading(false);
      getCourttime(ground_details, currentDate);
    } else {
    }
  };
  function charToAlphabetPosition(char) {
    // Convert a single character to its alphabetical position
    return char.toLowerCase().charCodeAt(0) - "a".charCodeAt(0) + 1;
  }

  function stringToAlphabetPositions(inputString) {
    // Convert the string to lowercase and split into individual characters
    inputString = inputString.toLowerCase();

    // Convert each character to its alphabetical position and concatenate them
    let result = "";
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i].match(/[a-z]/)) {
        result += charToAlphabetPosition(inputString[i]);
      }
    }
    return Number(result);
  }
  const getCourttime = (groundData, date) => {
    let start;
    let end;

    if (typeof date === "object") {
      start = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
        groundData?.start_time
      }`;
      end = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
        groundData?.end_time
      }`;
    } else {
      start = `${date}T${groundData?.start_time}`;
      end = `${date}T${groundData?.end_time}`;
    }

    let daystart = new Date(start);
    let dayend = new Date(end);

    let hourdiff = ((dayend - daystart) / (1000 * 60 * 60)).toFixed(1);

    let eventss = [];
    let starttime = daystart;

    for (let j = 0; j < hourdiff; j++) {
      let en;

      en = {
        backgroundColor: "#fff",
        bordercolor: "#339A49",
        textColor: "#339A49",
        zIndex: "1",
        selected: false,
        isbooked: false,
        isblocked: false,
      };
      en.start = new Date(starttime.getTime() + 1);
      en.end = new Date(starttime.getTime() + 60 * 60 * 1000);
      eventss.push(en);

      starttime = new Date(en.end.getTime() + 1);
    }

    eventss?.map(
      (court) =>
        eventData.length &&
        eventData?.map((event) => {
          let date1 = new Date(event?.start);
          let date2 = new Date(court?.start);
          if (
            Math.abs(date1.getTime() - date2.getTime()) <= 1000 &&
            event.status == "Blocked"
          ) {
            court.isblocked = true;
            court.backgroundColor = "#EEF0F3";
            court.textColor = "#ff0000";
            court.bordercolor = "#ff0000";
          } else if (
            Math.abs(date1.getTime() - date2.getTime()) <= 1000 &&
            event.status != "Canceled" &&
            event.status != "Cancelled" &&
            event.status != "Unblocked"
          ) {
            court.isbooked = true;
            court.backgroundColor = "#EEF0F3";
            court.textColor = "#64627C";
            court.bordercolor = "#64627C";
          }
        })
    );
    let eventarr = eventss.map((element, index) => {
      if (
        new Date(element.start).getHours() == new Date().getHours() &&
        new Date(element.start).getDate() == new Date().getDate()
      ) {
        element = {
          ...element,

          color: "#FFF",
          backgroundColor: "#FFF",
          bordercolor: "#00b4d8",
          textColor: "#00b4d8",
        };

        return element;
      } else {
        return element;
      }
    });

    setCourtTime(eventarr);
  };

  const handlebooking = (value, index) => {
    const updatedCourtTime = [...courtTime];

    const currentTime = new Date();
    const currentTimehour = new Date().getHours();
    if (value.start <= new Date().getHours()) {
      alert("This booking time has already passed.");
      return;
    } else {
      if (value.isblocked) {
        updatedCourtTime[index] = {
          ...updatedCourtTime[index],
          mapIndex: index,
          selected: !updatedCourtTime[index].selected,
          color: !updatedCourtTime[index].selected ? "#FFF" : "#ff0000",
          bordercolor: !updatedCourtTime[index].selected
            ? "#339A49"
            : "#ff0000",
          backgroundColor: !updatedCourtTime[index].selected
            ? "#339A49"
            : "#EEF0F3",
          textColor: !updatedCourtTime[index].selected ? "#FFF" : "#ff0000",
        };
      } else {
        updatedCourtTime[index] = {
          ...updatedCourtTime[index],
          mapIndex: index,
          selected: !updatedCourtTime[index].selected,
          color: !updatedCourtTime[index].selected ? "#FFF" : "#339A49",
          bordercolor: !updatedCourtTime[index].selected
            ? "#339A49"
            : "#339A49",
          backgroundColor: !updatedCourtTime[index].selected
            ? "#339A49"
            : "#FFF",
          textColor: !updatedCourtTime[index].selected ? "#FFF" : "#339A49",
        };
      }
      setCourtTime(updatedCourtTime);
    }
  };

  const handleblockmodal = () => {
    if (uid == null) {
      navigate("/login");
      return;
    }
    let data = courtTime?.filter((item) => item?.selected == true);
    let blockeddata = data?.filter((item) => item?.isblocked == true);

    if (
      availablecourt?.Courts != "Select court" &&
      availablecourt?.date != "" &&
      // availablecourt?.gameType != "Select game" &&
      data.length != 0
    ) {
      if (blockeddata?.length) {
        setblockerrorModalOpen(true);
      } else {
        setblockModalOpen(true);
      }
    } else {
      console.error("select appropriate values");
    }
  };
  const handleblock = async () => {
    if (uid == null) {
      navigate("/login");
      return;
    }
    let data = courtTime?.filter((item) => item?.selected == true);
    if (
      availablecourt?.Courts != "" &&
      availablecourt?.date != "" &&
      data.length != 0
    ) {
      availablecourt.selectedSlot = data;
      let Addcartdatas = [];
      const courtDetails = groundData?.court_details?.filter(
        (item) => item.court_id == availablecourt?.Courts.value
      );
      availablecourt.courtDetails = courtDetails;
      let courtNo;
      if (typeof availablecourt?.Courts.value.match(/\d+/g) == null) {
        courtNo = availablecourt?.Courts.value.match(/\d+/g).join("");
      } else {
        courtNo = stringToAlphabetPositions(
          availablecourt?.Courts.value.slice(0, 5)
        );
      }
      let courtslot = await getcourtslotdata(
        availablecourt?.Courts.value,
        "user"
      );

      data.forEach((slot) => {
        let cartItem = {
          court_id: availablecourt?.Courts.value,
          court_name: courtDetails[0]?.court_name,
          ground_id: courtDetails[0]?.ground_id,
          user_id: uid,
          // amount: courtDetails[0]?.default_amount,
          start: `${availablecourt?.date}T${new Date(
            slot.start
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}`,
          end: `${availablecourt?.date}T${new Date(slot.end).toLocaleTimeString(
            "en-US",
            { hour: "2-digit", minute: "2-digit", hour12: false }
          )}`,
        };
        let currentslot = courtslot?.slotData?.find((item) => {
          return (
            (new Date(item.start) < new Date(cartItem.start) &&
              new Date(item.end) < new Date(cartItem.start)) == false &&
            (new Date(item.start) > new Date(cartItem.end) &&
              new Date(item.end) > new Date(cartItem.end)) == false
          );
        });
        if (currentslot) {
          cartItem.amount = currentslot.price;
        }

        Addcartdatas.push(cartItem);
      });
      let bookedDataList = [];
      const promises = Addcartdatas.map(async (item) => {
        let dat = await getcourtevent(item.court_id);
        let bookedData = dat?.filter((data) => data?.start === item.start);

        return bookedData;
      });
      const results = await Promise.all(promises);
      bookedDataList = results.flat();

      const response = await createNewBlockEvent(Addcartdatas);

      if (response?.status == "Success") {
        handleReset();
        toast.success("Blocking Success", {
          position: "top-right",
          autoClose: 2000,
        });

        grndData();
        setblockModalOpen(false);
      } else {
        toast.error("Blocking Failed", {
          position: "top-right",
          autoClose: 2000,
        });
        console.error("book fail");
      }
      // }
    }
  };
  const handleunblockmodal = () => {
    if (uid == null) {
      navigate("/login");
      return;
    }

    let data = courtTime?.filter((item) => item?.selected == true);
    let unblockeddata = data?.filter((item) => item?.isblocked == false);

    if (
      availablecourt?.Courts != "Select court" &&
      availablecourt?.date != "" &&
      // availablecourt?.gameType != "Select game" &&
      data.length != 0
    ) {
      if (unblockeddata?.length) {
        setunblockerrorModalOpen(true);
      } else {
        setunblockModalOpen(true);
      }
    } else {
      console.error("select appropriate values");
    }
  };
  const handleUnblock = async () => {
    if (uid == null) {
      navigate("/login");
      return;
    }
    let data = courtTime?.filter((item) => item?.selected == true);
    if (
      availablecourt?.Courts != "" &&
      availablecourt?.date != "" &&
      data.length != 0
    ) {
      availablecourt.selectedSlot = data;
      let Addcartdatas = [];
      const courtDetails = groundData?.court_details?.filter(
        (item) => item.court_id == availablecourt?.Courts.value
      );
      availablecourt.courtDetails = courtDetails;
      let courtNo;
      if (typeof availablecourt?.Courts.value.match(/\d+/g) == null) {
        courtNo = availablecourt?.Courts.value.match(/\d+/g).join("");
      } else {
        courtNo = stringToAlphabetPositions(
          availablecourt?.Courts.value.slice(0, 5)
        );
      }
      let courtslot = await getcourtslotdata(
        availablecourt?.Courts.value,
        "user"
      );

      data.forEach((slot) => {
        let cartItem = {
          court_id: availablecourt?.Courts.value,
          court_name: courtDetails[0]?.court_name,
          ground_id: courtDetails[0]?.ground_id,
          user_id: uid,
          // amount: courtDetails[0]?.default_amount,
          start: `${availablecourt?.date}T${new Date(
            slot.start
          ).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}`,
          end: `${availablecourt?.date}T${new Date(slot.end).toLocaleTimeString(
            "en-US",
            { hour: "2-digit", minute: "2-digit", hour12: false }
          )}`,
        };
        let currentslot = courtslot?.slotData?.find((item) => {
          return (
            (new Date(item.start) < new Date(cartItem.start) &&
              new Date(item.end) < new Date(cartItem.start)) == false &&
            (new Date(item.start) > new Date(cartItem.end) &&
              new Date(item.end) > new Date(cartItem.end)) == false
          );
        });
        if (currentslot) {
          cartItem.amount = currentslot.price;
        }

        Addcartdatas.push(cartItem);
      });

      let bookedDataList = [];
      const promises = Addcartdatas.map(async (item) => {
        let dat = await getcourtevent(item.court_id);
        let bookedData = dat?.filter((data) => data?.start === item.start);

        return bookedData;
      });
      const results = await Promise.all(promises);
      bookedDataList = results.flat();

      const mapcosnt = bookedDataList.map(async (datum) => {
        await changeEventStatus(datum?.event_id, "Unblocked");
      });

      if (mapcosnt?.length) {
        handleReset();
        toast.success("Unblocking Success", {
          position: "top-right",
          autoClose: 2000,
        });
        grndData();
        setunblockModalOpen(false);
      } else {
        toast.error("Unblocking Failed", {
          position: "top-right",
          autoClose: 2000,
        });
        setunblockModalOpen(false);
        console.error("Unblocking fail");
      }
      // }
    }
  };
  const handleReset = () => {
    setAvailablecourt({
      Courts: "",
      date: currentDate,
    });

    setAccordionOpen(false);
    setCourtTime([]);
  };
  const dateday = (start, end) => {
    const [phours, pminutes] = start.split(":").map(Number);
    const [phours2, pminutes2] = end.split(":").map(Number);

    const sdate = new Date();
    sdate.setHours(phours);
    sdate.setMinutes(pminutes);
    let hours = sdate.getHours();
    const minutes = sdate.getMinutes();
    let ampm = "AM";
    if (hours >= 12) {
      ampm = "PM";
      hours %= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    const edate = new Date();
    edate.setHours(phours2);
    edate.setMinutes(pminutes2);
    let hours2 = edate.getHours();
    const minutes2 = edate.getMinutes();
    let ampm2 = "AM";
    if (hours2 >= 12) {
      ampm2 = "PM";
      hours2 %= 12;
    }
    if (hours2 === 0) {
      hours2 = 12;
    }

    return (
      <div>
        {/* <h1 className="text-[12px] font-[400]">{`${dayOfWeek}, ${month} ${day
          .toString()
          .padStart(2, "0")}`}</h1> */}
        <h1 className="text-[12px] font-[400] text-nowrap">{`${hours}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm} - ${hours2}:${minutes2
          .toString()
          .padStart(2, "0")} ${ampm2}`}</h1>
      </div>
    );
  };

  const [changestatusmodal, setChangestatusmodal] = useState(false);
  const [SlotDelWarning, setSlotDelWarning] = useState(false);
  const [SloteditWarning, setSloteditWarning] = useState(false);
  const [addEdit, setAddEdit] = useState("Add");
  const [selectcourt, setSelectcourt] = useState();
  const [OpenSlotRes, setOpenSlotRes] = useState();
  const [SlotToEdit, setSlotToEdit] = useState();
  const [AnchorEl, setAnchorEl] = useState();
  const [AccordionOpen, setAccordionOpen] = useState(false);

  const handlemodal = async (props) => {
    setSelectcourt(props);
    setChangestatusmodal(true);
  };

  const handleswitchChange = async () => {
    selectcourt.isactive = selectcourt?.isactive ? false : true;
    setLoading(true);
    await updatecourt(selectcourt, selectcourt?.court_id);
    setLoading(false);
    grndData();
    setChangestatusmodal(false);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 44,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#097E52",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  useEffect(() => {
    grndData();
  }, []);
  const open = Boolean(AnchorEl);
  const handleSlotEditREsponsive = (slot, target) => {
    setOpenSlotRes(true);
    setAnchorEl(target);
    setSlotToEdit(slot);
  };
  const handleSlotClose = () => {
    setAnchorEl(null);
  };

  function formatDateToISO(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const handleSlotdelete = async (value) => {
    setLoading(true);
    const slot = { ...value };
    delete slot.slotData;
    const delSlot = await deleteSlotDetails(slot);
    setLoading(false);

    if (delSlot == "deleted") {
      const slotDatas = await getGroundslotdata(groundData);
      setCourtslot(slotDatas);
    } else {
      setSlotDelWarning(true);
    }
    setLoading(false);
  };

  const handleSlotedit = async (value) => {
    setLoading(true);
    const courtDataBySlot = await getcourtevent(value.court_id);
    setLoading(false);
    if (courtDataBySlot.length != 0) {
      const newStartTime = new Date(value.start);
      const newEndTime = new Date(value.end);
      const isExist = courtDataBySlot.filter((item) => {
        return (
          (new Date(item.start) < newStartTime &&
            new Date(item.end) < newStartTime) == false &&
          (new Date(item.start) > newEndTime &&
            new Date(item.end) > newEndTime) == false
        );
      });

      if (!isExist.length) {
        setAddEdit("Update");
        value.start = formatDateToISO(value.start);
        value.end = formatDateToISO(value.end);

        let court_value = optionsCourts?.filter(
          (item) => item.value == value?.court_id
        );

        setSelectedValue({
          ...selectedValue,
          selectedEditslot: value?.slot_id,
          Courts: court_value[0],
        });

        setCreateslots({
          date: value?.start.slice(0, 10),
          starttime: value?.start?.slice(11, 16),
          endtime: value?.end?.slice(11, 16),
          price: value?.price,
          isActive: value?.isActive,
        });
        setAnchorEl(null);

        handleChange("Slots");
      } else {
        setSloteditWarning(true);
      }
    } else {
      setAddEdit("Update");
      value.start = formatDateToISO(value.start);
      value.end = formatDateToISO(value.end);

      let court_value = optionsCourts?.filter(
        (item) => item.value == value?.court_id
      );

      setSelectedValue({
        ...selectedValue,
        selectedEditslot: value?.slot_id,
        Courts: court_value[0],
      });

      setCreateslots({
        date: value?.start.slice(0, 10),
        starttime: value?.start?.slice(11, 16),
        endtime: value?.end?.slice(11, 16),
        price: value?.price,
        isActive: value?.isActive,
      });
      setAnchorEl(null);

      handleChange("Slots");
    }
  };

  const navigate = useNavigate();

  const uid = localStorage.getItem("uid");
  const isowner = localStorage.getItem("isowner");

  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        navigate("/players/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  function compareByDate(obj1, obj2) {
    return new Date(obj1.start) - new Date(obj2.start);
  }

  return (
    <div className="flex flex-col items-center justify-center mt-4 gap-4 ">
      <Modal open={loader}>
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            className="flex justify-center"
          >
            <CircularProgress style={{ color: "#018352" }} />
          </Typography>
        </Box>
      </Modal>
      <div className="bg-white flex min-[769px]:flex-row max-[769px]flex-col justify-between px-4 items5-center w-[90%] rounded-lg">
        <div className="flex flex-row gap-2 overflow-x-auto whitespace-nowrap min-[769px]:w-[50%] max-[769px]:w-[100%] ">
          {tabs?.map((item) => {
            return (
              <CustomButton
                text={item}
                onClick={() => handleChange(item)}
                sx={
                  tab == item
                    ? {
                        backgroundColor: "#192335",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        fontSize: "14px",
                        overflow: "hidden",
                        textOverFlow: "ellipsis",
                        "&:hover": { bgcolor: "#192335" },
                        px: 2,
                        width: "100%",
                      }
                    : {
                        backgroundColor: "#F9F9F9",
                        color: "#898989",
                        borderRadius: "8px",
                        overflow: "ellipsis",
                        overflow: "hidden",
                        textAlign: "start",
                        textOverFlow: "ellipsis",
                        "&:hover": { bgcolor: "#F9F9F9" },
                        px: 2,
                        width: "100%",
                      }
                }
              />
            );
          })}
        </div>
      </div>

      <div className="bg-white  w-[90%] rounded-lg" id="Courts">
        <Accordion defaultExpanded sx={{ width: "100%", borderRadius: "8px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Manage Courts{" "}
          </AccordionSummary>
          <Divider variant="middle" sx={{ mb: 3 }} />
          <AccordionDetails>
            <Box>
              <Box sx={{ textAlign: "left", mb: 3 }}>
                <Typography sx={{ mb: 2 }}>Choose the Game</Typography>
                <Stack direction={"row"} gap={2}>
                  {gametype?.map((item) => (
                    <>
                      {" "}
                      <div
                        className="flex flex-col justify-center relative items-center w-[85px] h-[90px] rounded-xl border-1 border-[#F9F9F6] bg-[#F9F9F6] cursor-pointer"
                        onClick={() => handleGameclick(item)}
                      >
                        <img
                          className="w-[52px] h-[35px] mx-auto "
                          src={iconsss[item]}
                          alt="icon"
                        />
                        <p className="text-[#192335] mt-1 text-[12px] font-[500]">
                          {item.replace("_", " ")}
                        </p>
                        {createCourt?.gametype?.includes(item) && (
                          <img
                            src={tick}
                            alt="tick"
                            className="w-[15px] h-[15px]  absolute top-[-5px]  right-[-5px] z-10 rounded-full"
                          />
                        )}
                      </div>
                    </>
                  ))}
                </Stack>
                {AddCourtError && createCourt.gametype == "" ? (
                  <div className="text-red-500 text-[13px]">
                    *Select appropriate values
                  </div>
                ) : (
                  ""
                )}
              </Box>

              <Stack
                sx={{ width: "100%" }}
                direction={{ sm: "row", xs: "column" }}
                gap={"16px"}
              >
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <Typography align="left">Court Name</Typography>
                  <CustomTextField
                    value={createCourt?.court_name}
                    onChange={(e) => {
                      setCreateCourt({
                        ...createCourt,
                        court_name: e,
                      });
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  />
                  {AddCourtError && createCourt.court_name == "" ? (
                    <div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <Typography align="left">Default price</Typography>
                  <CustomTextField
                    value={createCourt?.default_amount}
                    onChange={(e) => {
                      setCreateCourt({
                        ...createCourt,
                        default_amount: e,
                      });
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  />
                  {AddCourtError && createCourt.default_amount == "" ? (
                    <div div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
              </Stack>

              <Stack direction={"row"}>
                <CustomButton
                  text={editMode ? "Update" : "Add"}
                  onClick={handleAddCourt}
                  sx={{ color: "#228B63" }}
                  icon={AddCircleOutlineIcon}
                  iconPosition={"start"}
                  iconWidth={"15px"}
                />
              </Stack>
            </Box>
            <Box>
              {groundData?.court_details?.map((item) => {
                return (
                  <>
                    <div className=" bg-[#FAFAFA] text-[16px]  flex justify-between my-3 px-3 rounded-md items-center max-[768px]:hidden">
                      <div className="flex items-center ">
                        <img
                          src={courticons}
                          alt="icon"
                          className=" w-[80px] h-[80px] p-2"
                        />
                        <div className=" text-left text-[13px] mx-3">
                          <p>{item?.court_name}</p>
                          {item?.gametype?.map((game, index) => {
                            return (
                              <span key={index} className=" text-[#097E52]">
                                {game}
                                {index < item.gametype.length - 1 && " | "}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className=" flex items-center">
                        <div className=" felx">
                          <p className=" font=[600]">
                            INR {item?.default_amount}
                          </p>
                        </div>
                        <p className=" text-[#DBDBDB] text-[26px] px-3 ">|</p>
                        <div className=" flex px-3">
                          <p className=" text-[#757C8D] pr-3">Block Court</p>
                          <IOSSwitch
                            checked={!item?.isactive}
                            onChange={() => handlemodal(item)}
                          />
                        </div>
                        <p className=" text-[#DBDBDB] text-[26px] px-3 ">|</p>
                        <div onClick={() => handleEditCourt(item)}>
                          <Tooltip title="Edit">
                            <img src={editIcon} alt="editIcon" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>{" "}
                    <div className=" bg-[#FAFAFA] text-[16px]  flex flex-col justify-between my-3 p-3 rounded-md items-center min-[768px]:hidden">
                      <div className="flex flex-row justify-between w-full text-[#192335] font-semibold">
                        {" "}
                        <p>{item?.court_name}</p>
                        {/*  */}
                        <div onClick={() => handleEditCourt(item)}>
                          <Tooltip title="Edit">
                            <img src={editIcon} alt="editIcon" />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="flex flex-row justify-between w-full text-[#097E52]">
                        <p> &#8377;{item?.default_amount}</p>
                      </div>
                      <div className=" flex w-full mt-4 justify-between">
                        <p className=" text-[#757C8D] pr-3">Block Court</p>
                        <IOSSwitch
                          checked={!item?.isactive}
                          onChange={() => handlemodal(item)}
                        />
                      </div>
                    </div>{" "}
                  </>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          // expanded={AccordionOpen}
          defaultExpanded
          sx={{ width: "100%", borderRadius: "8px" }}
          id="Slots"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Manage Slots{" "}
          </AccordionSummary>
          <Divider variant="middle" sx={{ mb: 3 }} />
          <AccordionDetails>
            <Box>
              <CustomSelect
                options={optionsCourts}
                value={selectedValue?.Courts}
                onChange={handleCourtChange}
                label="Courts"
                name="courts"
                labelPosition="block"
                width="100%"
                style={{ padding: "0", height: "50px" }}
                placeholder={"Courts"}
                sx={{
                  border: "none",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#FAFAFA",
                  borderRadius: "8px",
                  mb: 1,
                  width: { sm: "50%", xs: "100%" },
                }}
              />
              {AddCourtTimingError && selectedValue.Courts == "" ? (
                <div className=" flex text-red-500 items-start text-left text-[13px]">
                  *Select appropriate values
                </div>
              ) : (
                ""
              )}
              <Stack
                sx={{ width: "100%" }}
                direction={{ sm: "row", xs: "column" }}
                gap={"16px"}
              >
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <Typography align="left">Price</Typography>
                  <CustomTextField
                    value={createSlots?.price}
                    onChange={(e) => {
                      setCreateslots({
                        ...createSlots,
                        price: e,
                      });
                    }}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                      mb: 1,
                    }}
                  />
                  {AddCourtTimingError && createSlots.price == "" ? (
                    <div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <div className="w-full">
                    <div
                      className="text-left font-[600] mb-1 text-[#00000099] "
                      // sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
                    >
                      Date
                    </div>
                    <input
                      type="date"
                      id="datePicker"
                      min={todaydate} // Set minimum date to today
                      value={createSlots?.date}
                      onChange={handleDateChange}
                      placeholder={"Choose Date"}
                      className="text-left text-[16px] bg-[#fafafa] w-full px-[17px] py-[14px] rounded-[4px] mt-1 "
                      sx={{
                        border: "none",
                        width: "100%",
                        heigth: "10px",
                        "& fieldset": { border: "none" },
                        backgroundColor: "#FAFAFA",
                        borderRadius: "8px",
                        textAlign: "start",
                      }}
                    />
                  </div>
                  {/* <CustomTextField
                    width="100%"
                    label="Date"
                    type="date"
                    height={10}
                    labelPosition="block"
                    value={createSlots?.date}
                    onChange={handleDateChange}
                    placeholder={"Choose Date"}
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      mb: 1,
                      borderRadius: "8px",
                    }}
                  /> */}
                  {AddCourtTimingError && createSlots.date == "" ? (
                    <div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{ width: "100%" }}
                direction={{ sm: "row", xs: "column" }}
                gap={"16px"}
              >
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <Typography align="left">Start Timing</Typography>

                  <CustomTextField
                    value={createSlots?.starttime}
                    onChange={(e) => {
                      setCreateslots({
                        ...createSlots,
                        starttime: e,
                      });
                    }}
                    type="time"
                    step="600"
                    //
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      mb: 1,
                      borderRadius: "8px",
                    }}
                  />
                  {AddCourtTimingError && createSlots.starttime == "" ? (
                    <div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                  <Typography align="left">End Timing</Typography>
                  <CustomTextField
                    value={createSlots?.endtime}
                    onChange={(e) => {
                      setCreateslots({
                        ...createSlots,
                        endtime: e,
                      });
                    }}
                    type="time"
                    sx={{
                      width: "100%",
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      mb: 1,
                      borderRadius: "8px",
                    }}
                  />
                  {AddCourtTimingError && createSlots.endtime == "" ? (
                    <div className="text-red-500 text-left text-[13px]">
                      *Enter appropriate values
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"}>
                {/* <AddCircleOutlineIcon sx={{ color: "#228B63" }} /> */}
                <CustomButton
                  text={addEdit}
                  onClick={handleAddCourtSlot}
                  sx={{ color: "#228B63" }}
                  icon={AddCircleOutlineIcon}
                  iconPosition={"start"}
                  iconWidth={"15px"}
                />
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
      {groundData?.court_details?.length != 0 ? (
        <>
          <div className="bg-white  w-[90%] rounded-lg max-[600px]:hidden">
            <Table sx={{ width: "100%" }}>
              <TableHead sx={{ paddingRight: "30px" }}>
                <TableRow sx={{ paddingRight: "30px" }}>
                  <TableCell sx={{ width: "25%" }}>Court Name</TableCell>
                  <TableCell sx={{ width: "25%" }}>Date & Time </TableCell>
                  <TableCell sx={{ width: "25%" }} align="center">
                    Pricing
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: "100%" }}>
                {courtslot
                  ? Object?.values(courtslot)?.map((item, index) => {
                      let activeSlotData = item?.slotData?.filter((slot) => {
                        return slot?.isActive;
                      });
                      return (
                        <TableRow key={index}>
                          <TableCell
                            colSpan={4}
                            sx={{ padding: "0px", border: "none" }}
                          >
                            <Accordion sx={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                  width: "100%",
                                  ".Mui-expanded .collapsIconWrapper": {
                                    display: "none",
                                  },
                                  ".Mui-expanded .collapsAmountWrapper": {
                                    display: "none",
                                  },
                                  ".expandIconWrapper": {
                                    display: "none",
                                  },
                                  ".expandAmountWrapper": {
                                    display: "none",
                                  },
                                  ".Mui-expanded .expandIconWrapper": {
                                    display: "block",
                                  },
                                  ".Mui-expanded .expandAmountWrapper": {
                                    display: "block",
                                  },
                                  padding: "0px",
                                }}
                              >
                                <Table>
                                  <TableBody>
                                    <TableCell
                                      sx={{ width: "25%", border: "none" }}
                                    >
                                      <div className="flex flex-row gap-2 w-[170px]">
                                        <div className=" justify-center items-center flex ">
                                          <img
                                            src={courticon}
                                            alt="img"
                                            className="h-[25px] w-[25px] "
                                          />
                                        </div>
                                        <div className="pt-1">
                                          {/* <h1 className="text-[18px] font-[500] text-nowrap">
                                            {groundData.groundname}
                                          </h1> */}
                                          <h1 className="text-[18px] text-[#097E52]">
                                            {item.court_name}
                                          </h1>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "25%", border: "none" }}
                                      align="left"
                                    >
                                      <div>
                                        <Box>
                                          <div className="expandIconWrapper"></div>
                                          <div className="collapsIconWrapper">
                                            {/* {dateday(
                                              groundData.start_time,
                                              groundData.end_time
                                            )} */}
                                          </div>
                                        </Box>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "25%", border: "none" }}
                                      align="center"
                                    >
                                      <div className="expandAmountWrapper"></div>
                                      <div className="collapsAmountWrapper">
                                        {item?.default_amount}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{ width: "25%", border: "none" }}
                                      align="left"
                                    ></TableCell>
                                  </TableBody>
                                </Table>
                              </AccordionSummary>
                              {activeSlotData?.length ? (
                                <Divider variant="middle" />
                              ) : (
                                ""
                              )}

                              <AccordionDetails sx={{ paddingRight: "30px" }}>
                                <Table sx={{ width: "100%" }}>
                                  {activeSlotData?.map((slot) => {
                                    return (
                                      <TableBody>
                                        <TableCell
                                          sx={{ width: "25%", border: "none" }}
                                        ></TableCell>
                                        <TableCell
                                          sx={{ width: "25%", border: "none" }}
                                        >
                                          <div className="text-[12px] text-[#192335] ">
                                            <p>
                                              {" "}
                                              {
                                                getTimeFormatted(slot.start)
                                                  .formatedate
                                              }
                                            </p>
                                            <div className=" flex">
                                              <p>
                                                {" "}
                                                {
                                                  getTimeFormatted(slot.start)
                                                    .Time
                                                }
                                              </p>
                                              -{" "}
                                              <p>
                                                {" "}
                                                {
                                                  getTimeFormatted(slot.end)
                                                    .Time
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          sx={{ width: "25%", border: "none" }}
                                          align="center"
                                        >
                                          &#8377;{slot.price}
                                        </TableCell>
                                        <TableCell
                                          sx={{ width: "25%", border: "none" }}
                                        >
                                          <div className="flex flex-row justify-center gap-4">
                                            <img
                                              src={delicon}
                                              alt="del"
                                              className="w-[25px]"
                                              onClick={() => {
                                                handleSlotdelete(slot);
                                              }}
                                            />
                                            <img
                                              src={editicon}
                                              alt="del"
                                              className="w-[25px]"
                                              onClick={() =>
                                                handleSlotedit(slot)
                                              }
                                            />
                                          </div>
                                        </TableCell>
                                      </TableBody>
                                    );
                                  })}
                                </Table>
                              </AccordionDetails>
                            </Accordion>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : ""}{" "}
              </TableBody>
            </Table>
          </div>
          <div className="bg-white  w-[90%] rounded-lg min-[600px]:hidden">
            {courtslot
              ? Object?.values(courtslot)?.map((item) => {
                  let sortslotdata = item?.slotData?.sort(compareByDate);
                  let activeSlotData = sortslotdata?.filter((item) => {
                    return item?.isActive == true;
                  });

                  return (
                    <Accordion sx={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ display: "flex", width: "100%" }}
                      >
                        <div className="flex flex-row w-full ">
                          <div className="flex flex-col items-start w-full text-[#192335] font-semibold">
                            {" "}
                            {/* <p>{groundData?.groundname}</p> */}
                            {/*  */}
                            <div className="flex flex-row justify-between w-full text-[#097E52]">
                              <p>{item?.court_name}</p>
                            </div>
                          </div>

                          {/* <div
                            onClick={() => handleEditCourt(item)}
                            className="flex justify-center"
                          >
                            <img
                              src={editIcon}
                              alt="editIcon"
                              className="rotate-90"
                            />
                          </div> */}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {activeSlotData?.map((slot) => {
                          return (
                            <div className="text-[12px] p-2 rounded-lg text-[#192335] flex flex-row justify-between  bg-[#FAFAFA]">
                              <div className="flex flex-row leading-6">
                                <p>
                                  {" "}
                                  {getTimeFormatted(slot.start).formatedate}
                                </p>
                                <p className="px-2"> |</p>
                                <p>
                                  {" "}
                                  {getTimeFormatted(slot.start).Time}
                                </p>- <p> {getTimeFormatted(slot.end).Time}</p>
                              </div>
                              <div className="flex flex-row">
                                <div className="flex justify-center">
                                  <p className="leading-6 pr-2 font-[500] text-[#097E52]">
                                    {" "}
                                    &#8377;{slot.price}
                                  </p>

                                  <img
                                    src={editIcon}
                                    alt="editIcon"
                                    className="rotate-90"
                                    onClick={(e) =>
                                      handleSlotEditREsponsive(
                                        slot,
                                        e.currentTarget
                                      )
                                    }
                                  />
                                  <Popover
                                    open={open}
                                    anchorEl={AnchorEl}
                                    onClose={handleSlotClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                  >
                                    <div className="flex flex-row justify-center gap-4 p-4">
                                      <img
                                        src={delicon}
                                        alt="del"
                                        className="w-[25px]"
                                        onClick={() => {
                                          handleSlotdelete(slot);
                                        }}
                                      />

                                      <img
                                        src={editicon}
                                        alt="del"
                                        className="w-[25px]"
                                        onClick={() => {
                                          handleSlotedit(slot);
                                        }}
                                      />
                                    </div>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              : ""}
          </div>
        </>
      ) : (
        ""
      )}
      <div className="bg-white  w-[90%] rounded-lg pb-2" id="Available Timings">
        <Accordion
          defaultExpanded
          sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Available Timing
          </AccordionSummary>
          <Divider variant="middle" sx={{ mb: 3 }} />

          <AccordionDetails>
            <Stack>
              <Stack direction={{ sm: "row", xs: "column" }} gap={2}>
                <CustomSelect
                  options={optionsCourts}
                  value={availablecourt?.Courts}
                  onChange={handleavailablecourt}
                  label="Courts"
                  name="courts"
                  labelPosition="block"
                  width="100%"
                  style={{ padding: "0", height: "50px" }}
                  placeholder={"Select Court"}
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" },
                    backgroundColor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                />{" "}
                <div className="w-full">
                  <div
                    className="text-left font-[600] mb-1 text-[#00000099] "
                    // sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
                  >
                    Date
                  </div>
                  <input
                    type="date"
                    id="datePicker"
                    min={todaydate} // Set minimum date to today
                    value={availablecourt?.date}
                    onChange={handleavialbleDateChange}
                    placeholder={"Choose Date"}
                    className="text-left text-[16px] bg-[#fafafa] w-full px-[17px] py-[14px] rounded-[4px] mt-1 "
                    sx={{
                      border: "none",
                      width: "100%",
                      heigth: "10px",
                      "& fieldset": { border: "none" },
                      backgroundColor: "#FAFAFA",
                      borderRadius: "8px",
                      textAlign: "start",
                    }}
                  />
                </div>
                {/* <CustomTextField
                  width="100%"
                  label="Date"
                  type="date"
                  labelPosition="block"
                  value={availablecourt?.date}
                  onChange={handleavialbleDateChange}
                  placeholder={"Choose Date"}
                  style={{ padding: "0", height: "50px" }}
                  sx={{
                    border: "none",
                    "& fieldset": { border: "none" },
                    backgroundColor: "#FAFAFA",
                    borderRadius: "8px",
                  }}
                /> */}
              </Stack>
              <div
                className={
                  AccordionOpen
                    ? "mt-5 grid xl:grid-cols-8 gap-2 min-[525px]:grid-cols-5 max-[525px]:grid-cols-3 max-[320px]:grid-cols-2"
                    : "w-full"
                }
              >
                {AccordionOpen ? (
                  courtTime?.map((item, index) => {
                    let gttime = getTimeFormatted(item?.start);
                    return (
                      <Button
                        sx={{
                          border: `1px solid ${item?.bordercolor}`,
                          color: item?.textColor,
                          backgroundColor: item?.backgroundColor,
                          ":hover": {
                            backgroundColor: item.backgroundColor,
                          },
                          whiteSpace: "nowrap",
                        }}
                        disabled={item.isbooked}
                        onClick={() => handlebooking(item, index)}
                      >
                        {gttime.Time}
                      </Button>
                    );
                  })
                ) : (
                  <div className="w-full whitespace-nowrap flex justify-center items-center p-4">
                    Select the appropriate values
                  </div>
                )}
              </div>
            </Stack>
            <Stack
              sx={{ mt: 4 }}
              direction={{ sm: "row", xs: "column" }}
              gap={{ sm: 4, xs: 2 }}
            >
              <Stack direction={"row"}>
                <img src={Reactangle3} alt="icon" />
                <Typography sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}>
                  Booked
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <img src={Reactangle2} alt="icon" />
                <Typography sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}>
                  Available
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <img src={Reactangle1} alt="icon" />
                <Typography sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}>
                  Selected
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <img src={Reactangle4} alt="icon" />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#64627C",
                    ml: 1,
                    whiteSpace: "nowrap",
                  }}
                >
                  Current slot
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <img src={Reactangle5} alt="icon" />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#64627C",
                    ml: 1,
                    whiteSpace: "nowrap",
                  }}
                >
                  Blocked slot
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "90%",
                margin: "auto",
              }}
            >
              <CustomButton
                text={"Unblock Court"}
                onClick={() => handleunblockmodal()}
                sx={{
                  backgroundColor: "#097E52",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#097E52" },
                }}
              />
              <CustomButton
                text={"Block court"}
                onClick={() => handleblockmodal()}
                sx={{
                  backgroundColor: "#192335",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#192335" },
                  ml: 2,
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              {editMode
                ? "Are you sure you want to update the Court?"
                : "Are you sure you want to Create a Court?"}
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleCreateCUpdateourt()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={updateSlotWarning}
        onClose={() => {
          setupdateSlotWarning(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot update slot as another slot already exists in the chosen
              time range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setModalOpen(false)}
            >
              X
            </p>
          </div>
          {/* <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancell"}
              onClick={() => setModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleCreateCourt()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div> */}
        </div>
      </Modal>
      <Modal
        open={CreateSlotWarning}
        onClose={() => {
          setCreateSlotWarning(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot create slot as another slot already exists in the chosen
              time range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setCreateSlotWarning(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={CreateEditSlotWarning}
        onClose={() => {
          setCreateEditSlotWarning(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot create slot as an event already exists in the chosen time
              range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setCreateEditSlotWarning(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={SlotDelWarning}
        onClose={() => {
          setSlotDelWarning(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot Delete slot as an event exists in the chosen time range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setSlotDelWarning(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={SloteditWarning}
        onClose={() => {
          setSloteditWarning(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot Edit slot as an event exists in the chosen time range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setSloteditWarning(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={blockerrormodalopen}
        onClose={() => {
          setblockerrorModalOpen(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot block slot as an blocked slot exists in the chosen time
              range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setblockerrorModalOpen(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={unblockerrormodalopen}
        onClose={() => {
          setunblockerrorModalOpen(false);
        }}
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Cannot unblock slot as an unblocked slot exists in the chosen time
              range.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setunblockerrorModalOpen(false)}
            >
              X
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={SlotmodalOpen}
        onClose={() => {
          setSlotModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              {addEdit == "Add"
                ? "Are you sure you want to Create a Slot?"
                : "Are you sure you want to Update a Slot?"}
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setSlotModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setSlotModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleCourtslot()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={changestatusmodal}
        onClose={() => {
          setChangestatusmodal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Are you sure you want to Change Court Status?
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setChangestatusmodal(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setChangestatusmodal(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleswitchChange()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={bookedPop}
        onClose={() => {
          setBookedpop(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[22px]">
              The Slot is Already Booked.
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setBookedpop(false)}
            >
              X
            </p>
          </div>
          <div className="">
            {alreadybooked?.map((item) => {
              return (
                <div className="">
                  <p className=" text-[#0A1A38] text-[16px] ">
                    Court Name : {item?.court_name}
                  </p>
                  <p className=" text-[16px] ">
                    {getTimeFormatted(item?.start.slice(0, 10)).Date} -{" "}
                    {getTimeFormatted(item?.start).Time} to{" "}
                    {getTimeFormatted(item?.end).Time}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Modal
        open={blockmodalopen}
        onClose={() => {
          setblockModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Are you sure you want to block the selected slots?
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setblockModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setblockModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleblock()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={unblockmodalopen}
        onClose={() => {
          setunblockModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className=" flex justify-between">
            <label className=" text-center text-[14px]">
              Are you sure you want to unblock the selected slots?
            </label>
            <p
              className=" text-[red] cursor-pointer"
              onClick={() => setunblockModalOpen(false)}
            >
              X
            </p>
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancel"}
              onClick={() => setunblockModalOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleUnblock()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default SlotDetails;
