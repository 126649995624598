import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Common/ButtonComponent";
import editsvg from "../../images/Ellipse 2839.svg";
import CustomTextField from "../Common/TextFiledComponet";

import { UpdateUserData, userData } from "../../firebaseFunction/userDetails";
import { uploadFile } from "../../firebaseFunction/groundDetails";

import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Profile() {
  const [userDetail, setuserDetail] = useState({});

  const [loader, setLoader] = useState(false);

  const allowedTypes = ["jpeg", "jpg", "png", "webp"];
  const [details, setDetails] = useState({});
  const [tempdetails, setTempDetails] = useState({});
  const [valData, setvalData] = useState(true);
  const [open, setopen] = useState(false);
  const navigate = useNavigate();
  let uid = localStorage.getItem("uid");

  const handlenavigate = () => {
    navigate("/courtadmin/dashboard");
  };
  async function profileDetail() {
    const userProfile = await userData(uid);
    setDetails(userProfile);
    setTempDetails(userProfile);
  }

  useEffect(() => {
    profileDetail();
  }, []);

  // useEffect(() => {
  //   if (isowner == true || !uid) {
  //     navigate('/');
  //   }
  // }, [isowner, uid, navigate]);

  const updateprofile = async () => {
    if (uid == null) {
      navigate("/login");
      return;
    }
    setLoader(true);
    let tempval = {
      profileimg: false,
      username: false,
      email: false,
      phonenumber: false,
      info: true,
      address: true,
      state: true,
      city: true,
      country: true,
      zipcode: true,
      owner: true,
    };
    if (details?.profileimg != "") {
      tempval.profileimg = true;
    }
    if (details?.username != "") {
      tempval.username = true;
    }
    if (details?.email != "") {
      tempval.email = true;
    }
    if (details?.phonenumber != "") {
      tempval.phonenumber = true;
    }
    if (details?.info != "") {
      tempval.info = true;
    }
    if (details?.address != "") {
      tempval.address = true;
    }
    if (details?.state != "") {
      tempval.state = true;
    }
    if (details?.city != "") {
      tempval.city = true;
    }
    if (details?.country != "") {
      tempval.country = true;
    }
    if (details?.zipcode != "") {
      tempval.zipcode = true;
    }
    if (details.owner != "") {
      tempval.owner = true;
    }
    setvalData(Object.values(tempval).every(Boolean));
    if (Object.values(tempval).every(Boolean)) {
      let update;
      update = await UpdateUserData(details, uid);
      setLoader(false);
      if (update.status == "success") {
        setuserDetail(update.data);
        toast.success("Update Success", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        toast.error("Update Failed", {
          position: "top-right",
          autoClose: 2000,
        });
        console.error("check ");
      }
      profileDetail();
    }

    // var user = auth.currentUser;

    // user
    //   .updatePhoneNumber(newphonenumber)
    //   .then(function () {
    //     // Phone number updated successfully.
    //   })
    //   .catch(function (error) {
    //     // An error happened.
    //   });
  };

  const handleimageChange = async (event) => {
    setLoader(true);

    const name = "Profile_IMG_" + new Date().getTime();

    let files = event.target.files[0];
    const extension = files.name.split(".").pop().toLowerCase();
    console.log(files, "files", extension);
    let profile_img;
    if (files) {
      if (!allowedTypes.includes(extension)) {
        alert("Only JPEG,JPG, PNG and WEBP files are allowed.");
        files = ""; // Clear the input field
        return;
      } else {
        profile_img = await uploadFile(uid, name, files, "profileImages");
        Promise.resolve();
        setLoader(false);
        setDetails({
          ...details,
          profileimg: profile_img,
        });
      }
    }
  };
  return (
    <div className=" bg-[#F9F9F9] pb-2">
      <Modal open={loader}>
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            className="flex justify-center"
          >
            <CircularProgress style={{ color: "#018352" }} />
          </Typography>
        </Box>
      </Modal>
      <div
        // style={{ backgroundColor: "#000000" }}
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 min-[525px]:pl-16 max-[525px]:pl-12">
          <div>
            <h1 className="text-[30px] font-[500] text-white">Profile</h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <p onClick={handlenavigate} className="cursor-pointer">
              Home
            </p>
            <p>{">"}</p> <p>Your Profile</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center min-[525px]:mt-4  gap-4 ">
        <div className="bg-white flex justify-evenly items-center min-[525px]:w-[80%] max-[525px]:w-full rounded-lg">
          {/* ----Profile img-------- */}

          <div className="flex flex-col w-full justify-center items-center pb-4">
            <div className="flex flex-col min-[525px]:items-start max-[525px]:items-center justify-center w-full p-4 gap-2">
              <div className="h-[160px] w-[160px] relative border-dashed border-[2px] rounded border-[#8F96A3]  ">
                {details?.profileimg ? (
                  <img
                    src={details?.profileimg}
                    alt="Upload Image"
                    className="h-[100%] w-[100%] text-[#8F96A3] "
                  />
                ) : (
                  <div className="text-[#8F96A3] h-[100%] w-[100%] flex justify-center items-center ">
                    Upload Image
                  </div>
                )}

                <input
                  type="file"
                  id="profileselect"
                  className="hidden"
                  onChange={(e) => {
                    handleimageChange(e);
                    //   setDetails({
                    //     ...details,
                    //     profileimg: URL.createObjectURL(e.target.files[0]),
                    //   });
                  }}
                ></input>
                <label
                  htmlFor="profileselect"
                  className=" absolute top-[8px]  right-[16px] z-10 rounded-full"
                >
                  <img
                    src={editsvg}
                    alt="editicon"
                    className=" w-[25px] rounded-full"
                  />{" "}
                </label>
              </div>
              <div className="text-[#8F96A3] text-[16px]">
                Upload a logo with a minimum size of 150 * 150 pixels (JPG, PNG,
                SVG)
              </div>
              {!details?.profileimg && !valData ? (
                <div className="text-[10px] text-red-600">
                  Upload profile image
                </div>
              ) : (
                ""
              )}
            </div>
            {/* ----Profile form-------- */}
            <div className="flex flex-col gap-2 w-full pb-4">
              <div className="flex min-[525px]:flex-row max-[525px]:flex-col gap-2 w-full pl-4">
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%] ">
                  <label>Name</label>
                  <CustomTextField
                    placeholder={"Name"}
                    width={"95%"}
                    value={details?.username}
                    onChange={(e) => {
                      setDetails({ ...details, username: e });
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.username && !valData ? (
                    <div className="text-[10px] text-red-600">
                      Enter user name{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%]">
                  <label>Email</label>
                  <CustomTextField
                    placeholder={"Email"}
                    width={"95%"}
                    value={details?.email}
                    onChange={(e) => {
                      setDetails({ ...details, email: e });
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.email && !valData ? (
                    <div className="text-[10px] text-red-600">
                      Enter email id{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%]">
                  <label>Mobile Number</label>
                  <CustomTextField
                    placeholder={"Mobile Number"}
                    width={"95%"}
                    value={details?.phonenumber}
                    onChange={(e) => {
                      if (e.length <= 10) {
                        setDetails({
                          ...details,
                          phonenumber: e.replace(/\s+/g, ""),
                        });
                      }
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.phonenumber && !valData ? (
                    <div className="text-[10px] text-red-600">
                      Enter phone number{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start gap-2 w-full px-4">
                <label>Information about You</label>
                <textarea
                  placeholder="Information about You"
                  value={details?.info}
                  onChange={(e) => {
                    setDetails({ ...details, info: e.target.value });
                  }}
                  className="w-full border-none bg-[#FAFAFA] rounded-[8px] focus:outline-none p-2"
                ></textarea>
                {!details?.info && !valData ? (
                  <div className="text-[10px] text-red-600">
                    Enter user info{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="border-[1px] w-[97%] h-[1px] flex border-[#FAFAFA] justify-center  "></div>

            {/* --------address--------- */}
            <div className="flex flex-col gap-2 w-full justify-center items-start p-4">
              <div className="font-semibold text-[18px]">Address</div>
              <div className="flex flex-col items-start gap-2 w-full ">
                <label>Address</label>
                <CustomTextField
                  placeholder={"Name"}
                  width={"100%"}
                  value={details?.address}
                  onChange={(e) => {
                    setDetails({ ...details, address: e });
                  }}
                  sx={{
                    backgroundColor: "#FAFAFA",
                    color: "#B7B9BF",
                    border: "none",
                    "& fieldset": { border: "none" },
                    borderRadius: "8px",
                  }}
                ></CustomTextField>
                {!details?.address && !valData ? (
                  <div className="text-[10px] text-red-600">
                    Enter user address{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex min-[525px]:flex-row  max-[525px]:flex-col gap-2 w-full ">
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%] ">
                  <label>State</label>
                  <CustomTextField
                    placeholder={"State"}
                    width={"100%"}
                    value={details?.state}
                    onChange={(e) => {
                      setDetails({ ...details, state: e });
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.state && !valData ? (
                    <div className="text-[10px] text-red-600">Enter state </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%]">
                  <label>City</label>
                  <CustomTextField
                    placeholder={"City"}
                    width={"100%"}
                    value={details?.city}
                    onChange={(e) => {
                      setDetails({ ...details, city: e });
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.city && !valData ? (
                    <div className="text-[10px] text-red-600">Enter city </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%]">
                  <label>Country</label>
                  <CustomTextField
                    placeholder={"Country"}
                    width={"100%"}
                    value={details?.country}
                    onChange={(e) => {
                      setDetails({ ...details, country: e });
                    }}
                    sx={{
                      backgroundColor: "#FAFAFA",
                      color: "#B7B9BF",
                      border: "none",
                      "& fieldset": { border: "none" },
                      borderRadius: "8px",
                    }}
                  ></CustomTextField>
                  {!details?.country && !valData ? (
                    <div className="text-[10px] text-red-600">
                      Enter country{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start gap-2 min-[525px]:w-[33%] ">
                <label>Zipcode</label>
                <CustomTextField
                  placeholder={"Zipcode"}
                  width={"100%"}
                  value={details?.zipcode}
                  onChange={(e) => {
                    setDetails({ ...details, zipcode: e });
                  }}
                  sx={{
                    backgroundColor: "#FAFAFA",
                    color: "#B7B9BF",
                    border: "none",
                    "& fieldset": { border: "none" },
                    borderRadius: "8px",
                  }}
                ></CustomTextField>
                {!details?.zipcode && !valData ? (
                  <div className="text-[10px] text-red-600">Enter zipcode </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <div className="border-[1px] w-[97%] h-[1px] flex border-[#FAFAFA] justify-center  "></div>
            <div className="p-4 flex flex-col items-start w-full">
              <div className="text-[18px] font-semibold">Court Admin</div>{" "}
              <div className="text-[16px] mt-4 mb-2">Are you a court admin</div>{" "}
              <CustomSelect
                options={[
                  { id: 1, value: true, label: "Yes" },
                  { id: 2, value: false, label: "No" },
                ]}
                value={details?.owner}
                placeholder={"Select Court"}
                width={"33%"}
                onChange={(e) => setDetails({ ...details, owner: e })}
                //   icon={iconimg}
                sx={{
                  border: "none",
                  "& fieldset": { border: "none" },
                  backgroundColor: "#F9F9F9",
                  borderRadius: "4px",
                  color: "#757C8D",
                  fontSize: "14px",
                  fontFamily: "Outfit",
                }}
                disabled
              />
            </div> */}
          </div>
        </div>
        {/* --------save buttonss------------ */}
        {/* */}
        <div className=" flex min-[525px]:flex-row  max-[525px]:flex-col justify-between items-center min-[525px]:w-[80%]  rounded-lg">
          <CustomButton
            text={"Deactivate Account"}
            onClick={() => {
              setopen(true);
            }}
            sx={{
              backgroundColor: "#E50000",
              color: "#FFFFFF",
              borderRadius: "8px",
              fontSize: "14px",
              "&:hover": { bgcolor: "#E50000" },
            }}
          />
          <div className="flex flex-row gap-2">
            <CustomButton
              text={"Reset"}
              //   onClick={() => handleChange("Profile")}
              onClick={() => setDetails(tempdetails)}
              sx={{
                backgroundColor: "#097E52",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: "14px",
                "&:hover": { bgcolor: "#097E52" },
              }}
            />
            <CustomButton
              text={"Save Changes"}
              //   onClick={() => handleChange("Other_Settings")}
              onClick={updateprofile}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: "14px",
                "&:hover": { bgcolor: "#192335" },
              }}
            />
          </div>
        </div>
        <Modal
          open={open}
          onClose={() => {
            setopen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            verticalAlign: "middle",
            m: "auto",
            display: "flex",
            border: "none",

            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="bg-white rounded-md flex flex-col m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
            <div className=" flex justify-between">
              <div className="text-[#192335] text-[18px] font-[500]">
                Are you sure you want to deactivate ?
              </div>
              <p
                className=" text-[red] cursor-pointer"
                onClick={() => setopen(false)}
              >
                X
              </p>
            </div>
            {/* <CloseIcon onClick={handleClose} className="cursor-pointer" /> */}

            <div className=" rounded-md p-2 flex justify-end gap-2 text-[#8F96A3]  text-[12px]">
              <CustomButton
                text={"Yes"}
                //   onClick={() => handleChange("Profile")}
                onClick={() => {
                  setDetails({
                    ...details,
                    isuseractive: false,
                  });
                  setopen(false);
                }}
                sx={{
                  backgroundColor: "#E50000",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#E50000" },
                }}
              />
              <CustomButton
                text={"No"}
                //   onClick={() => handleChange("Profile")}
                onClick={() => {
                  setopen(false);
                }}
                sx={{
                  backgroundColor: "#097E52",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#097E52" },
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
}
//simply
export default Profile;
