import React from "react";
import { InputLabel, Box, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const CustomSelect = ({
  name,
  options,
  value,
  icon,
  onChange,
  label,
  placeholder,
  labelPosition = "block",
  width,
  disabled,
  ...rest
}) => {
  const handleChange = (e, newValue) => {
    onChange(newValue);
  };

  return (
    <Box
      style={{
        display: labelPosition === "block" ? "block" : "flex",
        alignItems: "start",
        justifyItems: "start",
        width: width,
      }}
      sx={{ fontFamily: "Outfit" }}
    >
      <InputLabel
        htmlFor={name}
        variant="subtitle1"
        component="div"
        sx={{ textAlign: "left", fontWeight: 600, mb: 1 }}
      >
        {label}
      </InputLabel>
      <Autocomplete
        value={value == "" ? null : value}
        onChange={handleChange}
        options={options}
        disabled={disabled ? disabled : false}
        getOptionLabel={(option) => option.label}
        sx={{ "&.Mui-disabled": { color: "#FFF", backgroundColor: "#A9A9A9" } }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            sx={{ ...(labelPosition === "block" && { mb: "5px" }) }}
          />
        )}
        {...rest}
      />
    </Box>
  );
};

export default CustomSelect;
