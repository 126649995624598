import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Modal,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../Common/ButtonComponent";
import { getreview, updatereivew } from "../../firebaseFunction/groundDetails";
import tickicon from "../../images/blackrecttickIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import CustomTextField from "../Common/TextFiledComponet";
import EditIcon from "../../images/editIcon.svg";
import { useNavigate } from "react-router-dom";

function ReviewPage({ groundId }) {
  const [reviewDatas, setReviewDatas] = useState();
  const [count, setCount] = useState(2);
  const [reply, setReply] = useState("");
  const [open, setOpen] = useState(false);
  const [selectReply, setSelectedReply] = useState();

  const reviewData = async () => {
    let response = await getreview(groundId);
    if (response?.status == "success") {
      setReviewDatas(response?.data);
    }
  };

  const handleReply = async (data) => {
    setSelectedReply(data);
    if (data?.adminreply != "") {
      setReply(data?.adminreply);
    } else {
      setReply("");
    }
    setOpen(true);
  };

  const handleReplySubmit = async () => {
    selectReply.adminreply = reply;
    const res = await updatereivew(selectReply?.review_id, selectReply);
    if (res?.status == "success") {
      setOpen(false);
      setReply("");
    }
  };

  useEffect(() => {
    reviewData();
  }, []);
  const navigate = useNavigate();

  const uid = localStorage.getItem("uid");
  const isowner = localStorage.getItem("isowner");

  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        console.log("player true");
        navigate("/players/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <Box sx={{ my: 2 }} id="Reviews">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ mb: -2 }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              alignItems: "center",
              textAlign: "left",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#192335", fontWeight: 600 }}>
              Reviews
            </Typography>
          </Box>
        </AccordionSummary>
        <Divider variant="middle" sx={{ mb: 1 }} />
        <AccordionDetails sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              backgroundColor: "#F9F9F6",
              width: { xs: "90%", sm: "50%" },
              px: 3,
              py: 5,
              borderRadius: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#192335",
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              {parseFloat(reviewDatas?.overallRating).toFixed(1)}
            </Typography>
            <Typography>out of 5.0</Typography>

            <Rating
              name="read-only"
              value={parseFloat(reviewDatas?.overallRating)}
              readOnly
              sx={{ height: "5px !important" }}
              precision={0.5}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </Box>
        </AccordionDetails>
        <AccordionDetails>
          {reviewDatas?.review?.length ? (
            <Box>
              {reviewDatas?.review?.slice(0, count)?.map?.((item) => {
                return (
                  <Box>
                    <Stack
                      direction={"row"}
                      sx={{ my: 2, alignItems: "center" }}
                    >
                      <Avatar
                        sx={{ width: 50, height: 50 }}
                        src={item?.profileimg}
                      />
                      <Box sx={{ textAlign: "left", ml: 1 }}>
                        <Typography
                          sx={{
                            color: "#757C8D",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          {item?.user_name} on{" "}
                          <span>
                            {new Date(
                              item?.createdAt?.seconds * 1000 +
                                Math.floor(item?.createdAt?.nanoseconds / 1e6)
                            ).toLocaleDateString("en-GB")}
                          </span>
                        </Typography>
                        <Stack direction={"row"}>
                          <Rating
                            name="read-only"
                            value={item?.rating}
                            readOnly
                            sx={{ height: "5px !important" }}
                            precision={0.5}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          <Typography
                            sx={{
                              color: "#707889",
                              fontSize: "14px",
                              ml: 1,
                            }}
                          >
                            {parseFloat(item?.rating).toFixed(1)}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        ml: { xs: 5, sm: 10 },
                        textAlign: "left",
                        width: "80%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#192335",
                          fontSize: "14px",
                          fontWeight: 600,
                          my: 2,
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.reviewTitle}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#757C8D",
                          fontSize: "14px",
                          fontWeight: 400,
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.reviewContent}
                      </Typography>
                      {item?.reviewImage?.length ? (
                        <Box className="flex flex-wrap overflow-x-auto">
                          {item?.reviewImage?.map((item) => {
                            return (
                              <img
                                src={item}
                                alt="images"
                                className="rounded-md m-2 h-[100px]"
                              />
                            );
                          })}
                        </Box>
                      ) : (
                        ""
                      )}
                      {item?.adminreply ? (
                        <Box
                          sx={{
                            backgroundColor: "#F9F9F6",
                            p: 4,
                            color: "#757C8D",
                            borderRadius: "9px",
                            position: "relative",
                          }}
                        >
                          <img
                            src={EditIcon}
                            style={{ position: "absolute", top: 5, right: 5 }}
                            onClick={() => handleReply(item)}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            {item?.adminreply}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography
                          onClick={() => handleReply(item)}
                          sx={{
                            color: "#106974",
                            fontSize: "14px",
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          Reply
                        </Typography>
                      )}
                    </Box>
                  </Box>
                );
              })}
              {reviewData?.review?.length > 3 ? (
                <Typography
                  sx={{
                    color: "#192335",
                    border: 1,
                    width: "150px",
                    borderRadius: "8px",
                    p: 2,
                    mt: 5,
                    mx: "auto",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setCount(reviewData?.review?.length)}
                >
                  Load More <img className="ml-2" src={tickicon} alt="" />{" "}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          ) : (
            "No Review is Available"
          )}
        </AccordionDetails>
      </Accordion>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          verticalAlign: "middle",
          m: "auto",
          display: "flex",
          border: "none",

          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div className="bg-white rounded-md flex flex-col m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
          <div className="text-[#192335] text-[18px] font-[500]">
            <div className=" flex justify-between">
              <label>Title of your Reply</label>
              <p
                className=" text-[red] cursor-pointer"
                onClick={() => setOpen(false)}
              >
                X
              </p>
            </div>
            <CustomTextField
              placeholder="If you could say it in one sentence, what would you say?"
              width="100%"
              value={reply}
              onChange={(e) => {
                setReply(e);
              }}
              required
              style={{
                backgroundColor: "#FAFAFA",
                color: "#B7B9BF",
                border: "none",
                borderRadius: "8px",
              }}
            />
          </div>
          <div className=" flex justify-center px-3">
            <CustomButton
              text={"Cancelled"}
              onClick={() => setOpen(false)}
              sx={{
                backgroundColor: "#F9F9F9",
                color: "#000",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#F9F9F9" },
                width: "100%",
                px: 6,
              }}
            />
            <CustomButton
              text={"Yes"}
              onClick={() => handleReplySubmit()}
              sx={{
                backgroundColor: "#192335",
                color: "#FFFFFF",
                borderRadius: "8px",
                fontSize: { sm: "14px", xs: "10px" },
                "&:hover": { bgcolor: "#192335" },
                width: "100%",
                px: 6,
                ml: 2,
              }}
            />
          </div>
        </div>
      </Modal>
    </Box>
  );
}

export default ReviewPage;
