import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, database } from "../firebase";
import {
  InsertDataWithUID,
  FetchData,
  FetchDataById,
  UpdateData,
} from "./crud";

export const signup = async (userData) => {
  try {
    const isAvailable = await checkPhoneNumberAvailability(
      userData.phonenumber
    );
    if (!isAvailable?.userAvilable) {
      let phonenumber = userData.phonenumber;
      userData.isuseractive = true;
      userData.owner = userData?.owner?.value;
      const recaptchaverifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        { size: "invisible" }
      );
      recaptchaverifier.render();
      const data = await signInWithPhoneNumber(
        auth,
        phonenumber,
        recaptchaverifier
      );

      return { data: data, userdata: userData };
    } else {
      console.log("Phone number is Already registered.", userData.phonenumber);
      return {
        data: null,
        error: "Phone number is Already registered.",
        msg: "Phone number is Already registered.",
      };
    }
  } catch (err) {
    console.log("Error sending OTP:", err);
    return { data: null, error: err, msg: "Incorrect Login Credentials" };
  }
};

export const verifyOtp = async (otp, response) => {
  let valueOTP = otp;

  if (valueOTP === "" || valueOTP === null) {
    return null;
  }

  try {
    const data = await response.data.confirm(valueOTP);
    const user = auth.currentUser;
    const uid = user.uid;
    // isuseractive

    // response?.userData.isuseractive = true
    //
    const userdata = await InsertDataWithUID("user", response?.userdata, uid);
    return { data: userdata, null: null, msg: "user signup successfully" };
  } catch (err) {
    return { data: null, error: err, msg: "Incorrect Login Credentials" };
  }
};

const checkPhoneNumberAvailability = async (phoneNumber) => {
  try {
    const usersRef = await FetchData("user");
    const checkAvilable = usersRef?.filter((user) => {
      return user.phonenumber == phoneNumber;
    });
    const userAvilable = checkAvilable.length == 0 ? false : true;
    return { userAvilable: userAvilable, usersRef: usersRef };
  } catch (error) {
    return false;
  }
};

export const login = async (phonenumber) => {
  try {
    const isAvailable = await checkPhoneNumberAvailability(phonenumber);
    const activeUser = isAvailable?.usersRef?.filter((user) => {
      return user.isuseractive == true && user.phonenumber == phonenumber;
    });
    const Userdetails = isAvailable?.usersRef?.filter((user) => {
      return user.phonenumber == phonenumber;
    });
    if (isAvailable.userAvilable && activeUser.length) {
      const recaptchaverifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        { size: "invisible" }
      );
      recaptchaverifier.render();
      const response = await signInWithPhoneNumber(
        auth,
        phonenumber,
        recaptchaverifier
      );
      // return response
      return { data: response, error: null, msg: "" };
    } else {
      if (!isAvailable.userAvilable) {
        return {
          data: null,
          error: "Phone number is Not registered.",
          msg: "Phone number is Not registered.",
        };
      } else if (!activeUser.length) {
        return {
          data: null,
          error: "Inactive user.",
          msg: "INactive User.",
        };
      }
    }
  } catch (error) {
    console.log("Error sending OTP:", error);
    return error;
  }
};

export const loginverifyOtp = async (otp, response) => {
  let valueOTP = otp;

  if (valueOTP === "" || valueOTP === null) {
    return null;
  }

  try {
    const data = await response.data.confirm(valueOTP);
    const user = auth.currentUser;
    let uid = user.uid;
    const userData = await FetchDataById("user", uid);
    return { userData, error: null, msg: "User logged in successfully" };
  } catch (err) {
    return { data: null, error: err, msg: "Incorrect Login Credentials" };
  }
};

export const adminConfig = async (allowadmin) => {
  let result = await FetchData("config", "venzoAdminId");
  try {
    if (result.length) {
      const data = await UpdateData(
        "config",
        { allowadmin: allowadmin },
        "venzoAdminId"
      );
      return data;
    }
  } catch (error) {
    return error;
  }
};

export const getadminconfig = async () => {
  let result = await FetchData("config", "venzoAdminId");
  return result.length ? result[0] : null;
};
