import React, { useEffect, useState } from "react";
import CustomButton from "../Common/ButtonComponent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { CustomTable } from "../Common/TableComponent";
import { Box, CircularProgress, Chip, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
  changeEventStatus,
  getEventdetailsByType,
} from "../../firebaseFunction/eventDetails";
import { Modal } from "@mui/material";

import { getTimeFormatted } from "../Common/GetHours";
import moment from "moment";
import { findElementsWithSameProp } from "../../utils/helper";
import { BookingCard } from "./BookingCard";
import { statusMap } from "../../utils/statusMap";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function BookingDetails({ groundId }) {
  const headers = [
    {
      key: "ground",
      label: "Court Name",
      align: "left",
      fontSize: "15px",
      width: "100px",
      valueRenderer: (rowData) => {
        const datum = rowData[0];
        return (
          <div className="flex flex-row gap-2 w-[170px]">
            <img
              src={datum["images"]}
              alt="img"
              className="h-[45px] w-[45px]"
            />
            <div className="pt-1">
              <h1 className="text-[14px] font-[500] text-nowrap">
                {datum["ground_name"]}
              </h1>

              <h1 className="text-[12px] text-[#097E52]">{datum.court_name}</h1>
            </div>
          </div>
        );
      },
    },
    {
      key: "start",
      label: "Date & Time",
      align: "left",
      fontSize: "15px",
      valueRenderer: (rowData) => {
        return rowData.map((datum, index) => {
          const startdateTime = new Date(datum["start"]);

          const dayOfWeek = daysOfWeek[startdateTime.getDay()];
          const month = months[startdateTime.getMonth()];
          const day = startdateTime.getDate();

          let hours = startdateTime.getHours();
          const minutes = startdateTime.getMinutes();

          let ampm = "AM";
          if (hours >= 12) {
            ampm = "PM";
            hours %= 12;
          }
          if (hours === 0) {
            hours = 12;
          }

          return (
            <div className="flex flex-col  ">
              {index == 0 ? (
                <h1 className="text-[12px] font-[400] ">{`${dayOfWeek}, ${month} ${day
                  .toString()
                  .padStart(2, "0")}`}</h1>
              ) : (
                ""
              )}
              <Chip
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "Outfit",
                  padding: "2px",
                  height: "0",
                  marginTop: "4px",
                }}
                variant="outlined"
                label={`${hours}:${minutes
                  .toString()
                  .padStart(2, "0")} ${ampm} `}
              />
            </div>
          );
        });
      },
    },

    {
      key: "payment",
      label: "Payment",
      align: "center",
      valueRenderer: (rowData) => {
        const flattenedArray = rowData.flat();
        const sumOfProp2 = flattenedArray.reduce(
          (sum, obj) => sum + parseInt(obj.amount),
          0
        );

        return (
          <div>
            <h1 className="text-[14px] font-[600] "> &#8377;{sumOfProp2}</h1>
          </div>
        );
      },
    },
    {
      key: "status",
      label: "Status",
      align: "left",
      valueRenderer: (rowData) => {
        return (
          <div className="flex flex-col gap-1">
            {rowData.length > 1 && <p className="text-white leading-3">---</p>}
            {rowData.map((datumm) => {
              return (
                <div
                  className={`text-[10px] font-[400] text-[${
                    statusMap[datumm.status]?.color
                  }] justify-center rounded-sm flex flex-row gap-1 w-full bg-[${
                    statusMap[datumm.status]?.bgColor
                  }] p-2 items-center`}
                >
                  {statusMap[datumm.status]?.icon}
                  <span className="text-nowrap text-[11px] leading-3.5">
                    {statusMap[datumm.status]?.text || datumm.status}
                  </span>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const [statusopen, setstatusopen] = useState(false);
  const [canBePaid, setCanBePaid] = useState(false);

  const [cancelEventind, setCancelEventind] = useState([]);

  const [tab, setTab] = useState("Bookings");
  const [finalData, setfinalData] = useState([]);
  const [filter, setFilter] = useState("This Month");
  const [selectedCancelEventData, setselectedCancelEventData] = useState([]);
  const [trigger, setTrigger] = useState([false]);

  const [data, setdata] = useState([]);
  const [noData, setNoData] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let uid = localStorage.getItem("uid");
  const handleUpdateStatus = async (props) => {
    if (checkSamePropertyValue(selectedCancelEventData)) {
      const mapcosnt = await selectedCancelEventData.map(
        (selectedCancelEventDatum) => {
          cancelEvent(selectedCancelEventDatum, props);
        }
      );
      Promise.resolve();
      setstatusopen(false);
      setdata([]);
      setCancelEventind([]);
      setselectedCancelEventData([]);
      // eventData();
      console.log(mapcosnt, "eventData");
      setTrigger(!trigger);
    } else {
      setCanBePaid(true);
    }
  };
  const cancelEvent = async (selectedEventDatum, props) => {
    await changeEventStatus(selectedEventDatum?.event_id, props);
    Promise.resolve();
  };
  const handlefilter = async (value) => {
    setdata([]);
    setNoData(false);
    setFilter(value);
  };

  const handleChange = async (value) => {
    if (tab !== value) setdata([]);
    setNoData(false);
    setTab(value);
  };

  const isowner = localStorage.getItem("isowner");

  useEffect(() => {
    eventData();
  }, [tab, filter]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      eventData();
    }, 2000);
  }, [trigger]);

  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        navigate("/players/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  const eventData = async () => {
    setLoading(true);
    if (uid == null) {
      navigate("/login");
    }

    let startDate = moment().format("YYYY-MM-DDTHH:mm");
    let endOfMonth = moment().endOf("month").format("YYYY-MM-DDTHH:mm");

    let statusValue = ["Accepted", "Awaiting"];

    if (tab === "Cancelled") {
      statusValue = ["Cancelled", "Canceled"];
      startDate = moment().startOf("month").format("YYYY-MM-DDTHH:mm");
    } else if (tab === "Completed") {
      statusValue = [tab];
      startDate = moment().startOf("month").format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment().format("YYYY-MM-DDTHH:mm");
    } else if (tab !== "Bookings" && tab !== "Cancelled") {
      statusValue = [tab];
    }

    if (filter === "Last Month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DDTHH:mm");
    } else if (filter === "Next Month") {
      startDate = moment()
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment()
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DDTHH:mm");
    }
    const otherFilters = [
      { key: "status", operator: "in", value: statusValue },
      { key: "start", operator: ">=", value: startDate },
      { key: "end", operator: "<=", value: endOfMonth },
    ];
    if (
      otherFilters &&
      otherFilters.length > 0 &&
      data.length === 0 &&
      !noData
    ) {
      const response = await getEventdetailsByType(
        groundId,
        "owner",
        { key: "start", dir: "asc" },
        null,
        otherFilters
      );

      const events = response?.data;
      setdata(events);
      setNoData(events?.length === 0);

      setfinalData(findElementsWithSameProp(response?.data));
    }

    setLoading(false);
  };

  const handlestatusEdit = (data) => {
    if (tab == "Bookings") {
      setSelectedEventData(data);
      setstatusopen(true);
    }
  };

  function checkSamePropertyValue(array) {
    return array.reduce((acc, obj) => acc && obj.status !== "Accepted", true);
  }

  const handleCancelbooking = (value) => {
    setselectedCancelEventData((prev) => [...prev, value]);
  };

  return (
    <div className=" bg-[#F9F9F9] relative font-Outfit">
      <div className="flex flex-col items-center justify-center mt-4 gap-4 ">
        <div className="bg-white flex min-[770px]:flex-row max-[769px]:flex-col max-[769px]:mb-2 justify-between min-[770px]:gap-2 px-4 max-[770px]:pb-2 items-center w-[90%] rounded-lg">
          <div className="flex flex-row gap-2 overflow-x-auto   max-[769px]:w-full ">
            <CustomButton
              text={"Bookings"}
              onClick={() => handleChange("Bookings")}
              sx={
                tab == "Bookings"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { md: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
            <CustomButton
              text={"Completed"}
              onClick={() => handleChange("Completed")}
              sx={
                tab == "Completed"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
            <CustomButton
              text={"On going"}
              onClick={() => handleChange("Ongoing")}
              sx={
                tab == "Ongoing"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 2,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 2,
                    }
              }
            />
            <CustomButton
              text={"Cancelled"}
              onClick={() => handleChange("Cancelled")}
              sx={
                tab == "Cancelled"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
          </div>
          <FormControl
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
              backgroundColor: "#F9F9F9",
              borderRadius: "4px",
              color: "#757C8D",
              fontSize: "14px",
              fontFamily: "Outfit",
              width: "200px",
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="This Month"
              onChange={(e) => handlefilter(e.target.value)}
            >
              <MenuItem value={"Last Month"}>Last Month</MenuItem>
              <MenuItem value={"This Month"}>This Month</MenuItem>
              <MenuItem value={"Next Month"}>Next Month</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Box sx={{ mt: 2, display: { xs: "none", sm: "block" } }}>
        {!loading ? (
          <CustomTable
            showEdit={true}
            itemsPerPage={10}
            Title={"My Bookings"}
            SubTitle={"Track and manage your completed court bookings"}
            headers={headers}
            data={data}
            oneditClick={handlestatusEdit}
          />
        ) : (
          <div className="h-[60vh] justify-center items-center flex">
            <CircularProgress style={{ color: "#018352" }} />
          </div>
        )}
      </Box>

      <div className="min-[600px]:hidden w-full flex flex-col items-center my-2 pb-4 px-2">
        {!data?.length && (
          <div className="w-[95%] bg-white rounded-lg p-3 flex flex-col my-2">
            No data
          </div>
        )}
        {finalData?.map((item, index) => (
          <BookingCard
            key={index}
            bookingItem={item}
            eventData={eventData}
            type={item[0].status}
          />
        ))}
      </div>

      {statusopen && (
        <Modal
          open={statusopen}
          onClose={() => {
            setCancelEventind([]);
            setselectedCancelEventData([]);
            setstatusopen(false);
            setCanBePaid(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            verticalAlign: "middle",
            m: "auto",
            display: "flex",
            border: "none",

            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="bg-white rounded-md flex flex-col m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
            <div className=" flex  justify-between">
              <div className="text-[#192335] text-[18px] font-[500]">
                Select the event to change the status{" "}
              </div>
              <p
                className=" text-[red] cursor-pointer"
                onClick={() => {
                  setCancelEventind([]);
                  setselectedCancelEventData([]);
                  setstatusopen(false);
                  setCanBePaid(false);
                }}
              >
                X
              </p>
            </div>
            <div className=" grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2 max-[320px]:grid-cols-2 justify-center mt-2">
              {selectedEventData?.map((item, index) => {
                let gttime = getTimeFormatted(item?.start);
                return (
                  <Button
                    sx={{
                      border: "1px solid green",
                      color: cancelEventind?.includes(index)
                        ? "white"
                        : "green",
                      backgroundColor: cancelEventind?.includes(index)
                        ? "green"
                        : "white",
                      ":hover": { backgroundColor: "green", color: "white" },
                    }}
                    onClick={() => {
                      setCancelEventind((prev) => [...prev, index]);
                      handleCancelbooking(item);
                    }}
                  >
                    <div>
                      <p>{gttime.Time}</p>
                      {item?.amount && (
                        <p className="text-black">&#8377; {item.amount}</p>
                      )}
                    </div>
                  </Button>
                );
              })}
            </div>
            {canBePaid && (
              <div className="text-red-500">
                An selected slot has already been accepted
              </div>
            )}
            <div className=" rounded-md p-2 flex justify-center gap-2 text-[#8F96A3]  text-[12px]">
              {/* {selectedEventData?.status != "Accepted" ? ( */}
              <CustomButton
                text={"Approve"}
                disabled={cancelEventind.length ? false : true}
                onClick={() => handleUpdateStatus("Accepted")}
                sx={{
                  backgroundColor: "#097E52",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#097E52" },
                  "&:disabled": {
                    backgroundColor: "#043522 ",
                  },
                }}
              />
              {/* ) : (
              ""
            )} */}
              <CustomButton
                text={"Cancelled"}
                onClick={() => handleUpdateStatus("Cancelled")}
                disabled={cancelEventind.length ? false : true}
                sx={{
                  backgroundColor: "#E50000",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#E50000" },
                  "&:disabled": {
                    backgroundColor: "#6f0000 ",
                  },
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default BookingDetails;
