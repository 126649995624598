// CustomButton.js
import React from "react";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";

const CustomButton = ({
  text,
  icon,
  iconPosition,
  iconWidth,
  iconHeight,
  onClick,
  onTouchStart,
  sx,
  disabled,
}) => {
  const renderIcon = () => {
    if (!icon) return null;

    return (
      <img
        src={icon}
        alt="Button Icon"
        style={{
          marginRight: iconPosition === "nextToText" ? "14px" : "0",
          width: iconWidth,
          height: iconHeight,
          color: "inherit !important",
        }}
      />
    );
  };

  return (
    <Button
      onClick={onClick}
      onTouchStart={onTouchStart}
      disabled={disabled ? disabled : false}
      startIcon={iconPosition === "start" ? renderIcon() : null}
      endIcon={iconPosition === "end" ? renderIcon() : null}
      sx={{
        px: 1,
        py: 1,
        my: 2,
        fontFamily: "Outfit",
        "&.Mui-disabled": { color: "#FFF", backgroundColor: "#A9A9A9" },
        ...sx,
      }}
    >
      {iconPosition == "top" ? (
        <Box sx={{ display: "block", justifyContent: "center" }}>
          <img
            src={icon}
            alt="Button Icon"
            style={{
              width: iconWidth,
              height: iconHeight,
              color: "inherit !important",
              margin: "auto",
            }}
          />
          <Typography sx={{ padding: "8px 0 0 0" }}>{text}</Typography>
        </Box>
      ) : (
        <Typography>{text}</Typography>
      )}
    </Button>
  );
};

export default CustomButton;
