import badmintonIcon from "../images/badmintonIcon.svg";
import CricketIcon from "../images/cricketIcon.svg";
import FootballIcon from "../images/footballIcon.svg";
import VolleyballIcon from "../images/volleyballIcon.svg";
import HockeyIcon from "../images/hockeyIcon.svg";
import BasketballIcon from "../images/BasketballIcon.svg";
import BaseballIcon from "../images/Baseball.svg";
import SoftballIcon from "../images/TennisIcon.svg";
import ArcheryIcon from "../images/ArcheryIcon.svg";
import tabletennisIcon from "../images/tabletennisIcon.svg";

export const ICONS = {
  Cricket: CricketIcon,
  Badminton: badmintonIcon,
  "Table Tennis": tabletennisIcon,
  Football: FootballIcon,
  Volleyball: VolleyballIcon,
  Hockey: HockeyIcon,
  Basketball: BasketballIcon,
  Archery: ArcheryIcon,
  Baseball: BaseballIcon,
  Softball: SoftballIcon,
};
