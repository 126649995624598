import React, { useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import gallery1 from "../../images/gallery1.svg";
import gallery2 from "../../images/gallery2.svg";
import gallery3 from "../../images/gallery3.svg";
import deleteIcons from "../../images/editIcon.svg";
import greenfavicon from "../../images/greenfavIcon.svg";
import { RWebShare } from "react-web-share";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  TextField,
  Typography,
  Modal,
  CircularProgress,
  IconButton,
} from "@mui/material";
import tickIocn from "../../images/TickIcon.svg";
import locationicon from "../../images/locationIocn.svg";
import phoneIcon from "../../images/phoneIcon.svg";
import mailIcon from "../../images/mailIcon.svg";
import mapIcon from "../../images/mapIcon.png";
import favIconGreen from "../../images/favIconGreen.svg";
import shareIcon from "../../images/ShareIcon.svg";
import chatIcon from "../../images/chatIcon.svg";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import vendortype from "../../images/vendortype.svg";
import CustomButton from "../Common/ButtonComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import badmintonIcon from "../../images/badmintonIcon.svg";
import CricketIcon from "../../images/cricketIcon.svg";
import FootballIcon from "../../images/footballIcon.svg";
import VolleyballIcon from "../../images/volleyballIcon.svg";
import HockeyIcon from "../../images/hockeyIcon.svg";
import BasketballIcon from "../../images/BasketballIcon.svg";
import BaseballIcon from "../../images/Baseball.svg";
import SoftballIcon from "../../images/TennisIcon.svg";
import ArcheryIcon from "../../images/ArcheryIcon.svg";
import tabletennisIcon from "../../images/tabletennisIcon.svg";
import grayclickIcon from "../../images/grayclickIcon.svg";
import greenTickIocn from "../../images/TickIcon.svg";
import redInfoIcon from "../../images/redInfoIcon.svg";
import greenCalnderIcon from "../../images/greenCalnderIcon.svg";
import calenderIcon from "../../images/myBookingIcon.svg";
import turfCoverBg from "../../images/turf_bg.jpg";
import dashboardtopImg from "../../images/Maskgroup.png";
import CustomTextField from "../Common/TextFiledComponet";
import arrowIcon from "../../images/right-arrow.svg";
import callIcon from "../../images/phoneIcon.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import addgreenIcon from "../../images/addgreenIcon.svg";
import RectangleIcon from "@mui/icons-material/Rectangle";
import { getTimeFormatted } from "../Common/GetHours";
import tickicon from "../../images/blackrecttickIcon.svg";

import groundImg1 from "../../images/groundImg1.svg";
import groundImg2 from "../../images/groundImg2.svg";
import groundImg3 from "../../images/groundImg3.svg";
import { CardComponent } from "../Common/CardComponent";
import uploadicon from "../../images/image_26.svg";
import CloseIcon from "@mui/icons-material/Close";
import greentickIcon from "../../images/TickIcon.svg";
import {
  Createreivew,
  createNewCourt,
  deleteFile,
  getallgroundData,
  getgroundDataById,
  getreview,
  removefavGround,
  uploadFile,
} from "../../firebaseFunction/groundDetails";
import { UpdateUserData, userData } from "../../firebaseFunction/userDetails";
import emailjs from "@emailjs/browser";
import { getcourtevent } from "../../firebaseFunction/eventDetails";
import CustomSelect from "../Common/SelectComponent";
import Reactangle1 from "../../images/Rectangle1.svg";
import Reactangle2 from "../../images/Rectangle2.svg";
import Reactangle3 from "../../images/Rectangle3.svg";
import { ArrowBack } from "@mui/icons-material";
import _ from "lodash";
import { ICONS } from "../../utils/icons";
import Maps from "./Maps";

function GroundDetails() {
  const [checked, setChecked] = React.useState(false);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const [userDetails, setuserDetail] = useState({
    name: "",
    email: "",
    phonenumber: "",
    tomailid: "deeraja@venzotechnologies.com",
    details: "",
  });

  const [selectedValue, setSelectedValue] = useState({
    Courts: "",
    gameType: "",
  });
  const [filteredCourts, setFilterdCourts] = useState([]);
  const [eventData, setEventData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];
  const currentDate2 = new Date();
  const [AccordionOpen, setAccordionOpen] = useState(false);

  const [courtTime, setCourtTime] = useState([]);
  const [activeTab, setActiveTab] = useState("Overview");
  const [favloading, setfavLoading] = useState(false);

  const templatedata = {
    to_name: userDetails.name,
    from_name: userDetails.name,
    phonenumber: userDetails.phonenumber,
    from_mailid: userDetails.email,
    to_mailid: userDetails.tomailid,
    message: userDetails.details,
  };
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .send("service_6cygz1i", "template_nfyi4dv", templatedata, {
        publicKey: "Y-ApHvZfuWfHPg4k8",
      })
      .then(
        (response) => {
          // setSuccess(true);
          toast.success("Mail sent", { position: "top-right" });
          setuserDetail({
            name: "",
            email: "",
            phonenumber: "",
            tomailid: "",
            details: "",
          });
          setChecked(false);
        },
        (error) => {
          // setError(true);
          toast.error("Try again later", { position: "top-right" });
          console.log(error);
        }
      );
  };

  const navigate = useNavigate();
  const [Mopen, setMOpen] = React.useState(false);
  const [avaopen, setavaOpen] = React.useState(false);
  const location = useLocation();
  const prams = new URLSearchParams(location.search);
  const state = prams.get("id");
  const [groundData, setGroundData] = useState([]);
  const [groundDetail, setGroundDetail] = useState();

  const [review, setReview] = useState({
    reviewTitle: "",
    reviewContent: "",
    reviewImage: [],
    rating: "",
  });

  const handleNavigate = async (elementId) => {
    // const sectionElement = document.getElementById(elementId.replace(/ /g, ""));
    var element = document.getElementById(elementId);
    element.scrollIntoView();
    if (elementId === "Reviews") {
      const reviewsData = await getreview(groundDetail?.ground_id);
      setGroundDetail({ ...groundDetail, review: reviewsData?.data?.review });
    }
    setActiveTab(elementId);
  };

  const [loader, setLoader] = useState(false);
  let uid = localStorage.getItem("uid");
  const [reviewerror, setError] = useState("");

  const handleClick = async (e) => {
    const name = "Review_IMG_" + new Date().getTime();
    let files = e.target.files;
    let Review_IMG_;
    if (files?.length) {
      Review_IMG_ = await uploadFile(uid, name, files[0], "reviewImages");
      let reviewImage = review?.reviewImage;
      Array.isArray(reviewImage)
        ? reviewImage.push(Review_IMG_)
        : (reviewImage = [Review_IMG_]);
      Promise.resolve();
      setReview({
        ...review,
        reviewImage: reviewImage,
      });
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const images = [gallery1, gallery2, gallery3, gallery1, gallery2, gallery3];
  const tabName = [
    "Overview",
    "SportsAvailable",
    "Includes",
    "Rules",
    "Amenities",
    "Gallery",
    "Reviews",
    "Locations",
  ];
  const [showAll, setShowAll] = useState(false);

  const [reviewErrors, setReviewErrors] = useState({
    reviewTitleError: "",
    reviewContentError: "",
    ratingError: "",
  });
  const [formValid, setFormValid] = useState(false);

  const handleShowMoreClick = () => {
    setShowAll(!showAll);
  };

  const handleBooking = () => {
    navigate("/players/booking", { state: { ground_id: state } });
  };
  const handleAddReview = async () => {
    validateForm();
    if (formValid) {
      review.ground_id = state;
      review.userId = uid;
      const response = await Createreivew(review);
      if (response?.status == "success") {
        handleClickClose();
        groundDetails();
      }
    }
  };
  const handleClickOpen = () => {
    if (uid == null || uid == undefined) {
      navigate("/login");
    }

    setMOpen(true);
    setError("");
    setReview({
      reviewTitle: "",
      reviewContent: "",
      reviewImage: [],
      rating: "",
    });
  };

  const handlereviewImageDelete = async (props, index) => {
    const updatedata = [...review?.reviewImage];
    updatedata.splice(index, 1);
    setReview({ ...review, reviewImage: updatedata });
    const reviewImg = await deleteFile(props, state);
  };

  const iconsss = ICONS;

  const [count, setCount] = useState(2);

  const [gamename, setgamename] = useState("");
  const handleGameclick = (value) => {
    const dat = `sportsData.${value}`;
    setgamename(value);
  };

  const handleChange = (value) => {
    setActiveTab(value);
    const sectionElement = document.getElementById(value.replace(/ /g, ""));
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRequest = () => {
    console.log(userDetails, "userDetails");
  };

  const handleCall = () => {
    console.log("call phone number");
  };

  const handleClickClose = () => {
    setMOpen(false);
  };

  const handleAvaClickClose = () => {
    setavaOpen(false);
    setSelectedValue({ gameType: "", Courts: "" });
    setAccordionOpen(false);
  };

  const groundDetails = async () => {
    const grounddata = await getgroundDataById(state, "user", uid, true);
    setGroundDetail(grounddata);
    setuserDetail({ ...userDetails, tomailid: grounddata.email });
    getCourttime(grounddata, currentDate2);
    console.log(grounddata, "gtr44");
  };

  const handleFav = async (data) => {
    if (uid == null || uid == undefined) {
      navigate("/login");
    }
    setfavLoading(true);
    if (!data?.favarote) {
      const userDetails = await userData(uid);
      if (uid != null) {
        userDetails?.favarote
          ? userDetails?.favarote.push(data?.ground_id)
          : (userDetails.favarote = [data?.ground_id]);
        const updatedata = await UpdateUserData(userDetails, uid);
        if (updatedata?.status == "success") {
          console.log("update successfully");
          groundDetails();
        } else {
          console.error("update data failed");
        }
      }
    } else {
      const removedata = await removefavGround(uid, data?.ground_id);
      if (removedata?.status == "success") {
        groundDetails();
      }
    }
    setfavLoading(false);
  };

  useEffect(() => {
    groundDetails();
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
    // window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state, eventData]);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      reviewTitleError: "",
      reviewContentError: "",
      ratingError: "",
    };

    if (!review.reviewTitle || review.reviewTitle.trim() === "") {
      errors.reviewTitleError = "Please enter a title for your review.";
      isValid = false;
    }
    if (!review.reviewContent || review.reviewContent.trim() === "") {
      errors.reviewContentError = "Please enter your review content.";
      isValid = false;
    }
    if (review.rating == 0) {
      errors.ratingError = "Please provide a rating.";
      isValid = false;
    }

    setReviewErrors(errors);
    setFormValid(isValid);
  };

  const optionsGameTypes = groundDetail?.game_type?.map((game, index) => ({
    id: index + 1,
    value: game,
    label: game,
  }));

  const optionsCourts = groundDetail?.court_details?.map((court, index) => ({
    id: index + 1,
    value: court.court_id,
    label: court.court_name,
    gameType: court.gametype,
  }));

  const handleGamechange = (value) => {
    setSelectedValue({ Courts: "", gameType: value });
    let filteredcourt = optionsCourts?.filter((court) => {
      return court?.gameType?.includes(value?.value);
    });
    setFilterdCourts(filteredcourt);
    if (selectedValue.Courts != "") {
      setAccordionOpen(true);
    }
  };

  const handleCourtChange = async (value) => {
    setSelectedValue({ ...selectedValue, Courts: value?.value });
    const data = await getcourtevent(value?.value);
    setEventData(data);
    if (selectedValue.gameType != "") {
      setAccordionOpen(true);
    }
  };

  const getCourttime = (groundData, date) => {
    let start;
    let end;
    let currentDate = new Date();
    let currentTime = currentDate.getHours();
    if (typeof date === "object") {
      start = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}T${currentTime.toString().padStart(2, "0")}:00`;
      end = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${
        groundData?.end_time
      }`;
    } else {
      start = `${date}T${groundData?.start_time}`;
      end = `${date}T${groundData?.end_time}`;
    }

    let daystart = new Date(start);
    let dayend = new Date(end);
    console.log("daysaet", daystart, typeof date);
    let hourdiff = ((dayend - daystart) / (1000 * 60 * 60)).toFixed(1);

    let eventss = [];
    let starttime = daystart;

    for (let j = 0; j < hourdiff; j++) {
      let en;

      en = {
        backgroundColor: "#fff",
        bordercolor: "#339A49",
        textColor: "#339A49",
        zIndex: "1",
        selected: false,
        isbooked: false,
      };
      en.start = new Date(starttime.getTime() + 1);
      en.end = new Date(starttime.getTime() + 60 * 60 * 1000);
      eventss.push(en);
      starttime = new Date(en.end.getTime() + 1);
    }

    eventss?.map(
      (court) =>
        eventData.length &&
        eventData?.map((event) => {
          let date1 = new Date(event?.start);
          let date2 = new Date(court?.start);
          if (Math.abs(date1.getTime() - date2.getTime()) <= 1000) {
            court.isbooked = true;
            court.backgroundColor = "#EEF0F3";
            court.textColor = "#64627C";
            court.bordercolor = "#64627C";
          }
        })
    );
    let eventarr = eventss.map((element, index) => {
      if (
        new Date(element.start).getHours() == currentTime &&
        new Date(element.start).getDate() == currentDate.getDate()
      ) {
        element = {
          ...element,

          color: "#FFF",
          backgroundColor: "#FFF",
          bordercolor: "#00b4d8",
          textColor: "#00b4d8",
        };
        // console.log(element, "eventarr");

        return element;
      } else {
        return element;
      }
    });
    setCourtTime(eventarr);
  };

  const isowner = localStorage.getItem("isowner");
  useEffect(() => {
    if (uid != null) {
      if (isowner == "true") {
        console.log("admin true");
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <Box className=" capitalize  font-Outfit">
      {groundDetail ? (
        <Box
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + dashboardtopImg})`,
            backgroundSize: "100% 250px",
            backgroundRepeat: "repeat-x",
          }}
        >
          {groundDetail?.coverImage?.length > 1 ? (
            <Carousel
              responsive={responsive}
              infinite={false}
              // autoPlay={true}
              // autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              removeArrowOnDeviceType={["mobile"]}
              containerClass="carousel-container  justify-center"
              itemClass="mr-0 !w-[270px]"
            >
              {groundDetail?.coverImage.map((image, index) => (
                <img
                  className="!rounded-lg h-[250px] w-full object-cover"
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                />
              ))}
            </Carousel>
          ) : (
            <img
              src={groundDetail?.coverImage[0] || dashboardtopImg}
              className="w-full h-[250px]"
              alt="cover pic"
            />
          )}
          <Box sx={{ width: "100%", p: { xs: 2, md: 5 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    display: "flex",
                    fontSize: "30px",
                    fontWeight: 600,
                    color: "#192335",
                  }}
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack />
                  </IconButton>
                  {_.startCase(groundDetail?.groundname)}
                  <img className="ml-2" src={tickIocn} alt="tixckIcon" />
                </Typography>
                <Box
                  sx={{ display: "flex", flexWrap: { xs: "wrap" }, my: 2 }}
                  gap={{ xs: 2, md: 3 }}
                >
                  <Stack direction={"row"}>
                    <img src={locationicon} alt="icon" />
                    <Typography
                      sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}
                    >
                      {groundDetail?.street_address}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <img src={phoneIcon} alt="icon" />
                    <Typography
                      sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}
                    >
                      {groundDetail?.phonenumber}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <img src={mailIcon} alt="icon" />
                    <Typography
                      sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}
                    >
                      {groundDetail?.email}
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  my: 2,
                  alignItems: { xs: "left", md: "center" },
                  gap: { sm: "8px", md: "12px" },
                }}
                gap={3}
              >
                <Stack direction={"row"} sx={{ gap: { sm: "10px" }, mb: 2 }}>
                  <Stack direction={"row"} sx={{ alignItems: "center", mr: 1 }}>
                    <RWebShare
                      data={{
                        text: "Like humans, flamingos make friends for life",
                        url: window.location.href,
                        title: "Flamingos",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <div className=" flex">
                        <img
                          className="w-[12px] h-[13px]"
                          src={shareIcon}
                          alt="icon"
                        />
                        <Typography
                          sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}
                        >
                          Share
                        </Typography>
                      </div>
                    </RWebShare>
                  </Stack>
                  <Stack direction={"row"} sx={{ alignItems: "center" }}>
                    {!favloading ? (
                      <img
                        src={
                          groundDetail.favarote ? greenfavicon : favIconGreen
                        }
                        alt="icon"
                        className="w-[12px] h-[13px]"
                        onClick={() => handleFav(groundDetail)}
                      />
                    ) : (
                      <CircularProgress
                        style={{
                          color: "#018352",
                          width: "12px",
                          height: "13px",
                        }}
                      />
                    )}

                    <Typography
                      sx={{ color: "#8E94A2", fontSize: "12px", ml: 1 }}
                    >
                      Add to favourite
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{
                    alignItems: "center",
                    border: "1px solid #EAEDF0",
                    p: 2,
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#097E52",
                      color: "#FFF",
                      width: "37px",
                      height: "37px",
                      borderRadius: "50%",
                      alignItems: "center",
                      p: 0.5,
                    }}
                  >
                    {parseFloat(groundDetail?.overallRating).toFixed(1)}
                  </Typography>
                  <Box sx={{ textAlign: "left" }}>
                    <Rating
                      name="read-only"
                      value={groundDetail?.overallRating}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    <Typography
                      sx={{
                        color: "#7B8292",
                        textUnderlineOffset: "5px",
                        textDecoration: "underline",
                      }}
                    >
                      {groundDetail?.review?.length} Reviews
                    </Typography>
                  </Box>

                  <img
                    onClick={() => handleNavigate("Reviews")}
                    src={chatIcon}
                    alt="icon"
                    className="w-[20px] h-[20px] cursor-pointer"
                  />
                </Stack>
              </Box>
            </Box>
            <Divider variant="middle" />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                width: "100%",
                justifyContent: "space-between",
                alignItems: { xs: "left", sm: "center" },
                my: 2,
              }}
            >
              <Box sx={{ display: "flex", alignContent: "center" }}>
                <Stack direction={"row"} sx={{ mr: 2 }}>
                  <img
                    className="w-[37px] h-[37px] "
                    src={vendortype}
                    alt="icon"
                  />
                  <Box sx={{ textAlign: "left", ml: 1 }}>
                    <Typography sx={{ color: "#838998", fontSize: "12px" }}>
                      Venue Type
                    </Typography>
                    <Typography sx={{ color: "#1D2738", fontSize: "12px" }}>
                      {groundDetail?.groundtype}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction={"row"}>
                  <Avatar
                    sx={{ width: 37, height: 37 }}
                    src={groundDetail?.owner_data?.profileimg}
                  />
                  <Box sx={{ textAlign: "left", ml: 1 }}>
                    <Typography sx={{ color: "#838998", fontSize: "12px" }}>
                      Court Owner
                    </Typography>
                    <Typography sx={{ color: "#1D2738", fontSize: "12px" }}>
                      {groundDetail?.owner_data?.username}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <Typography sx={{ color: "#757C8D", fontSize: "14px" }}>
                  Starts From :{" "}
                  <span className="text-[#097E52] text-[20px]">
                    ₹{groundDetail?.starting_amount}
                    <span className="text-[14px]">/hr</span>
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ backgroundColor: "#F9F9F9" }}>
            <Box
              sx={{
                mx: "auto",
                px: 2,
                display: "flex",
                flexDirection: { xs: "column-reverse", sm: "row" },
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "65%" }, py: 2 }}>
                <Box
                  sx={{
                    backgroundColor: { xs: "transparent", sm: "#fff" },
                    borderRadius: "12px",
                  }}
                >
                  <Box
                    gap={1}
                    sx={{
                      display: "flex",
                      // justifyContent: "center",
                      overflowX: "auto",
                    }}
                  >
                    {tabName?.map((item) => (
                      <CustomButton
                        key={item}
                        text={
                          item == "SportsAvailable" ? "Sports Available" : item
                        }
                        onClick={() => handleNavigate(item)}
                        sx={
                          activeTab === item
                            ? {
                                backgroundColor: "#192335",
                                color: "#FFFFFF",
                                borderRadius: "8px",
                                fontSize: {
                                  md: "14px !important",
                                  sm: "12px !important",
                                },
                                "&:hover": { bgcolor: "#192335" },
                                width: "100%",
                                px: 6,
                              }
                            : {
                                backgroundColor: "#FFFFFF",
                                color: "#6B7385",
                                borderRadius: "8px",
                                fontSize: { md: "14px", sm: "12px" },
                                width: "100%",
                                px: 6,
                                "&:hover": { bgcolor: "#F9F9F9" },
                                border: 1,
                              }
                        }
                      />
                    ))}
                  </Box>
                </Box>
                <Box sx={{ my: 2 }} id="Overview">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Overview
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "16px",
                          color: "#8F96A3",
                        }}
                      >
                        {showAll
                          ? groundDetail?.overview
                          : `${groundDetail?.overview?.slice(0, 200)}...`}
                      </Typography>
                    </AccordionDetails>
                    <AccordionDetails
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <img src={addgreenIcon} alt="icon" />
                      <Typography
                        sx={{
                          color: "#228B63",
                          fontSize: "12px",
                          cursor: "pointer",
                          ml: 1,
                        }}
                        onClick={handleShowMoreClick}
                      >
                        {showAll ? "Show Less" : "Show More"}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="SportsAvailable">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Sports Available
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Stack
                        direction={"row"}
                        gap={2}
                        sx={{ overflowX: "auto" }}
                      >
                        {groundDetail?.game_type?.map((item) => (
                          <Box
                            onClick={() => handleGameclick(item)}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "85px",
                              height: "90px",
                              padding: 2,
                              borderRadius: "12px",
                              border: 1,
                              borderColor: "#F9F9F6",
                              backgroundColor: "#F9F9F6",
                            }}
                          >
                            <img
                              className="w-[52px] h-[35px] mx-auto "
                              src={iconsss[item]}
                              alt="icon"
                            />
                            <Typography
                              sx={{
                                color: "#192335",
                                mt: 1,
                                fontSize: "12px",
                                fontWeight: 500,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.replace("_", " ")}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Includes">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Includes
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Stack
                        direction={"row"}
                        gap={2}
                        sx={{ flexWrap: "wrap" }}
                      >
                        {groundDetail?.includes?.map((item) => (
                          <Box
                            sx={{
                              p: "1% 2%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "12px",
                              border: 1,
                              borderColor: "#F9F9F6",
                              backgroundColor: "#F9F9F6",
                            }}
                          >
                            <img
                              className="w-[15px] h-[15px] mx-auto"
                              src={grayclickIcon}
                              alt="icon"
                            />
                            <Typography
                              sx={{
                                color: "#192335",
                                pl: 1,
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Rules">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Rules
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Stack direction={"column"} gap={2}>
                        {groundDetail?.rules?.map((item) => (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              className="w-[15px] h-[15px]"
                              src={redInfoIcon}
                              alt="icon"
                            />
                            <Typography
                              sx={{
                                color: "#192335",
                                pl: 1,
                                fontSize: "12px",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Amenities">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Amenities
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Stack
                        direction={"row"}
                        sx={{ flexWrap: "wrap" }}
                        gap={2}
                      >
                        {groundDetail?.amenities?.map((item) => (
                          <Box
                            sx={{
                              padding: "1% 2%",
                              flexWrap: "wrap",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              className="w-[15px] h-[15px] mx-auto"
                              src={greenTickIocn}
                              alt="icon"
                            />
                            <Typography
                              sx={{
                                color: "#192335",
                                pl: 1,
                                fontSize: "12px",
                                fontWeight: 500,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Gallery">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Gallery
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails sx={{ overflowX: "auto" }}>
                      {groundDetail?.gallery?.length ? (
                        <Carousel
                          responsive={responsive}
                          infinite={true}
                          // autoPlay={true}
                          // autoPlaySpeed={3000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          removeArrowOnDeviceType={["mobile"]}
                          containerClass="carousel-container"
                          itemClass="!m-0   !p-0 !gap-1"
                        >
                          {groundDetail?.gallery?.map((image, index) => (
                            <img
                              className="!rounded-lg h-[100px] "
                              key={index}
                              src={image}
                              alt={`Image ${index}`}
                            />
                          ))}
                        </Carousel>
                      ) : (
                        <Typography>No Available Images </Typography>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Reviews">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ mb: -2 }}
                    >
                      <Box
                        sx={{
                          display: { xs: "block", sm: "flex" },
                          alignItems: "center",
                          textAlign: "left",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                          Reviews
                        </Typography>
                        <CustomButton
                          text={"Write a Review"}
                          onClick={handleClickOpen}
                          sx={{
                            width: "150px",
                            mr: 3,
                            backgroundColor: "#106974",
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            fontSize: "14px",
                            "&:hover": { bgcolor: "#106974" },
                          }}
                        />
                      </Box>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 1 }} />
                    <AccordionDetails
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#F9F9F6",
                          width: { xs: "90%", sm: "50%" },
                          px: 3,
                          py: 5,
                          borderRadius: "12px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#192335",
                            fontSize: "22px",
                            fontWeight: 600,
                          }}
                        >
                          {parseFloat(groundDetail?.overallRating).toFixed(1)}
                        </Typography>
                        <Typography>out of 5.0</Typography>
                        <Rating
                          name="read-only"
                          value={groundDetail?.overallRating}
                          readOnly
                          sx={{ height: "5px !important" }}
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                      </Box>
                      {/* <Box sx={{ ml: 2, textAlign: "left" }}>
                         <Typography>Recommended by 97% of Players</Typography> 
                        <Box sx={{ textAlign: "left", my: 1 }}>
                          <Box sx={{ display: "flex", gap: 5 }}>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>5.0</Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>5.0</Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>5.0</Typography>
                              </Stack>
                            </Box>
                          </Box>
                          <Box sx={{ display: "flex", gap: 5, mt: 2 }}>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>5.0</Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography>5.0</Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Typography>Quality of Service</Typography>
                              <Stack direction={"row"}>
                                <Rating
                                  name="read-only"
                                  value={4}
                                  readOnly
                                  icon={<RectangleIcon fontSize="inherit" />}
                                  sx={{ height: "5px !important" }}
                                  precision={0.5}
                                  emptyIcon={
                                    <RectangleIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Typography
                                  sx={{ color: "#757C8D", fontSize: "14px" }}
                                >
                                  5.0
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Box> *
                      </Box> */}
                    </AccordionDetails>
                    <AccordionDetails>
                      {groundDetail?.review?.length ? (
                        <Box>
                          {groundDetail?.review
                            ?.slice(0, count)
                            ?.map?.((item) => {
                              return (
                                <Box>
                                  <Stack
                                    direction={"row"}
                                    sx={{ my: 2, alignItems: "center" }}
                                  >
                                    <Avatar
                                      sx={{ width: 50, height: 50 }}
                                      src={item?.profileimg}
                                    />
                                    <Box sx={{ textAlign: "left", ml: 1 }}>
                                      <Typography
                                        sx={{
                                          color: "#757C8D",
                                          fontSize: "16px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {item?.user_name} on{" "}
                                        <span>
                                          {new Date(
                                            item?.createdAt?.seconds * 1000 +
                                              Math.floor(
                                                item?.createdAt?.nanoseconds /
                                                  1e6
                                              )
                                          ).toLocaleDateString("en-GB")}
                                        </span>
                                      </Typography>
                                      <Stack direction={"row"}>
                                        <Rating
                                          name="read-only"
                                          value={item?.rating}
                                          readOnly
                                          sx={{ height: "5px !important" }}
                                          precision={0.5}
                                          emptyIcon={
                                            <StarIcon
                                              style={{ opacity: 0.55 }}
                                              fontSize="inherit"
                                            />
                                          }
                                        />
                                        <Typography
                                          sx={{
                                            color: "#707889",
                                            fontSize: "14px",
                                            ml: 1,
                                          }}
                                        >
                                          {parseFloat(item?.rating).toFixed(1)}
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  </Stack>
                                  <Box
                                    sx={{
                                      ml: { xs: 5, sm: 10 },
                                      textAlign: "left",
                                      width: "80%",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#192335",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        my: 2,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item?.reviewTitle}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#757C8D",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item?.reviewContent}
                                    </Typography>
                                    {item?.reviewImage?.length ? (
                                      <Box className="flex flex-wrap  overflow-x-auto">
                                        {item?.reviewImage?.map((item) => {
                                          return (
                                            <img
                                              src={item}
                                              alt="images"
                                              className="rounded-md m-2 h-[100px]"
                                            />
                                          );
                                        })}
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    {item?.adminreply ? (
                                      <Box
                                        sx={{
                                          backgroundColor: "#F9F9F6",
                                          mt: 1,
                                          p: 2,
                                          color: "#757C8D",
                                          borderRadius: "9px",
                                          position: "relative",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {item?.adminreply}
                                        </Typography>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              );
                            })}
                          {groundDetail?.review?.length >= 3 ? (
                            count <= 2 ? (
                              <Typography
                                sx={{
                                  color: "#192335",
                                  border: 1,
                                  width: "150px",
                                  borderRadius: "8px",
                                  p: 2,
                                  mt: 5,
                                  mx: "auto",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setCount(groundDetail?.review?.length)
                                }
                              >
                                Load More{" "}
                                <img className="ml-2" src={tickicon} alt="" />{" "}
                              </Typography>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Box>
                      ) : (
                        "No Review is Available"
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }} id="Locations">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Location
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <Stack
                        direction="row"
                        sx={{
                          width: "100%",
                          height: "100px",
                          overflow: "hidden",
                          justifyContent: "center",
                          alignContent: "center",
                          gap: 2,
                        }}
                      >
                        <img
                          src={mapIcon}
                          alt="map icon"
                          className="w-[70px] h-[50px]"
                        />
                        <p className="pt-2">{`${groundDetail?.street_address},${
                          groundDetail?.city
                        },${groundDetail?.state}${
                          groundDetail?.pincode
                            ? `-${groundDetail?.pincode}`
                            : ""
                        }`}</p>
                      </Stack>
                      <Maps />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "30%" }, mx: "auto", py: 2 }}>
                <Box
                  sx={{
                    backgroundColor: "#FFF",
                    py: 2,
                    borderRadius: "12px",
                    mx: "auto",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Stack direction={"row"} gap={2} sx={{ ml: 3 }}>
                    <Box
                      sx={{
                        mt: 1,
                        width: "35px",
                        height: "35px",
                        backgroundColor: "#F9F9F6",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        p: 1.5,
                      }}
                    >
                      <img src={greenCalnderIcon} alt="icon" />
                    </Box>
                    <Box
                      sx={{ textAlign: "left" }}
                      onClick={() => setavaOpen(true)}
                    >
                      <Typography
                        sx={{
                          color: "#192335",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        Availability
                      </Typography>
                      <Typography sx={{ color: "#6C7486", fontSize: "14px" }}>
                        Check availability on your convenient time
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#FFF",
                    py: 2,
                    borderRadius: "12px",
                    mx: "auto",
                    alignItems: "center",
                    my: 2,
                    textAlign: "left",
                    p: 3,
                  }}
                >
                  <Typography
                    sx={{ color: "#192335", fontSize: "18px", fontWeight: 600 }}
                  >
                    Book A Court
                  </Typography>
                  <Typography
                    sx={{
                      color: "#757C8D",
                      fontSize: "16px",
                      fontWeight: 600,
                      my: 2,
                    }}
                    className=" capitalize"
                  >
                    {groundDetail?.groundname},
                    <span className="font-400"> available Now </span>{" "}
                  </Typography>
                  <CustomButton
                    text={"Book Now"}
                    icon={calenderIcon}
                    iconPosition="start"
                    iconWidth={14}
                    iconHeight={14}
                    onClick={() => handleBooking("ground uid")}
                    sx={{
                      width: "100%",
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#192335" },
                    }}
                  />
                </Box>
                <form onSubmit={sendEmail}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ textAlign: "left" }}
                    >
                      <Typography sx={{ color: "#192335", fontWeight: 600 }}>
                        Request for Bulk Booking
                      </Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails>
                      <CustomTextField
                        placeholder={"Enter Name"}
                        label={"Name"}
                        labelPosition={"block"}
                        width={"95%"}
                        value={userDetails.name}
                        onChange={(e) => {
                          setuserDetail({ ...userDetails, name: e });
                        }}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          my: 1,
                        }}
                      />
                      <CustomTextField
                        placeholder={"Enter Email"}
                        label={"Email"}
                        labelPosition={"block"}
                        width={"95%"}
                        value={userDetails.email}
                        onChange={(e) => {
                          setuserDetail({ ...userDetails, email: e });
                        }}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          my: 1,
                        }}
                      />

                      <CustomTextField
                        placeholder={"Enter Mobile Number"}
                        label={"Mobile Number"}
                        labelPosition={"block"}
                        width={"95%"}
                        value={userDetails?.phonenumber}
                        onChange={(e) => {
                          if (e.length <= 10) {
                            setuserDetail({ ...userDetails, phonenumber: e });
                          }
                        }}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          my: 1,
                        }}
                      />
                      <CustomTextField
                        placeholder={"Enter Email"}
                        label={"To Email"}
                        labelPosition={"block"}
                        width={"95%"}
                        value={userDetails.tomailid}
                        // onChange={(e) => {
                        //   setuserDetail({ ...userDetails, email: e });
                        // }}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          my: 1,
                        }}
                      />
                      <CustomTextField
                        placeholder={"Enter your Comment"}
                        label={"Details"}
                        type={"textarea"}
                        labelPosition={"block"}
                        width={"95%"}
                        multiline
                        rows={4}
                        value={userDetails.details}
                        onChange={(e) => {
                          setuserDetail({ ...userDetails, details: e });
                        }}
                        sx={{
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          my: 1,
                        }}
                      />
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 5 }}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckChange}
                          color="success"
                        />
                        <Typography
                          sx={{
                            fontSize: "14px",
                            textAlign: "left",
                            color: "#757D8D",
                          }}
                        >
                          {" "}
                          By Clicking{" "}
                          <span className="font-[600] text-[#1C1C1C]">
                            {" "}
                            “Send Request”
                          </span>
                          , I agree to X Sports{" "}
                          <span className="font-[600] text-[#1C1C1C]">
                            Privacy Policy & Terms of Use
                          </span>
                        </Typography>
                      </Box>
                      <Button
                        type="submit"
                        disabled={!checked}
                        sx={{
                          width: "100%",
                          backgroundColor: "#192335",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          fontSize: "14px",
                          mt: 1,
                          "&.Mui-disabled": { color: "#757D8D " },
                          "&:hover": { bgcolor: "#192335" },
                        }}
                      >
                        Send Request
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </form>
                <Box
                  sx={{
                    backgroundColor: "#FFF",
                    py: 2,
                    borderRadius: "12px",
                    mx: "auto",
                    alignItems: "center",
                    my: 2,
                    textAlign: "left",
                    p: 3,
                  }}
                >
                  <Typography
                    sx={{ color: "#192335", fontSize: "18px", fontWeight: 600 }}
                  >
                    Court Owner Details
                  </Typography>
                  <Divider variant="middle" sx={{ ml: -1, my: 1 }} />

                  <Stack
                    direction={{ md: "row", sm: "column" }}
                    sx={{ my: 2, alignItems: "center" }}
                  >
                    <Avatar
                      sx={{ width: 70, height: 70 }}
                      src={groundDetail?.owner_data?.profileimg}
                    />
                    <Box sx={{ textAlign: "left", ml: 1 }}>
                      <Typography
                        sx={{
                          color: "#192335",
                          fontSize: "18px",
                          fontWeight: 600,
                          ml: { sm: 2 },
                        }}
                      >
                        {groundDetail?.owner_data?.username}
                      </Typography>
                      <Stack direction={{ md: "row", sm: "column" }}>
                        <Rating
                          name="read-only"
                          value={groundDetail?.overallRating}
                          readOnly
                          sx={{ height: "5px !important" }}
                          precision={0.5}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        <Typography
                          sx={{
                            color: "#707889",
                            fontSize: "14px",
                            ml: { sm: 2, md: 0.5 },
                            mt: { xs: "25px", sm: "18px", md: "0px" },
                          }}
                        >
                          {parseFloat(groundDetail?.overallRating).toFixed(1)}
                          {`(${groundDetail?.review?.length} Reviews)`}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <CustomButton
                    text={"Call"}
                    icon={callIcon}
                    iconPosition="start"
                    iconWidth={14}
                    iconHeight={14}
                    onClick={() => handleCall()}
                    sx={{
                      width: "100%",
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: "14px",
                      "&:hover": { bgcolor: "#192335" },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ textAlign: "left", px: 2, my: 2 }}>
              <Typography
                sx={{ color: "#192335", fontSize: "22px", fontWeight: 600 }}
              >
                Similar Venues
              </Typography>
              <Box
                className={` flex w-full gap-3 mt-5 justify-items-center max-[600px]:flex-col max-[600px]:items-center`}
              >
                {groundData?.slice(0, 3)?.map((item, index) => (
                  <CardComponent
                    key={index}
                    data={item}
                    onClick={handleFav}
                    type={"user"}
                  />
                ))}
              </Box>
            </Box> */}
          </Box>

          <Modal
            open={Mopen}
            onClose={handleClickClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              verticalAlign: "middle",
              m: "auto",
              display: "flex",
              overflowY: "auto",
            }}
          >
            <div className="bg-white rounded-md flex flex-col m-auto  p-4  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6">
              <div className="flex w-full justify-between items-center px-4 py-2">
                <p className="text-[#313A4A] text-[22px] font-[500]">
                  Write a Review
                </p>
                <p className=" text-[red]" onClick={handleClickClose}>
                  X
                </p>
              </div>
              <div className="border-[1px] w-[100%] h-[1px] flex border-[#FAFAFA] justify-center "></div>

              <div className="flex flex-col items-start gap-2 w-full px-4 py-2">
                <label>Title of your review</label>
                <CustomTextField
                  placeholder="If you could say it in one sentence, what would you say?"
                  width="100%"
                  value={review?.reviewTitle}
                  onChange={(e) => {
                    setReview({ ...review, reviewTitle: e });
                  }}
                  required
                  style={{
                    backgroundColor: "#FAFAFA",
                    color: "#B7B9BF",
                    border: "none",
                    borderRadius: "8px",
                  }}
                />
                {reviewErrors.reviewTitleError && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {reviewErrors.reviewTitleError}
                  </Typography>
                )}
              </div>
              <div className="flex flex-col items-start gap-2 w-full px-4">
                <label>Your Review</label>
                <textarea
                  placeholder="Enter your review"
                  value={review?.reviewContent}
                  onChange={(e) =>
                    setReview({ ...review, reviewContent: e.target.value })
                  }
                  className="w-full border-none bg-[#FAFAFA] rounded-[8px] h-[80px] focus:outline-none p-2"
                  required
                ></textarea>
                {reviewErrors.reviewContentError && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {reviewErrors.reviewContentError}
                  </Typography>
                )}
                <label className="text-[#73788C] text-[14px] font-[300]">
                  {100 - review?.reviewContent?.length} characters remaining
                </label>
              </div>
              <div className="flex flex-col items-start gap-2 w-full p-4">
                <label htmlFor="uploadimg">Upload Image</label>
                <input
                  id="uploadimg"
                  type="file"
                  className="hidden"
                  // onChange={(e) => handleFileUpload(e.target.files[0])}
                  onChange={(e) => handleClick(e)}
                />
                <label
                  htmlFor="uploadimg"
                  className="h-[100px] w-full border-dashed border-[2px] flex flex-col gap-1 justify-center items-center rounded-md border-[#8F96A3] cursor-pointer"
                >
                  <img
                    src={uploadicon}
                    alt="img icon"
                    className="w-[45px] h-[45px]"
                  />
                  <span className="text-[#707889] text-[14px]">
                    Upload Image
                  </span>
                </label>
              </div>
              <div className=" flex justify-start">
                {review?.reviewImage?.map((item, index) => {
                  return (
                    <div
                      onClick={() => handlereviewImageDelete(item, index)}
                      className="relative "
                    >
                      <img
                        src={deleteIcons}
                        alt="delt Icon"
                        className="absolute top-3 right-4"
                      />
                      <img
                        src={item}
                        alt="review image"
                        className="w-[100px] h-[100px]"
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col items-start my-2 w-full px-4">
                <label>Rating</label>
                <Rating
                  name="read-only"
                  value={review?.rating}
                  onChange={(e) =>
                    setReview({ ...review, rating: e.target.value })
                  }
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                  required
                />
                {reviewErrors.ratingError && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {reviewErrors.ratingError}
                  </Typography>
                )}
              </div>
              <div className="border-[1px] w-[100%] h-[1px] flex border-[#FAFAFA] justify-center "></div>
              <div className="flex w-full justify-end px-4">
                <CustomButton
                  text="Add Review"
                  onClick={handleAddReview}
                  sx={{
                    backgroundColor: "#097E52",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    borderRadius: "8px",
                    "&:hover": { bgcolor: "#097E52" },
                  }}
                />
              </div>
            </div>
          </Modal>

          <Modal
            open={avaopen}
            onClose={handleAvaClickClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              verticalAlign: "middle",
              m: "auto",
              display: "flex",
              overflowY: "auto",
            }}
          >
            <div className="bg-white rounded-md flex flex-col m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
              <div className="flex w-full justify-between items-center px-4 py-2">
                <p className="text-[#313A4A] text-[22px] font-[500]">
                  Available Timing
                </p>
                <p
                  className=" text-[red] cursor-pointer"
                  onClick={handleAvaClickClose}
                >
                  X
                </p>
              </div>

              <Box sx={{ width: "100%" }}>
                <Box>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>Basic Info</Typography>
                    </AccordionSummary>
                    <Divider variant="middle" />
                    <AccordionDetails
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 4,
                      }}
                    >
                      <CustomSelect
                        options={optionsGameTypes}
                        value={selectedValue?.gameType}
                        onChange={handleGamechange}
                        label="Game Type"
                        name="game_type"
                        labelPosition="block"
                        width="100%"
                        style={{ padding: "0", height: "50px" }}
                        placeholder={"Sports Type"}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                          backgroundColor: "#FAFAFA",
                          borderRadius: "8px",
                        }}
                      />
                      <CustomSelect
                        options={filteredCourts}
                        value={selectedValue?.Court}
                        onChange={handleCourtChange}
                        label="Courts"
                        name="courts"
                        labelPosition="block"
                        width="100%"
                        // icon={Reactangle1}
                        style={{ padding: "0", height: "50px" }}
                        placeholder={"Select Court"}
                        sx={{
                          border: "none",
                          "& fieldset": { border: "none" },
                          backgroundColor: "#FAFAFA",
                          borderRadius: "8px",
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Accordion expanded={AccordionOpen}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>Available Timing</Typography>
                    </AccordionSummary>
                    <Divider variant="middle" sx={{ mb: 3 }} />
                    <AccordionDetails className=" grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2  max-[320px]:grid-cols-2">
                      {courtTime?.map((item, index) => {
                        let gttime = getTimeFormatted(item?.start);
                        return (
                          <Button
                            sx={{
                              border: `1px solid ${item?.bordercolor}`,
                              color: item?.textColor,
                              backgroundColor: item?.backgroundColor,
                              ":hover": {
                                backgroundColor: item.backgroundColor,
                              },
                              whiteSpace: "nowrap",
                            }}
                            disabled={item.isbooked}
                          >
                            {gttime.Time}
                          </Button>
                        );
                      })}
                    </AccordionDetails>
                    <AccordionDetails>
                      <Stack
                        direction={{ sm: "row", xs: "column" }}
                        gap={{ sm: 4, xs: 2 }}
                      >
                        <Stack direction={"row"}>
                          <img src={Reactangle3} alt="icon" />
                          <Typography
                            sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}
                          >
                            Booked
                          </Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <img src={Reactangle2} alt="icon" />
                          <Typography
                            sx={{ fontSize: "14px", color: "#64627C", ml: 1 }}
                          >
                            Available
                          </Typography>
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </div>
          </Modal>
        </Box>
      ) : (
        <div className="h-[100vh] justify-center items-center flex">
          <CircularProgress style={{ color: "#018352" }} />
        </div>
      )}
      <ToastContainer />
    </Box>
  );
}

export default GroundDetails;
