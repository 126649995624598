import EditIcon from "../../images/editIcon.svg";
import React, { useImperativeHandle, useState } from "react";
import CustomButton from "../Common/ButtonComponent";
import { changeEventStatus } from "../../firebaseFunction/eventDetails";
import { Button, Modal } from "@mui/material";
import { getTimeFormatted } from "../Common/GetHours";
import _ from "lodash";
import { ICONS } from "../../utils/icons";
import { statusMap } from "../../utils/statusMap";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function checkSamePropertyValue(array) {
  return array.reduce((acc, obj) => acc && obj.status !== "Accepted", true);
}

export const BookingCard = React.forwardRef(
  (
    {
      bookingItem,
      index,
      groundIds,
      eventData,
      type,
      showMenu = false,
      width,
      showShort = false,
    },
    ref
  ) => {
    const [selectedEventData, setSelectedEventData] = useState();
    const [statusopen, setstatusopen] = useState(false);
    const [selectedCancelEventData, setselectedCancelEventData] = useState([]);
    const [cancelEventind, setCancelEventind] = useState([]);
    const [canBePaid, setCanBePaid] = useState(false);

    const handleUpdateStatus = async (props) => {
      if (checkSamePropertyValue(selectedCancelEventData)) {
        const mapcosnt = await selectedCancelEventData.map(
          async (selectedCancelEventDatum) => {
            await updateBooking(selectedCancelEventDatum, props);
          }
        );
        setstatusopen(false);
        setCancelEventind([]);
        setselectedCancelEventData([]);
        await eventData(groundIds);
      } else {
        setCanBePaid(true);
      }
    };

    const updateBooking = async (selectedEventDatum, props) => {
      await changeEventStatus(selectedEventDatum?.event_id, props);
      Promise.resolve();
    };

    const handleCancelbooking = (value) => {
      setselectedCancelEventData((prev) => [...prev, value]);
    };

    const flattenedArray = bookingItem.flat();
    const sumOfProp2 = flattenedArray.reduce(
      (sum, obj) => sum + parseInt(obj.amount),
      0
    );
    useImperativeHandle(ref, () => ({
      handlestatusEdit,
    }));

    const handlestatusEdit = (data) => {
      setSelectedEventData(data || bookingItem);
      setstatusopen(true);
    };

    const firstSlot = new Date(bookingItem[0].start);
    const lastSlot = new Date(_.last(bookingItem).end);
    const day = daysOfWeek[firstSlot.getDay()];
    const month = months[firstSlot.getMonth()];
    const date = firstSlot.getDate();

    const renderSlots = () => {
      if (showShort) {
        const status = bookingItem[0]?.status;
        const startdateTime = new Date(firstSlot);
        const enddateTime = new Date(lastSlot);
        let hours = startdateTime.getHours();
        const minutes = startdateTime.getMinutes();
        let ampm = "AM";
        if (hours >= 12) {
          ampm = "PM";
          hours %= 12;
        }
        if (hours === 0) {
          hours = 12;
        }
        let hours2 = enddateTime.getHours();
        const minutes2 = enddateTime.getMinutes();
        let ampm2 = "AM";
        if (hours2 >= 12) {
          ampm2 = "PM";
          hours2 %= 12;
        }
        if (hours2 === 0) {
          hours2 = 12;
        }
        const statusObj = statusMap[status];
        const { icon, bgColor, color } = statusObj || {};
        return (
          <div className="flex flex-row justify-between w-full items-cente xs:flex-wrap sm:text-wrap">
            <React.Fragment key={index}>
              <div className="flex flex-row justify-between text-[12px] font-[400] items-center">
                {`${hours}:${minutes.toString().padStart(2, "0")} ${ampm} -
                      ${hours2}:${minutes2
                  .toString()
                  .padStart(2, "0")} ${ampm2}`}
              </div>
              {statusObj && (
                <div
                  className={`text-[10px] font-[400] text-[${color}] justify-center items-center rounded-sm flex flex-row gap-1 bg-[${bgColor}] p-1`}
                >
                  {icon}
                  <span className="text-nowrap text-[11px] leading-3.5">
                    {status}
                  </span>
                </div>
              )}
            </React.Fragment>
          </div>
        );
      }

      return bookingItem.map(({ start, end, status }, index) => {
        const startdateTime = new Date(start);
        const enddateTime = new Date(end);
        let hours = startdateTime.getHours();
        const minutes = startdateTime.getMinutes();
        let ampm = "AM";
        if (hours >= 12) {
          ampm = "PM";
          hours %= 12;
        }
        if (hours === 0) {
          hours = 12;
        }
        let hours2 = enddateTime.getHours();
        const minutes2 = enddateTime.getMinutes();
        let ampm2 = "AM";
        if (hours2 >= 12) {
          ampm2 = "PM";
          hours2 %= 12;
        }
        if (hours2 === 0) {
          hours2 = 12;
        }
        const statusObj = statusMap[status];
        const { icon, bgColor, color } = statusObj || {};
        return (
          <div className="flex flex-row justify-between w-full items-cente xs:flex-wrap sm:text-wrap">
            <React.Fragment key={index}>
              <div className="flex flex-row justify-between text-[12px] font-[400] items-center">
                {`${hours}:${minutes.toString().padStart(2, "0")} ${ampm} -
                    ${hours2}:${minutes2.toString().padStart(2, "0")} ${ampm2}`}
              </div>
              {statusObj && (
                <div
                  className={`text-[10px] font-[400] text-[${color}] justify-center items-center rounded-sm flex flex-row gap-1 bg-[${bgColor}] p-1`}
                >
                  {icon}
                  <span className="text-nowrap text-[11px] leading-3.5">
                    {status}
                  </span>
                </div>
              )}
            </React.Fragment>
          </div>
        );
      });
    };

    return (
      <div
        className={`w-[${width ? width + "px" : "95%"}] bg-[${
          type ? statusMap[type]?.cardColor : "white"
        }]  rounded-lg p-3 flex flex-col  justify-between md:mt-2 mt-3 mx-2`}
        key={index}
      >
        <div className="flex flex-row justify-between w-full item-center">
          <div className="flex flex-row truncate text-wrap pt-2 text-left">
            {`${_.startCase(bookingItem[0].ground_name)} | ${_.startCase(
              bookingItem[0].court_name
            )}`}
          </div>
          {showMenu && (
            <img
              onClick={() => handlestatusEdit(bookingItem)}
              src={EditIcon}
              alt="Edit"
              style={{
                width: "20px",
                height: "20px",
                zIndex: 100,
                cursor: "pointer",
              }}
              width={20}
              height={20}
            />
          )}
        </div>

        <div className="w-full items-center pt-3">
          <div className="flex flex-row justify-between text-[12px] font-[400] items-center">
            {`${day}, ${month} ${date.toString().padStart(2, "0")}`}
          </div>
          {renderSlots()}
        </div>
        <div className="flex flex-row justify-between w-full mt-2">
          <span>
            {" "}
            <img
              className="w-[30px] h-[30px] mx-auto pr-2 "
              src={ICONS[bookingItem[0].gametype]}
              alt="icon"
            />
          </span>
          <span>&#8377;{sumOfProp2}</span>
        </div>
        {statusopen && (
          <Modal
            open={statusopen}
            onClose={() => {
              setCancelEventind([]);
              setselectedCancelEventData([]);
              setstatusopen(false);
              setCanBePaid(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              verticalAlign: "middle",
              m: "auto",
              display: "flex",
              border: "none",

              "&:focus": {
                outline: "none",
              },
            }}
          >
            <div className="bg-white rounded-md flex flex-col m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
              <div className=" flex  justify-between">
                <div className="text-[#192335] text-[18px] font-[500]">
                  Select slots to approve or reject{" "}
                </div>
                <p
                  className=" text-[red] cursor-pointer"
                  onClick={() => {
                    setCancelEventind([]);
                    setselectedCancelEventData([]);
                    setstatusopen(false);
                    setCanBePaid(false);
                  }}
                >
                  X
                </p>
              </div>
              <div className=" grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2 max-[320px]:grid-cols-2 justify-center mt-2">
                {selectedEventData?.map((item, index) => {
                  let gttime = getTimeFormatted(item?.start);
                  return (
                    <Button
                      sx={{
                        border: "1px solid green",
                        color: cancelEventind?.includes(index)
                          ? "white"
                          : "green",
                        backgroundColor: cancelEventind?.includes(index)
                          ? "green"
                          : "white",
                        ":hover": { backgroundColor: "green", color: "white" },
                      }}
                      onClick={() => {
                        setCancelEventind((prev) => [...prev, index]);
                        handleCancelbooking(item);
                      }}
                    >
                      <div>
                        <p>{gttime.Time}</p>
                        {item?.amount && (
                          <p className="text-black">&#8377; {item.amount}</p>
                        )}
                      </div>
                    </Button>
                  );
                })}
              </div>
              {canBePaid && (
                <div className="text-red-500">
                  An selected slot has already been accepted
                </div>
              )}
              <div className=" rounded-md p-2 flex justify-center gap-2 text-[#8F96A3]  text-[12px]">
                <CustomButton
                  text={"Approve"}
                  disabled={cancelEventind.length ? false : true}
                  onClick={() => handleUpdateStatus("Accepted")}
                  sx={{
                    backgroundColor: "#097E52",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#097E52" },
                    "&:disabled": {
                      backgroundColor: "#043522 ",
                    },
                  }}
                />
                {/* ) : (
              ""
            )} */}
                <CustomButton
                  text={"Reject"}
                  onClick={() => handleUpdateStatus("Cancelled")}
                  disabled={cancelEventind.length ? false : true}
                  sx={{
                    backgroundColor: "#E50000",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E50000" },
                    "&:disabled": {
                      backgroundColor: "#6f0000 ",
                    },
                  }}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
);
