import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";
import { MuiTelInput } from "mui-tel-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Divider, Typography, CircularProgress } from "@mui/material";
import { login, loginverifyOtp } from "../../firebaseFunction/auth";

import Logo from "../../images/Logo.svg";
import ra from "../../images/right-arrow.svg";
import CustomButton from "../Common/ButtonComponent";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LoginPage() {
  const navigate = useNavigate();
  const [phonenumber, setPhonenumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [response, setResponse] = useState();
  const [seconds, setSeconds] = useState(30);
  const [timeup, setTimeup] = useState(false);
  const [val, setVal] = useState(false);

  const [open, setOpen] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  useEffect(() => {
    let interval = null;
    if (open) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      if (seconds == 0) setTimeup(true);
    } else if (!open && seconds !== 30) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [open, seconds]);

  const checklogin = () => {
    const uid = localStorage?.getItem("uid");

    if (uid?.length) {
      navigate("/players/dashboard");
    }
  };
  useEffect(() => {
    checklogin();
  }, []);

  const handleLogin = async () => {
    const formattedphone = phonenumber.replace(/\s+/g, "");
    if (!isValidPhoneNumber(formattedphone)) {
      setVal(true);
    } else {
      try {
        setDisableBtn(true);
        let data = await login(formattedphone);
        setDisableBtn(false);
        if (data?.data != null) {
          toast.success("OTP sent", { position: "top-right", autoClose: 2000 });
          setResponse(data);
          setOpen(true);
        } else {
          console.error("Login failed:", data?.error);
          toast.error(`Login Failed: ${data?.error}`, {
            position: "top-right",
          });
        }
      } catch (error) {
        console.error("Login failed:", error);
        toast.error(`Login Failed ${error}`, { position: "top-center" });
      }
    }
  };
  const handleotpverify = async () => {
    if (otp.length !== 6) {
      alert("Enter a valid otp");
    }

    setLoader(true);
    const userDetails = await loginverifyOtp(otp, response);

    localStorage.setItem("uid", userDetails?.userData?.user_id);
    localStorage.setItem("isowner", userDetails?.userData?.owner);

    if (userDetails?.userData?.owner == true) {
      navigate("/courtadmin/dashboard");
    } else if (userDetails?.userData?.owner == false) {
      navigate("/players/dashboard");
    }
    setLoader(false);
  };

  console.log(phonenumber, "phonenumber");

  return (
    <div className="flex  min-[525px]:flex-row flex-col items-center justify-center h-screen">
      <div
        className={`min-[525px]:w-1/2 w-full min-[525px]:h-screen bg-[url('../public/images/loginbg.png')] bg-no-repeat bg-cover flex justify-center items-center max-[525px]:basis-1/3`}
      >
        <div className="min-[525px]:w-[75%]  max-[525px]:hidden divide-y divide-white/30 bg-black/45 backdrop-blur rounded-[18px] mt-[82px] p-4 font-normal text-white">
          <h2 className="text-[26px] lg:pb-4 pb-2 ">Log In</h2>
          <p className="lg:text-xl sm:text-sm  lg:pt-4 pt-2 px-2  ">
            Log in right away for our advanced sports software solutions,
            created to address issues in regular sporting events and activities.
          </p>
        </div>
        <div className="min-[525px]:hidden p-8">
          <img
            src="./images/logowhite.png"
            alt="logo"
            className="w-4/5 m-auto"
          />
        </div>
      </div>

      <div className="min-[525px]:w-1/2 w-full mx-auto p-10 max-[525px]:p-0   h-screen bg-[#F8F8F8] max-[525px]:bg-white">
        <div className="w-full h-[100%] px-4 max-[525px]:px-0 flex flex-col gap-8 items-center justify-center">
          <div className="flex flex-row items-center  max-[525px]:hidden">
            <img className="w-[54px] h-[54px] " src={Logo} />
            <div sx={{ textAlign: "left", px: 1 }}>
              <Typography
                sx={{ color: "#018352", fontSize: { xs: "22px", sm: "25px" } }}
              >
                TurfMama
              </Typography>
              <Typography
                sx={{ color: "#1D1D1D", fontSize: { xs: "14px", sm: "16px" } }}
              >
                Fun starts here!
              </Typography>
            </div>
          </div>
          {loader ? (
            <CircularProgress style={{ color: "#018352" }} />
          ) : (
            <div className=" max-[525px]:h-full max-[525px]:rounded-t-2xl max-[525px]:mt-[-50px] w-full bg-white rounded-[14px] p-8 ">
              {!open ? (
                <>
                  <div className="items-start flex flex-col">
                    <h2 className="lg:text-[30px] text-[25px] font-[600]">
                      Welcome Back
                    </h2>
                    <h3 className=" lg:text-[18px] text-[14px] text-[#898989] font-[300]">
                      Login to your Account
                    </h3>
                  </div>

                  <div className=" mt-10">
                    <MuiTelInput
                      fullWidth
                      size="small"
                      sx={{
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                      inputProps={{ maxLength: 15 }}
                      placeholder="Mobile Number"
                      defaultCountry="IN"
                      limitMaxLength={10}
                      value={phonenumber}
                      disableDropdown
                      onChange={(phonenumberi) => {
                        const currentcountrycode = phonenumberi
                          .trim(" ")
                          ?.slice(0, 3);
                        if (currentcountrycode !== "+91") {
                          phonenumberi = `+91 ${phonenumberi}`;
                        }
                        if (phonenumberi?.length <= 15) {
                          setPhonenumber(phonenumberi);
                        } else {
                          setPhonenumber(`+91${phonenumberi?.slice(3, 13)}`);
                        }
                      }}
                      rules={{
                        required: true,
                        validate: (n) => n.length <= 13,
                      }}
                    />
                  </div>
                  {val ? (
                    <div className="text-[13px] mt-0 text-red-600 text-left">
                      *Enter a valid phone number*
                    </div>
                  ) : (
                    ""
                  )}
                  <div id="recaptcha-container" className="mt-2"></div>
                  <CustomButton
                    text={"Get OTP"}
                    icon={ra}
                    iconPosition={"end"}
                    iconWidth={"15px"}
                    disabled={disableBtn}
                    onClick={handleLogin}
                    onTouchStart={handleLogin}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#192335",
                      width: "100%",
                      "&:hover": { bgcolor: "#192335" },
                      marginTop: "70px",
                    }}
                  />
                </>
              ) : (
                <div className=" z-20">
                  <div className="items-start flex flex-col ">
                    <h2 className="lg:text-[30px] text-[25px] font-[600]">
                      6-digit Code
                    </h2>
                    <h3 className=" lg:text-[18px] text-[16px] text-[#898989] font-[300]">
                      Please enter the code we’ve sent to{" "}
                      <span className=" text-black font-[500]">
                        {phonenumber}
                      </span>{" "}
                    </h3>
                  </div>
                  <div className="flex flex-col items-start">
                    <div className="p-4 lg:mt-10 mt-4 w-full">
                      <MuiOtpInput
                        value={otp}
                        onChange={(newvalue) => setOtp(newvalue)}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "auto !important",
                          textAlign: "center",
                          color: "#000",
                        }}
                        length={6}
                      />
                      <div id="recaptcha-container" className="mt-2"></div>
                    </div>
                    {timeup ? (
                      <div className=" lg:text-[18px] text-[16px] text-[#898989] font-[300]">
                        Didn't recieve code ?
                        <span
                          className="font-[500] text-black cursor-pointer pl-2"
                          onClick={async () => {
                            try {
                              let data = await login(phonenumber);
                              setResponse(data);
                            } catch (error) {
                              console.error("Login failed:", error);
                            }
                          }}
                        >
                          Resend code
                        </span>
                      </div>
                    ) : (
                      <div className=" lg:text-[18px] text-[16px] text-[#898989] font-[300]">
                        Resend code in{" "}
                        <span className="font-[500] text-black">{`00:${seconds}`}</span>
                      </div>
                    )}
                  </div>
                  <CustomButton
                    text={"Log In"}
                    icon={ra}
                    iconPosition={"end"}
                    iconWidth={"15px"}
                    disabled={loader}
                    onClick={handleotpverify}
                    onTouchStart={handleotpverify}
                    sx={{
                      color: "#FFFFFF",
                      backgroundColor: "#192335",
                      width: "100%",
                      "&:hover": { bgcolor: "#192335" },
                    }}
                  />
                </div>
              )}
              <div className="w-full flex justify-center lg:my-6 my-2">
                <div className="w-[20%]">
                  <Divider sx={{ color: "#898989", fontSize: "18px" }}>
                    OR
                  </Divider>
                </div>
              </div>
              <div className="text-[#898989] lg:text-[18px] text-[16px] font-[300]">
                Don't have an Account?
                <span className="pl-2 font-[500] text-[#097E52]">
                  <Link to="/signup">Sign Up</Link>
                </span>{" "}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LoginPage;
