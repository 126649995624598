import React from "react";
import { useParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import CourtDetails from "./CourtDetails";
import BookingDetails from "./BookingDetails";
import SlotDetails from "./SlotDetails";
import ReviewPage from "./ReviewPage";
import MyArena from "./MyArena";
import Profile from "../CourtAdmin/Profile";

function Content() {
  const params = useParams();
  const routeName = params;

  const screens = {
    "/": <Dashboard />,
    dashboard: <Dashboard />,
    courtdetails: <CourtDetails />,
    bookingdetails: <BookingDetails />,
    slotdetails: <SlotDetails />,
    review: <ReviewPage />,
    myarena: <MyArena />,
    profile: <Profile />,
  };

  return (
    <div className="overflow-y-auto text-[#000], font-Outfit">
      {screens[routeName?.id] ||
        screens[routeName?.page] ||
        screens[routeName?.dashboard] ||
        screens["dashboard"]}
    </div>
  );
}
export default Content;
