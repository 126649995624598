import React, { useEffect, useState } from "react";
import CustomButton from "../Common/ButtonComponent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { CustomTable } from "../Common/TableComponent";
import {
  Box,
  CircularProgress,
  Typography,
  Stack,
  Chip,
  Button,
} from "@mui/material";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import { useNavigate } from "react-router-dom";
import { getTimeFormatted } from "../Common/GetHours";

import {
  changeEventStatus,
  getEventdetailsByType,
} from "../../firebaseFunction/eventDetails";
import { Modal } from "@mui/material";
import EditIcon from "../../images/editIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import _ from "lodash";
function MyBooking() {
  const headers = [
    {
      key: "ground",
      label: "Court Name",
      align: "left",
      fontSize: "15px",
      width: "100px",
      valueRenderer: (data) => {
        const datum = data[0];
        return (
          <div className="flex flex-row gap-2 w-[170px]">
            <img
              src={datum["images"]}
              alt="img"
              className="h-[45px] w-[45px]"
            />
            <div className="pt-1">
              <h1 className="text-[14px] font-[500] text-nowrap">
                {_.startCase(datum["ground_name"])}
              </h1>
              <h1 className="text-[12px] text-[#097E52]">
                {_.startCase(datum.court_name)}
              </h1>
            </div>
          </div>
        );
      },
    },
    {
      key: "start",
      label: "Date & Time",
      align: "left",
      fontSize: "15px",
      valueRenderer: (data) => {
        return data.map((datum, index) => {
          const startdateTime = new Date(datum["start"]);
          const enddateTime = new Date(datum["end"]);

          const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const dayOfWeek = daysOfWeek[startdateTime.getDay()];
          const month = months[startdateTime.getMonth()];
          const day = startdateTime.getDate();

          let hours = startdateTime.getHours();
          const minutes = startdateTime.getMinutes();

          let ampm = "AM";
          if (hours >= 12) {
            ampm = "PM";
            hours %= 12;
          }
          if (hours === 0) {
            hours = 12;
          }

          return (
            <div className="flex flex-col gap-1">
              {index == 0 ? (
                <h1 className="text-[12px] font-[400] ">{`${dayOfWeek}, ${month} ${day
                  .toString()
                  .padStart(2, "0")}`}</h1>
              ) : (
                ""
              )}
              <Chip
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  fontFamily: "Outfit",
                  padding: "2px",
                  height: "0",
                  marginTop: "4px",
                }}
                variant="outlined"
                label={`${hours}:${minutes
                  .toString()
                  .padStart(2, "0")} ${ampm} `}
              />
            </div>
          );
        });
      },
    },

    {
      key: "payment",
      label: "Payment",
      align: "center",
      valueRenderer: (data) => {
        const flattenedArray = data.flat();
        const sumOfProp2 = flattenedArray.reduce(
          (sum, obj) => sum + parseInt(obj.amount),
          0
        );

        return (
          <div>
            <h1 className="text-[14px] font-[600] "> &#8377;{sumOfProp2}</h1>
          </div>
        );
      },
    },

    // {
    //   key: "review",
    //   label: "Review",
    //   align: "left",
    //   fontSize: "15px",
    //   fontWeight: "500",
    //   fontFamily: "Outfit",
    //   valueRenderer: (data) => {
    //     const flattenedArray = data.flat();
    //     const sumOfProp2 = flattenedArray.reduce(
    //       (sum, obj) => sum + parseFloat(obj.review),
    //       0
    //     );
    //     const avg = sumOfProp2 / data.length;
    //     return (
    //       <div>
    //         <Rating
    //           name="read-only"
    //           value={avg}
    //           readOnly
    //           precision={0.5}
    //           emptyIcon={
    //             <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
    //           }
    //         />
    //       </div>
    //     );
    //   },
    // },
    {
      key: "status",
      label: "Status",
      align: "left",
      valueRenderer: (data) => {
        const hnn = {
          Awaiting: {
            icon: <AccessTimeOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Awaiting",
            color: "#7756C9",
            bg: "#E4DDF4",
          },
          Accepted: {
            icon: <AccessTimeOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Accepted",
            color: "#097E52",
            bg: "#D1F0D6",
          },
          Ongoing: {
            icon: <AccessTimeOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Ongoing",
            color: "#097E52",
            bg: "#D1F0D6",
          },
          Completed: {
            icon: <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Completed",
            color: "#097E52",
            bg: "#D1F0D6",
          },
          Cancelled: {
            icon: <HighlightOffOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Cancelled",
            color: "#F50303",
            bg: "#FFF0F0",
          },
          Canceled: {
            icon: <HighlightOffOutlinedIcon sx={{ fontSize: "14px" }} />,
            text: "Cancelled",
            color: "#F50303",
            bg: "#FFF0F0",
          },
        };

        return (
          <div className="flex flex-col gap-1">
            {data.length > 1 && <p className="text-white leading-3">---</p>}
            {data.map((datumm) => {
              return (
                <div
                  className={`text-[10px] font-[400] text-[${
                    hnn[datumm.status].color
                  }] justify-center rounded-sm flex flex-row gap-1 w-full bg-[${
                    hnn[datumm.status].bg
                  }] p-2`}
                >
                  {hnn[datumm.status].icon}
                  <span className="text-nowrap text-[11px] leading-3.5">
                    {hnn[datumm.status].text}
                  </span>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const [statusopen, setstatusopen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState();
  const [cancelEventind, setCancelEventind] = useState([]);
  const [selectedCancelEventData, setselectedCancelEventData] = useState([]);
  const [tab, setTab] = useState("Upcoming");
  const [finalData, setfinalData] = useState([]);
  const prop1Map = new Map();

  const [filter, setFilter] = useState("This Month");
  const [data, setdata] = useState([]);
  const [noData, setNoData] = useState(false);
  const [detaildata, setdetaildata] = useState([]);

  const [loading, setLoading] = useState(false);
  const [nonfilter, setNonFilter] = useState([]);
  const navigate = useNavigate();
  let uid = localStorage.getItem("uid");

  const handlefilter = async (value) => {
    setdata([]);
    setNoData(false);
    setFilter(value);
  };

  const handledetail = () => {
    handleChange("Upcoming");
  };
  function compareByDate(obj1, obj2) {
    return new Date(obj1.start) - new Date(obj2.start);
  }
  const handleChange = async (value) => {
    if (tab !== value) setdata([]);
    setNoData(false);
    setTab(value);
  };

  const eventData = async () => {
    if (uid == null) {
      navigate("/login");
    }
    setLoading(true);
    let startDate = moment().format("YYYY-MM-DDTHH:mm");
    let endOfMonth = moment().endOf("month").format("YYYY-MM-DDTHH:mm");

    let statusValue = ["Accepted", "Awaiting"];

    if (tab === "Cancelled") {
      statusValue = ["Cancelled", "Canceled"];
      startDate = moment().startOf("month").format("YYYY-MM-DDTHH:mm");
    } else if (tab === "Completed") {
      statusValue = [tab];
      startDate = moment().startOf("month").format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment().format("YYYY-MM-DDTHH:mm");
    } else if (tab !== "Upcoming" && tab !== "Cancelled") {
      statusValue = [tab];
    }

    if (filter === "Last Month") {
      startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DDTHH:mm");
    } else if (filter === "Next Month") {
      startDate = moment()
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DDTHH:mm");
      endOfMonth = moment()
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DDTHH:mm");
    }

    const otherFilters = [
      { key: "status", operator: "in", value: statusValue },
      { key: "start", operator: ">=", value: startDate },
      { key: "end", operator: "<=", value: endOfMonth },
    ];
    if (
      otherFilters &&
      otherFilters.length > 0 &&
      data.length === 0 &&
      !noData
    ) {
      const response = await getEventdetailsByType(
        uid,
        "user",
        { key: "start", dir: "asc" },
        null,
        otherFilters
      );
      const events = response?.data;
      setdata(events);
      setNoData(events.length === 0);

      setfinalData(findElementsWithSameProp(response?.data));
    }
    setLoading(false);
  };

  let isowner = localStorage.getItem("isowner");

  const handlenavigate = () => {
    if (isowner == "true") {
      navigate("/courtadmin/dashboard");
    } else {
      navigate("/players/dashboard");
    }
  };

  useEffect(() => {
    eventData();
  }, [tab, filter, data]);

  useEffect(() => {
    if (uid != null) {
      if (isowner == "true") {
        navigate("/courtadmin/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  const statusrendet = (itemdata) => {
    switch (itemdata.status) {
      case "Awaiting":
        return (
          <div className="text-[10px] font-[400] text-[#7756C9] justify-center rounded-sm flex flex-row gap-1 w-full bg-[#E4DDF4] p-1">
            <AccessTimeOutlinedIcon sx={{ fontSize: "12px" }} />
            <span className="text-nowrap text-[11px] leading-3">
              {itemdata["status"]}
            </span>
          </div>
        );
      case "Ongoing":
        return (
          <div className="text-[11px] font-[400] text-[#45AEF4] justify-center rounded-sm  flex flex-row gap-1 w-full bg-[#E0F6FF] p-1">
            <AccessTimeOutlinedIcon sx={{ fontSize: "12px" }} />
            <span className="text-nowrap leading-3">{itemdata["status"]}</span>
          </div>
        );
      case "Completed":
        return (
          <div className="text-[11px] font-[400] text-[#097E52] justify-center rounded-sm  flex flex-row gap-1 w-full bg-[#D1F0D6] p-1">
            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
            <span className="text-nowrap  leading-3">{itemdata["status"]}</span>
          </div>
        );
      case "Accepted":
        return (
          <div className="text-[11px] font-[400] text-[#097E52] justify-center rounded-sm  flex flex-row gap-1 w-full bg-[#D1F0D6] p-1">
            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
            <span className="text-nowrap leading-3">{itemdata["status"]}</span>
          </div>
        );
      case "Cancelled":
        return (
          <div className="text-[11px] font-[400] text-[#F50303] justify-center rounded-sm  flex flex-row gap-1 w-full bg-[#FFF0F0] p-1">
            <HighlightOffOutlinedIcon sx={{ fontSize: "12px" }} />
            <span className="text-nowrap leading-3">{itemdata["status"]}</span>
          </div>
        );
      default:
        return (
          <div>
            <h1 className="text-[14px] text-yellow-500">
              {itemdata["status"]}
            </h1>
          </div>
        );
    }
  };

  const handlestatusEdit = (data) => {
    if (tab == "Upcoming") {
      setSelectedEventData(data);
      setstatusopen(true);
    }
  };

  const handleupdatestatus = async (props) => {
    if (cancelEventind.length != 0 && selectedCancelEventData.length != 0) {
      const mapcosnt = selectedCancelEventData.map(
        (selectedCancelEventDatum) => {
          cancelEvent(selectedCancelEventDatum, props);
        }
      );

      // mapcosnt
      setstatusopen(false);
      setCancelEventind([]);
      setselectedCancelEventData([]);

      eventData();
      setTimeout(() => {
        handleChange("Cancelled");
      }, 1000);
    }
  };

  const cancelEvent = async (selectedEventDatum, props) => {
    await changeEventStatus(selectedEventDatum?.event_id, props);
  };

  const handleCancelbooking = (value, index) => {
    setselectedCancelEventData((prev) => [...prev, value]);
  };

  function findElementsWithSameProp(arr) {
    const prop1Map = new Map();

    arr.forEach((element) => {
      const prop1Value = element.BookId;
      if (prop1Map.has(prop1Value)) {
        prop1Map.get(prop1Value).push(element);
      } else {
        prop1Map.set(prop1Value, [element]);
      }
    });

    const result = Array.from(prop1Map.values());

    return result;
  }

  const modalPrice = (data) => {
    const flattenedArray = data.flat();
    const sumOfProp2 = flattenedArray?.reduce(
      (sum, obj) => sum + parseInt(obj.amount),
      0
    );
    return <p>&#8377;{sumOfProp2}</p>;
  };

  const modaldatetime = () => {
    data.sort(compareByDate);
    return data.map((datum) => {
      const startdateTime = new Date(datum["start"]);
      const enddateTime = new Date(datum["end"]);

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dayOfWeek = daysOfWeek[startdateTime.getDay()];
      const month = months[startdateTime.getMonth()];
      const day = startdateTime.getDate();

      let hours = startdateTime.getHours();
      const minutes = startdateTime.getMinutes();

      let ampm = "AM";
      if (hours >= 12) {
        ampm = "PM";
        hours %= 12;
      }
      if (hours === 0) {
        hours = 12;
      }

      return (
        <div className="flex flex-col gap-1">
          <h1 className="text-[12px] font-[400] ">{`${dayOfWeek}, ${month} ${day
            .toString()
            .padStart(2, "0")}`}</h1>
          <Chip
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "Outfit",
              padding: "2px",
              height: "0",
            }}
            variant="outlined"
            label={`${hours}:${minutes.toString().padStart(2, "0")} ${ampm} `}
          />
        </div>
      );
    });
  };
  return (
    <div className=" bg-[#F9F9F9] relative font-Outfit">
      <div
        className={` bg-[url('../public/images/mybooking.png')] bg-no-repeat bg-blend-darken bg-cover w-full h-[179px] `}
      >
        <div className="bg-black/75 w-full h-full flex flex-col items-start justify-center  gap-2 min-[525px]:pl-16 max-[525px]:pl-12">
          <div>
            <h1 className="text-[30px] font-[500] text-white">My Booking</h1>
          </div>
          <div className="text-white text-[16px] flex flex-row gap-2">
            <p onClick={handlenavigate} className="cursor-pointer">
              Home
            </p>
            <p>{">"}</p> <p>My Bookings</p>
            <p>{">"}</p> <p>{tab}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-4 gap-4 ">
        <div className="bg-white flex min-[769px]:flex-row max-[768px]:pb-3 max-[768px]:flex-col justify-between px-4 max-[525px]:pb-2 items-center w-[90%] rounded-lg">
          <div className="flex flex-row gap-2 overflow-x-auto  max-[768px]:w-full ">
            <CustomButton
              text={"Upcoming"}
              onClick={() => handleChange("Upcoming")}
              sx={
                tab == "Upcoming"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { md: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
            <CustomButton
              text={"Completed"}
              onClick={() => handleChange("Completed")}
              sx={
                tab == "Completed"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
            <CustomButton
              text={"On going"}
              onClick={() => handleChange("Ongoing")}
              sx={
                tab == "Ongoing"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
            <CustomButton
              text={"Cancelled"}
              onClick={() => handleChange("Cancelled")}
              sx={
                tab == "Cancelled"
                  ? {
                      backgroundColor: "#192335",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#192335" },
                      width: "100%",
                      px: 6,
                    }
                  : {
                      backgroundColor: "#F9F9F9",
                      color: "#898989",
                      borderRadius: "8px",
                      fontSize: { sm: "14px", xs: "10px" },
                      "&:hover": { bgcolor: "#F9F9F9" },
                      width: "100%",
                      px: 6,
                    }
              }
            />
          </div>
          <FormControl
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
              backgroundColor: "#F9F9F9",
              borderRadius: "4px",
              color: "#757C8D",
              fontSize: "14px",
              fontFamily: "Outfit",
              width: "200px",
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="This Month"
              onChange={(e) => handlefilter(e.target.value)}
            >
              <MenuItem value={"Last Month"}>Last Month</MenuItem>
              <MenuItem value={"This Month"}>This Month</MenuItem>
              <MenuItem value={"Next Month"}>Next Month</MenuItem>
            </Select>
          </FormControl>
          {/* <CustomSelect
            options={[
              { id: "This Month", value: "Next Month", label: "Next Month" },
              { id: "This Month", value: "This Month", label: "This Month" },
              { id: "Last Month", value: "Last Month", label: "Last Month" },
            ]}
            value={filter}
            onChange={(v) => handlefilter(v)}
            icon={iconimg}
            style={{ padding: "0", height: "50px" }}
            sx={{
              border: "none",
              "& fieldset": { border: "none" },
              backgroundColor: "#F9F9F9",
              borderRadius: "4px",
              color: "#757C8D",
              fontSize: "14px",
              fontFamily: "Outfit",
              width: "200px",
            }}
          /> */}
        </div>
      </div>
      <Box sx={{ mt: 2, display: { xs: "none", sm: "block" } }}>
        {!loading ? (
          <CustomTable
            showEdit={true}
            itemsPerPage={10}
            Title={"My Bookings"}
            SubTitle={"Track and manage your completed court bookings"}
            headers={headers}
            data={data}
            oneditClick={handlestatusEdit}
          />
        ) : (
          <div className="h-[60vh] justify-center items-center flex">
            <CircularProgress style={{ color: "#018352" }} />
          </div>
        )}
      </Box>
      <div className="min-[525px]:hidden w-full flex flex-col items-center mt-2">
        {!data.length && (
          <div className="w-[95%] bg-white rounded-lg p-3 flex flex-col my-2">
            No data
          </div>
        )}
        {finalData?.map((Oitem, index) => {
          const flattenedArray = Oitem.flat();
          const sumOfProp2 = flattenedArray.reduce(
            (sum, obj) => sum + parseInt(obj.amount),
            0
          );
          return (
            <div
              className="w-[95%] bg-white rounded-lg p-3 flex flex-col mt-2"
              key={index}
            >
              <div className="flex flex-row justify-end w-full">
                <img
                  onClick={() => handlestatusEdit(Oitem)}
                  src={EditIcon}
                  alt="Edit"
                  width={20}
                  height={20}
                />
              </div>
              <div className="flex flex-row justify-between w-full pt-4">
                <div>
                  {_.startCase(Oitem[0].ground_name)} |{" "}
                  <span className="text-[#097E52]">{Oitem[0].court_name}</span>
                </div>
                <div>&#8377;{sumOfProp2}</div>
              </div>
              {/* <div className="flex flex-row justify-between w-full pt-1">
                <div className=" flex flex-row gap-1">
                  <div className="text-center">{Oitem[0].review}</div>{" "}
                  <Rating
                    name="read-only"
                    value={Oitem[0].review}
                    readOnly
                    precision={0.5}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </div>
              </div> */}
              <div className="flex flex-row justify-between w-full">
                <div>
                  {Oitem.map((item) => {
                    const startdateTime = new Date(item.start);
                    const enddateTime = new Date(item.end);

                    const daysOfWeek = [
                      "Sun",
                      "Mon",
                      "Tue",
                      "Wed",
                      "Thu",
                      "Fri",
                      "Sat",
                    ];
                    const months = [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ];

                    const dayOfWeek = daysOfWeek[startdateTime.getDay()];
                    const month = months[startdateTime.getMonth()];
                    const day = startdateTime.getDate();
                    const year = startdateTime.getFullYear();

                    let hours = startdateTime.getHours();
                    const minutes = startdateTime.getMinutes();
                    const seconds = startdateTime.getSeconds();
                    let ampm = "AM";
                    if (hours >= 12) {
                      ampm = "PM";
                      hours %= 12;
                    }
                    if (hours === 0) {
                      hours = 12;
                    }
                    let hours2 = enddateTime.getHours();
                    const minutes2 = enddateTime.getMinutes();
                    const seconds2 = enddateTime.getSeconds();
                    let ampm2 = "AM";
                    if (hours2 >= 12) {
                      ampm2 = "PM";
                      hours2 %= 12;
                    }
                    if (hours2 === 0) {
                      hours2 = 12;
                    }
                    return (
                      <div className="flex flex-row justify-between w-full pt-4 text-[12px] font-[400]">
                        {`${dayOfWeek}, ${month} ${day
                          .toString()
                          .padStart(2, "0")} | ${hours}:${minutes
                          .toString()
                          .padStart(2, "0")} ${ampm} - ${hours2}:${minutes2
                          .toString()
                          .padStart(2, "0")} ${ampm2}`}
                      </div>
                    );
                  })}
                </div>
                <div className="pt-4 flex flex-col gap-2">
                  {Oitem.map((item) => {
                    return statusrendet(item);
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {open && (
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          sx={{
            verticalAlign: "middle",
            m: "auto",
            display: "flex",
            border: "none",
            outline: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6">
            <div className="flex flex-row p-8 w-full justify-between">
              <div className="">
                <div className="text-[#707070] ">Name</div>
                <div className="font-[600] text-xl ">
                  {detaildata[0]?.user_name}
                </div>
              </div>
              <CloseIcon
                className="text-red-600 cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className="w-full text-white bg-[#0A1A38] py-2 px-8">
              Booking
            </div>

            <Stack
              direction={{ sm: "row", xs: "column" }}
              gap={{ sm: 4, xs: 2 }}
              sx={{ textAlign: "left", padding: "32px" }}
            >
              <Box sx={{ width: "200px" }}>
                <Typography
                  sx={{
                    color: "#313A4A",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Court
                </Typography>

                <div className="flex flex-col gap-2">
                  {data?.map((dat) => (
                    <h1 className="text-[12px] text-[#707070]">
                      {dat.court_name}
                    </h1>
                  ))}
                </div>
              </Box>
              <Box sx={{ width: "200px" }}>
                <Typography
                  sx={{
                    color: "#313A4A",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Date & Timing
                </Typography>
                <Typography
                  sx={{ color: "#707070", fontSize: "14px", mt: "6px" }}
                >
                  {modaldatetime()}
                </Typography>
              </Box>
              <Box sx={{ width: "250px" }}>
                <Typography
                  sx={{
                    color: "#313A4A",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Court Booking Amount
                </Typography>
                <Typography sx={{ color: "#707070", fontSize: "14px" }}>
                  {modalPrice(detaildata)}
                </Typography>
              </Box>
            </Stack>
          </div>
        </Modal>
      )}

      {statusopen && (
        <Modal
          open={statusopen}
          onClose={() => {
            setstatusopen(false);
            setCancelEventind([]);
            setselectedCancelEventData([]);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            verticalAlign: "middle",
            m: "auto",
            display: "flex",
            border: "none",
            outline: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="flex flex-col bg-white rounded-md m-auto  xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
            <div className="flex flex-col ">
              <div className=" flex  justify-between">
                <div className="text-[#192335] text-[18px] font-[500]">
                  Select the event to cancel.
                </div>
                <p
                  className=" text-[red] cursor-pointer"
                  onClick={() => {
                    setCancelEventind([]);
                    setstatusopen(false);
                    setselectedCancelEventData([]);
                  }}
                >
                  X
                </p>
              </div>
              <div className=" grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2 max-[320px]:grid-cols-2 justify-center">
                {selectedEventData?.map((item, index) => {
                  let gttime = getTimeFormatted(item?.start);
                  return (
                    <Button
                      sx={{
                        border: "1px solid red",
                        color: cancelEventind?.includes(index)
                          ? "white"
                          : "red",
                        backgroundColor: cancelEventind?.includes(index)
                          ? "red"
                          : "white",
                        ":hover": { backgroundColor: "red", color: "white" },
                      }}
                      // disabled={item.isbooked}
                      onClick={() => {
                        setCancelEventind((prev) => [...prev, index]);
                        handleCancelbooking(item);
                      }}
                    >
                      <div>
                        <p>{gttime.Time}</p>
                        {item?.amount && (
                          <p className="text-black">&#8377; {item.amount}</p>
                        )}
                      </div>
                    </Button>
                  );
                })}
              </div>
              <div className=" rounded-md p-2 flex justify-center gap-2 text-[#8F96A3]  text-[12px]">
                <CustomButton
                  text={"Cancel"}
                  onClick={() => handleupdatestatus("Cancelled")}
                  disabled={
                    cancelEventind.length == 0 &&
                    selectedCancelEventData.length == 0
                  }
                  sx={{
                    backgroundColor: "#E50000",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E50000" },
                  }}
                />
              </div>
            </div>{" "}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default MyBooking;
