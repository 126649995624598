import StaticMap, { Marker } from "react-map-gl";
import maplibregl from "maplibre-gl";

import logo from "../../images/logo.png";

import "maplibre-gl/dist/maplibre-gl.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function Maps() {
  const [selctedLocation, setSelectedLocation] = useState();

  let { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  console.log(search, "params", searchParams.get("lat"));
  const lat = searchParams.get("lat");
  const long = searchParams.get("long");

  const width = searchParams.get("width");
  const height = searchParams.get("height");
  const getLatLong = (e) => {
    setSelectedLocation(e.lngLat);
  };
  if (!lat || !long) {
    return <></>;
  }
  return (
    <div>
      <StaticMap
        onClick={getLatLong}
        mapLib={maplibregl}
        initialViewState={{
          longitude: long,
          latitude: lat,
          zoom: 15,
        }}
        style={{
          width: width ? `${width}px` : "100%",
          height: height ? `${height}px` : 400,
        }}
        mapStyle="https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json"
        transformRequest={(url, resourceType) => {
          if (url.includes("?")) {
            url = url + `&api_key=${process.env.REACT_APP_OLA_MAPS_API_KEY}`;
          } else {
            url = url + `?api_key=${process.env.REACT_APP_OLA_MAPS_API_KEY}`;
          }
          return { url, resourceType };
        }}
      >
        <Marker longitude={long} latitude={lat} anchor="bottom">
          <img src={logo} alt="location" width={48} height={48} />
        </Marker>
        {selctedLocation && (
          <Marker
            longitude={selctedLocation.lng}
            latitude={selctedLocation.lat}
            anchor="bottom"
          >
            <img src={logo} alt="location" width={48} height={48} />
          </Marker>
        )}
      </StaticMap>
    </div>
  );
}

export default Maps;
