import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import Logo from "../images/logo.jpg";
import AccountMenu from "./AccountPopOver";
import { useNavigate } from "react-router-dom";
import { updateEventStatus } from "../firebaseFunction/eventDetails";
function Header() {
  const navigate = useNavigate();
  let isowner = localStorage.getItem("isowner");

  const handlenavigate = () => {
    if (isowner == "true") {
      navigate("/courtadmin/dashboard");
    } else {
      navigate("/players/dashboard");
    }
  };

  const data = async () => {
    const uid = localStorage.getItem("uid");
    const isowner = localStorage.getItem("isowner");
    const currentDate = new Date();
    const currentminutes = currentDate.getMinutes();
    const currentseconds = currentDate.getSeconds();
    const millisecondsUntilNextHour =
      (60 - currentminutes) * 60 * 1000 - currentseconds * 1000;

    if (uid != null || uid == undefined) {
      await updateEventStatus(uid, isowner == "true" ? "owners" : "user");
      if (currentminutes != 59 && currentseconds != 59) {
        setTimeout(async () => {
          await updateEventStatus(uid, isowner == "true" ? "owners" : "user");
          setInterval(async () => {
            if (uid != null) {
              await updateEventStatus(
                uid,
                isowner == "true" ? "owners" : "user"
              );
            }
          }, 60 * 60 * 1000);
        }, millisecondsUntilNextHour);
      }
    }
  };

  useEffect(() => {
    data();
  }, []);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ alignItems: "center", px: { xs: 2, sm: 6 } }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handlenavigate}
        >
          <img
            className="md:w-[80px] lg:h-[80px] w-[60px] h-[60px] cursor-pointer"
            src={Logo}
            onClick={handlenavigate}
            alt="logo"
          />
          <Box sx={{ textAlign: "left", pr: 1 }}>
            <Typography
              sx={{ color: "#018352", fontSize: { xs: "20px", sm: "25px" } }}
            >
              TurfMama
            </Typography>
            <Typography
              sx={{ color: "#1D1D1D", fontSize: { xs: "14px", sm: "16px" } }}
            >
              Fun starts here!
            </Typography>
          </Box>
        </Box>
        <AccountMenu />
      </Stack>
    </Box>
  );
}

export default Header;
