import React, { useState } from "react";
import { Modal, Button } from "@mui/material";
import CustomButton from "../Common/ButtonComponent";
import { changeEventStatus } from "../../firebaseFunction/eventDetails";
import { getTimeFormatted } from "../Common/GetHours";
import { ToastContainer, toast } from "react-toastify";
function checkSamePropertyValue(array) {
  return array.reduce((acc, obj) => acc && obj.status !== "Accepted", true);
}

const SlotApprovalModal = ({
  statusopen,
  setstatusopen,
  selectedEventData,
  setSelectedEventData,
  eventData,
  groundIds,
  showToast
}) => {
  const [cancelEventind, setCancelEventind] = useState([]);
  const [canBePaid, setCanBePaid] = useState(false);
  const [selectedCancelEventData, setselectedCancelEventData] = useState([]);

  const handleCancelbooking = (item) => {
    setselectedCancelEventData((prev) => [...prev, item]);
    if (item.status === "Accepted") {
      setCanBePaid(true);
    }
  };

  const handleUpdateStatus = async (props) => {
    if (checkSamePropertyValue(selectedCancelEventData)) {
      const mapcosnt = await selectedCancelEventData.map(
        async (selectedCancelEventDatum) => {
          await updateBooking(selectedCancelEventDatum, props);
        }
      );
     
      setstatusopen(false);
      setSelectedEventData(null);
      setCancelEventind([]);
      showToast(props)
      setselectedCancelEventData([]);
      await eventData(groundIds);
    } else {
      setCanBePaid(true);
    }
  };

  const updateBooking = async (selectedEventDatum, props) => {
    await changeEventStatus(selectedEventDatum?.event_id, props);
    Promise.resolve();
  };

  return (
    <>
      {statusopen && (
        <Modal
          open={statusopen}
          onClose={() => {
            setCancelEventind([]);
            setselectedCancelEventData([]);
            setstatusopen(false);
            setCanBePaid(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            verticalAlign: "middle",
            m: "auto",
            display: "flex",
            border: "none",
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <div className="bg-white rounded-md flex flex-col m-auto xl:w-3/6 md:w-4/6 max-[425px]:w-5/6 p-4">
            <div className="flex justify-between">
              <div className="text-[#192335] text-[18px] font-[500]">
                Select slots to approve or reject
              </div>
              <p
                className="text-[red] cursor-pointer"
                onClick={() => {
                  setCancelEventind([]);
                  setselectedCancelEventData([]);
                  setstatusopen(false);
                  setCanBePaid(false);
                }}
              >
                X
              </p>
            </div>
            <div className="grid xl:grid-cols-6 gap-2 min-[525px]:grid-cols-4 max-[525px]:grid-cols-2 max-[320px]:grid-cols-2 justify-center mt-2">
              {selectedEventData?.map((item, index) => {
                let gttime = getTimeFormatted(item?.start);
                return (
                  <Button
                    key={index}
                    sx={{
                      border: "1px solid green",
                      color: cancelEventind?.includes(index)
                        ? "white"
                        : "green",
                      backgroundColor: cancelEventind?.includes(index)
                        ? "green"
                        : "white",
                      ":hover": {
                        backgroundColor: "green",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      setCancelEventind((prev) =>
                        prev.includes(index)
                          ? prev.filter((i) => i !== index)
                          : [...prev, index]
                      );
                      handleCancelbooking(item);
                    }}
                  >
                    <div>
                      <p>{gttime.Time}</p>
                      {item?.amount && (
                        <p className="text-black">&#8377; {item.amount}</p>
                      )}
                    </div>
                  </Button>
                );
              })}
            </div>
            {canBePaid && (
              <div className="text-red-500">
                A selected slot has already been accepted
              </div>
            )}
            <div className="rounded-md p-2 flex justify-center gap-2 text-[#8F96A3] text-[12px]">
              <CustomButton
                text={"Approve"}
                disabled={cancelEventind.length ? false : true}
                onClick={() => handleUpdateStatus("Accepted")}
                sx={{
                  backgroundColor: "#097E52",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#097E52" },
                  "&:disabled": {
                    backgroundColor: "#043522 ",
                  },
                }}
              />
              <CustomButton
                text={"Reject"}
                onClick={() => handleUpdateStatus("Cancelled")}
                disabled={cancelEventind.length ? false : true}
                sx={{
                  backgroundColor: "#E50000",
                  color: "#FFFFFF",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#E50000" },
                  "&:disabled": {
                    backgroundColor: "#6f0000 ",
                  },
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SlotApprovalModal;
