export const findElementsWithSameProp = (arr) => {
  const prop1Map = new Map();

  arr.forEach((element) => {
    const prop1Value = element.BookId;
    if (prop1Map.has(prop1Value)) {
      prop1Map.get(prop1Value).push(element);
    } else {
      prop1Map.set(prop1Value, [element]);
    }
  });

  const result = Array.from(prop1Map.values());

  return result;
};
