import React, { useEffect, useState } from "react";
import CustomButton from "../Common/ButtonComponent";

import CustomSelect from "../Common/SelectComponent";
import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Divider,
  Box,
  Stack,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import CustomTextField from "../Common/TextFiledComponet";

import deleteIcons from "../../images/Group 1000004815.svg";
import badmintonIcon from "../../images/badmintonIcon.svg";
import CricketIcon from "../../images/cricketIcon.svg";
import FootballIcon from "../../images/footballIcon.svg";
import VolleyballIcon from "../../images/volleyballIcon.svg";
import HockeyIcon from "../../images/hockeyIcon.svg";
import BasketballIcon from "../../images/BasketballIcon.svg";
import BaseballIcon from "../../images/Baseball.svg";
import SoftballIcon from "../../images/TennisIcon.svg";
import ArcheryIcon from "../../images/ArcheryIcon.svg";

import tabletennisIcon from "../../images/tabletennisIcon.svg";
import uploadicon from "../../images/image_26.svg";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal } from "@mui/material";

import tick from "../../images/Ellipse 2835.svg";

import {
  UpdateGroundData,
  createGroundData,
  createCity,
  deleteFile,
  getgroundDataById,
  uploadFile,
} from "../../firebaseFunction/groundDetails";
import { userData } from "../../firebaseFunction/userDetails";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { Maps } from "../Players/Maps";
import { ICONS } from "../../utils/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CourtDetails({ data, setArenaDetails }) {
  const [tab, setTab] = useState("Basic Details");
  const [groundData, setGroundData] = useState();
  const [details, setDetails] = useState({});

  const [loader, setLoading] = useState(false);
  const [tempstate, settempstate] = useState();
  let uid = localStorage.getItem("uid");
  const navigate = useNavigate();
  const [game_type, setgame_type] = useState([
    "Cricket",
    "Badminton",
    "Table Tennis",
    "Football",
    "Volleyball",
    "Hockey",
    "Basketball",
    "Archery",
    "Softball",
    "Baseball",
  ]);
  const [valData, setvalData] = useState(true);

  const tabs = [
    "Basic Details",
    "Cover Image",
    "Sports Available",
    "Include",
    "Rules",
    "Amenities",
    "Gallery",
    "Location",
  ];
  const includes = [
    "Badminton Racket Unlimited",
    "Bats",
    "Hitting Machines",
    "Multiple Courts",
    "Spare Players",
    "Instant Racket",
    "Green Turfs",
  ];
  const amenities = [
    "Parking",
    "Drinking Water",
    "First Aid",
    "Change Room",
    "Shower",
  ];

  const createtempgroundData = {
    groundname: "",
    phonenumber: "",
    email: "",
    groundtype: "",
    coverImage: [],
    game_type: [],
    includes: [],
    rules: [],
    amenities: [],
    gallery: [],
    city: "",
    state: "",
    street_address: "",
    pincode: "",
    latitude: "",
    longitude: "",
    start_time: "",
    end_time: "",
    active: "",
  };
  const handleChange = (value) => {
    setTab(value);
    var element = document.getElementById(value);
    element.scrollIntoView();
  };

  const iconsss = ICONS;

  const allowedTypes = ["jpeg", "jpg", "png", "webp"];
  const handleGameclick = (value) => {
    let availablegame = groundData?.game_type;
    if (availablegame?.includes(value)) {
      let subarr = availablegame.filter((item) => item != value);
      setGroundData({ ...groundData, game_type: subarr });
    } else {
      setGroundData((prevData) => ({
        ...prevData,
        game_type: [...(prevData.game_type || []), value],
      }));
    }
  };
  const handleincludeclick = (value) => {
    let availableinclude = groundData?.includes;
    if (availableinclude?.includes(value)) {
      let subarr = availableinclude.filter((item) => item != value);
      setGroundData({ ...groundData, includes: subarr });
    } else {
      setGroundData((prevData) => ({
        ...prevData,
        includes: [...(prevData.includes || []), value],
      }));
    }
  };
  const handleamenitiesclick = (value) => {
    let availableamenities = groundData?.amenities;
    if (availableamenities?.includes(value)) {
      let subarr = availableamenities.filter((item) => item != value);
      setGroundData({ ...groundData, amenities: subarr });
    } else {
      setGroundData((prevData) => ({
        ...prevData,
        amenities: [...(prevData.amenities || []), value],
      }));
    }
  };

  const handleClick = async (e) => {
    const name = "Cover_IMG_" + new Date().getTime();
    let files = e.target.files[0];
    const extension = files.name.split(".").pop().toLowerCase();

    let Cover_IMG_;
    if (files) {
      if (!allowedTypes.includes(extension)) {
        alert("Only JPEG,JPG, PNG and WEBP files are allowed.");
        files = "";
        return;
      } else {
        Cover_IMG_ = await uploadFile(uid, name, files, "coverImage");
        let coverImage = groundData?.coverImage;
        Array.isArray(coverImage)
          ? coverImage.push(Cover_IMG_)
          : (coverImage = [Cover_IMG_]);
        Promise.resolve();
        setGroundData({
          ...groundData,
          coverImage: coverImage,
        });
      }
    }
  };

  const handlecoverImageDelete = async (props, index) => {
    const updatedata = [...groundData?.coverImage];
    updatedata.splice(index, 1);
    setGroundData({ ...groundData, coverImage: updatedata });
  };

  const handleGalleryClick = async (e) => {
    const name = "Gallery_IMG_" + new Date().getTime();
    let files = e.target.files[0];
    const extension = files.name.split(".").pop().toLowerCase();

    let Gallery_IMG_;
    if (files) {
      if (!allowedTypes.includes(extension)) {
        alert("Only JPEG,JPG, PNG and WEBP files are allowed.");
        files = "";
        return;
      } else {
        Gallery_IMG_ = await uploadFile(uid, name, files, "galleryImage");

        let galleryImage = groundData?.gallery;
        Array.isArray(galleryImage)
          ? galleryImage.push(Gallery_IMG_)
          : (galleryImage = [Gallery_IMG_]);
        Promise.resolve();
        setGroundData({
          ...groundData,
          gallery: galleryImage,
        });
      }
    }
  };

  const handlegalleryImageDelete = async (props, index) => {
    const updatedata = [...groundData?.gallery];
    updatedata.splice(index, 1);
    setGroundData({ ...groundData, gallery: updatedata });
  };

  const grndData = async () => {
    let user = await userData(uid);
    setDetails(user);

    if (data != null && groundData !== null) {
      let groundres = await getgroundDataById(data);

      setGroundData(groundres);
      setArenaDetails(groundres);

      settempstate(groundres);
      setTextAreas(groundres?.rules);
      setLoading(false);
    } else {
      setGroundData(createtempgroundData);
    }
    console.log(
      data,
      groundData,
      loader,
      "rerer",
      "grnddata",
      data != null,
      groundData == null
    );
  };

  const updateground = async () => {
    if (uid == null) {
      navigate("/login");
      return;
    }
    let createcity;
    let tempval = {
      groundname: false,
      phonenumber: false,
      email: false,
      groundtype: false,
      coverImage: false,
      game_type: false,
      includes: false,
      rules: false,
      amenities: false,
      gallery: false,
      city: false,
      state: false,
      street_address: false,
      pincode: false,
      start_time: false,
      end_time: false,
      active: false,
    };
    if (groundData?.groundname != "") {
      tempval.groundname = true;
    } else {
    }
    if (groundData?.groundtype != "") {
      tempval.groundtype = true;
    }
    if (groundData?.email != "") {
      tempval.email = true;
    }
    if (groundData?.phonenumber != "") {
      tempval.phonenumber = true;
    }
    if (groundData?.coverImage != []) {
      tempval.coverImage = true;
    }
    if (groundData?.street_address != "") {
      tempval.street_address = true;
    }
    if (groundData?.pincode != "") {
      tempval.pincode = true;
    }
    if (groundData?.state != "") {
      tempval.state = true;
    }
    if (groundData?.city != "") {
      tempval.city = true;
    }
    if (groundData?.game_type != []) {
      tempval.game_type = true;
    }
    if (groundData?.includes != []) {
      tempval.includes = true;
    }
    if (groundData?.rules != []) {
      tempval.rules = true;
    }
    if (groundData?.amenities != []) {
      tempval.amenities = true;
    }
    if (groundData?.gallery != []) {
      tempval.gallery = true;
    }
    if (groundData?.start_time != "") {
      tempval.start_time = true;
    }
    if (groundData?.end_time != "") {
      tempval.end_time = true;
    }
    if (groundData?.active != "") {
      tempval.active = true;
    }

    setvalData(Object.values(tempval).every(Boolean));
    if (Object.values(tempval).every(Boolean)) {
      if (details.owner) {
        let update = "";
        groundData.rules = textAreas;
        groundData.city = groundData.city.toLowerCase();
        update = await UpdateGroundData(groundData, data);
        console.log(typeof update.data, update, "rerer");
        createcity = await createCity({ cityName: groundData.city });
        if (update.status === "success") {
          toast.success("Update Success", {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error("Update Failed", {
            position: "top-right",
            autoClose: 2000,
          });
        }

        grndData();
      } else {
        console.log("not a owner");
      }
    }
  };
  const createground = async (groundData) => {
    let create;
    let createcity;
    let tempval = {
      groundname: false,
      phonenumber: false,
      email: false,
      groundtype: false,
      coverImage: false,
      game_type: false,
      includes: false,
      rules: false,
      amenities: false,
      gallery: false,
      city: false,
      state: false,
      street_address: false,
      pincode: false,
      start_time: false,
      end_time: false,
      active: false,
    };
    if (groundData?.groundname != "") {
      tempval.groundname = true;
    }
    if (groundData?.groundtype != "") {
      tempval.groundtype = true;
    }
    if (groundData?.email != "") {
      tempval.email = true;
    }
    if (groundData?.phonenumber != "") {
      tempval.phonenumber = true;
    }
    if (groundData?.coverImage != []) {
      tempval.coverImage = true;
    }
    if (groundData?.street_address != "") {
      tempval.street_address = true;
    }
    if (groundData?.pincode != "") {
      tempval.pincode = true;
    }
    if (groundData?.state != "") {
      tempval.state = true;
    }
    if (groundData?.city != "") {
      tempval.city = true;
    }
    if (groundData?.game_type != []) {
      tempval.game_type = true;
    }
    if (groundData?.includes != []) {
      tempval.includes = true;
    }
    if (groundData?.rules != []) {
      tempval.rules = true;
    }
    if (groundData?.amenities != []) {
      tempval.amenities = true;
    }
    if (groundData?.gallery != []) {
      tempval.gallery = true;
    }
    if (groundData?.start_time != "") {
      tempval.start_time = true;
    }
    if (groundData?.end_time != "") {
      tempval.end_time = true;
    }
    if (groundData?.active != "") {
      tempval.active = true;
    }

    setvalData(Object.values(tempval).every(Boolean));
    if (Object.values(tempval).every(Boolean)) {
      if (details.owner) {
        groundData.owner = uid;
        groundData.latitude = "9.92872166589861";
        groundData.longitude = "78.16099437904265";
        groundData.city = groundData.city.toLowerCase();

        setLoading(true);
        create = await createGroundData(groundData);
        createcity = await createCity({ cityName: groundData.city });

        setLoading(false);

        setGroundData(create);
        navigate("/courtadmin/dashboard");
        grndData();
      } else {
        console.log("not a owner");
      }
    }
  };

  const [textAreas, setTextAreas] = useState(
    groundData ? groundData?.rules : [""]
  );

  const handleAddTextArea = () => {
    setTextAreas([...textAreas, ""]);
  };

  const handleDeleteTextArea = (index) => {
    const updatedTextAreas = textAreas.filter((_, i) => i !== index);
    setTextAreas(updatedTextAreas);
  };

  const handleTextAreaChange = (index, value) => {
    const updatedTextAreas = [...textAreas];
    updatedTextAreas[index] = value;
    setTextAreas(updatedTextAreas);
  };

  useEffect(() => {
    grndData();
  }, []);

  const isowner = localStorage.getItem("isowner");
  useEffect(() => {
    if (uid != null) {
      if (isowner == "false") {
        navigate("/players/dashboard");
      }
    }
  }, [isowner, uid, navigate]);

  return (
    <div>
      {groundData ? (
        <div className="flex flex-col items-center justify-center mt-4 gap-4 scroll-smooth">
          <Modal open={loader}>
            <Box sx={style}>
              <Typography
                id="modal-modal-description"
                className="flex justify-center"
              >
                <CircularProgress style={{ color: "#018352" }} />
              </Typography>
            </Box>
          </Modal>
          <div className="bg-white flex min[769px]:flex-row max-[768px]:flex-col min[769px]:justify-between max-[768px]:justify-center px-4 items-center w-[90%] gap-2 rounded-lg">
            <div className="flex flex-row gap-2 overflow-x-auto whitespace-nowrap min[769px]:w-[80%] max-[768px]:w-full">
              {tabs?.map((item) => {
                return (
                  <CustomButton
                    text={item}
                    onClick={() => handleChange(item)}
                    sx={
                      tab == item
                        ? {
                            backgroundColor: "#192335",
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            fontSize: "14px",
                            overflow: "hidden",
                            textOverFlow: "ellipsis",
                            "&:hover": { bgcolor: "#192335" },
                            px: 8,
                            width: "100%",
                          }
                        : {
                            backgroundColor: "#F9F9F9",
                            color: "#898989",
                            borderRadius: "8px",
                            overflow: "ellipsis",
                            Padding: "0 4px",
                            overflow: "hidden",
                            textAlign: "start",
                            textOverFlow: "ellipsis",
                            "&:hover": { bgcolor: "#F9F9F9" },
                            px: 8,
                            width: "100%",
                          }
                    }
                  />
                );
              })}
            </div>
            {data == null ? (
              <div className="flex flex-row gap-2 whitespace-nowrap">
                <CustomButton
                  text={"Cancel"}
                  onClick={() => {
                    setGroundData(createtempgroundData);
                  }}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
                <CustomButton
                  text={"Add Arena"}
                  onClick={() => createground(groundData)}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-row gap-2 whitespace-nowrap">
                <CustomButton
                  text={"Reset"}
                  onClick={async () => {
                    setGroundData(tempstate);
                    handleChange("Basic Details");
                  }}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
                <Button
                  onClick={updateground}
                  sx={{
                    px: 1,
                    py: 1,
                    my: 2,
                    fontFamily: "Outfit",
                    "&.Mui-disabled": {
                      color: "#FFF",
                      backgroundColor: "#A9A9A9",
                    },
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                >
                  Update arena
                </Button>
              </div>
            )}
          </div>
          <div className="bg-white  w-[90%] rounded-lg" id="Basic Details">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Basic Details{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />
              <AccordionDetails>
                <Box sx={{ textAlign: "left" }}>
                  <Stack
                    sx={{ width: "100%" }}
                    direction={{ sm: "row", xs: "column" }}
                    gap={"16px"}
                  >
                    <Stack
                      sx={{
                        width: { sm: "33%", xs: "100%" },
                      }}
                    >
                      <Typography align="left">Arena Name</Typography>
                      <CustomTextField
                        value={groundData?.groundname}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            groundname: e,
                          });
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.groundname && !valData ? (
                        <Box className="text-[13px] text-red-600 ">
                          *Enter ground name
                        </Box>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Stack
                      sx={{
                        width: { sm: "33%", xs: "100%" },
                        textAlign: "left",
                      }}
                    >
                      <Typography align="left">Mobile Number</Typography>
                      <CustomTextField
                        value={groundData?.phonenumber}
                        onChange={(e) => {
                          if (e.length <= 10) {
                            setGroundData({
                              ...groundData,
                              phonenumber: e,
                            });
                          }
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.phonenumber && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Enter Phone number
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Stack sx={{ width: { sm: "33%", xs: "100%" } }}>
                      <Typography align="left">Email</Typography>
                      <CustomTextField
                        value={groundData?.email}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            email: e,
                          });
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.email && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Enter email id
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{ width: "100%" }}
                    direction={{ sm: "row", xs: "column" }}
                    gap={"16px"}
                  >
                    <Stack sx={{ width: { sm: "33%", xs: "100%" } }}>
                      <Typography align="left">Arena Type</Typography>
                      <CustomTextField
                        value={groundData?.groundtype}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            groundtype: e,
                          });
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.groundtype && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Enter ground type
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Stack sx={{ width: { sm: "33%", xs: "100%" } }}>
                      <Typography align="left">Opens At</Typography>

                      <CustomTextField
                        value={groundData?.start_time}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            start_time: e,
                          });
                        }}
                        type="time"
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.start_time && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Enter start time
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                    <Stack sx={{ width: { sm: "33%", xs: "100%" } }}>
                      <Typography align="left">Closes At</Typography>
                      <CustomTextField
                        value={groundData?.end_time}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            end_time: e,
                          });
                        }}
                        type="time"
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                        }}
                      />
                      {!groundData?.end_time && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Enter end time
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{ width: "100%" }}
                    direction={{ sm: "row", xs: "column" }}
                    gap={"16px"}
                  >
                    <Stack sx={{ width: { sm: "33%", xs: "100%" } }}>
                      <Typography align="left">
                        Arena - Active/Inactive
                      </Typography>
                      <CustomSelect
                        value={{
                          di: 1,
                          value: groundData?.active,
                          label:
                            groundData?.active == true ? "Active" : "Inactive",
                        }}
                        onChange={(e) => {
                          setGroundData({
                            ...groundData,
                            active: e.value,
                          });
                        }}
                        options={[
                          { value: true, label: "Active" },
                          { value: false, label: "Inactive" },
                        ]}
                        sx={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#B7B9BF",
                          border: "none",
                          "& fieldset": { border: "none" },
                          borderRadius: "8px",
                          fontSize: "14px",
                        }}
                      />
                      {!groundData?.active && !valData ? (
                        <div className="text-[13px] text-red-600">
                          *Select whether ground actve or not
                        </div>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg" id="Cover Image">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Cover Image{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />
              <AccordionDetails>
                <div className="flex flex-col items-start gap-2 w-full px-4 mb-4">
                  <input
                    id="uploadimg"
                    type="file"
                    className="hidden"
                    onChange={(e) => handleClick(e)}
                  />
                  <label
                    htmlFor="uploadimg"
                    className="h-[100px] w-full border-dashed border-[2px] flex flex-col gap-1 justify-center items-center rounded-md border-[#8F96A3] cursor-pointer"
                  >
                    <img
                      src={uploadicon}
                      alt="img icon"
                      className="w-[45px] h-[45px]"
                    />
                    <span className="text-[#707889] text-[14px]">
                      Upload Image
                    </span>
                  </label>
                </div>
                <div className=" flex justify-start gap-2">
                  {groundData?.coverImage?.map((item, index) => {
                    return (
                      <div className="relative ">
                        <img
                          src={deleteIcons}
                          alt="delt Icon"
                          className="absolute top-3 right-4"
                          onClick={() => handlecoverImageDelete(item, index)}
                        />
                        {/* <DeleteTwoToneIcon
                          sx={{
                            color: "#fafafa",
                            position: "absolute",
                            top: "5px",
                            right: "13px",
                          }}
                        /> */}
                        <img
                          src={item}
                          alt="cover image"
                          className="w-[100px] h-[100px] object-cover	"
                        />
                      </div>
                    );
                  })}
                </div>
                {groundData?.coverImage?.length == 0 && !valData ? (
                  <div className="text-[13px] text-red-600 text-left">
                    *Select Cover Image
                  </div>
                ) : (
                  ""
                )}
                <div className="items-start flex flex-col text-[#757C8D] text-[14px]">
                  <p>Put the main picture as first image</p>
                  <p>
                    Images should be minimum 270*312 supported file format JPG,
                    PNG, JPEG, WEBP
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="bg-white  w-[90%] rounded-lg pb-2"
            id="Sports Available"
          >
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Overview
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails>
                <Stack>
                  <textarea
                    placeholder="Overview"
                    value={groundData?.overview}
                    onChange={(e) => {
                      setGroundData({
                        ...groundData,
                        overview: e.target.value,
                      });
                    }}
                    className="w-full h-[100px] border-none bg-[#FAFAFA] rounded-[8px] focus:outline-none p-2"
                  ></textarea>
                  {!groundData?.overview && !valData ? (
                    <div className="text-[13px] text-red-600 text-left">
                      *Enter overview
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="bg-white  w-[90%] rounded-lg pb-2"
            id="Sports Available"
          >
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Sports Available{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails>
                <Stack>
                  <Stack direction={"row"} gap={2} sx={{ overflowX: "auto" }}>
                    {game_type?.map((item) => {
                      return (
                        <>
                          {" "}
                          <div
                            className="flex flex-col p-2 my-2 justify-center relative items-center w-[85px] h-[90px] rounded-xl border-1 border-[#F9F9F6] bg-[#F9F9F6] cursor-pointer"
                            onClick={() => handleGameclick(item)}
                          >
                            <img
                              className="w-[52px] h-[35px] mx-auto "
                              src={iconsss[item]}
                              alt="icon"
                            />
                            <p className="text-[#192335] mt-1 text-[12px] font-[500] whitespace-nowrap">
                              {item.replace("_", " ")}
                            </p>
                            {groundData?.game_type?.includes(item) && (
                              <img
                                src={tick}
                                alt="tick"
                                className="w-[15px] h-[15px]  absolute top-[-5px]  right-[-5px] z-10 rounded-full"
                              />
                            )}
                          </div>
                        </>
                      );
                    })}
                  </Stack>
                  {groundData?.game_type?.length == 0 && !valData ? (
                    <div className="text-[13px] text-red-600 text-left">
                      *Select game type
                    </div>
                  ) : (
                    ""
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg pb-2" id="Include">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Include{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails>
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{ flexWrap: "wrap", px: 1 }}
                >
                  {includes?.map((item) => (
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <img
                              className="w-[15px] h-[15px] mx-auto"
                              src={grayclickIcon}
                              alt="icon"
                            /> */}
                      <FormControlLabel
                        sx={{ color: "#757C8D" }}
                        control={
                          <Checkbox
                            checked={
                              groundData?.includes?.includes(item)
                                ? true
                                : false
                            }
                            size="small"
                            sx={{
                              color: "#192335",

                              fontWeight: 400,

                              "&.Mui-checked": {
                                color: "#097E52",
                              },
                            }}
                          />
                        }
                        label={item}
                        onChange={() => handleincludeclick(item)}
                      />
                    </FormGroup>
                  ))}
                </Stack>
                {groundData?.includes?.length == 0 && !valData ? (
                  <div className="text-[13px] text-red-600 text-left">
                    *Select includes
                  </div>
                ) : (
                  ""
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg pb-2" id="Rules">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Rules{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-col gap-2">
                    {textAreas?.map((text, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 w-full"
                      >
                        <textarea
                          className="border border-gray-300 rounded-md p-2 w-full"
                          value={text}
                          onChange={(e) =>
                            handleTextAreaChange(index, e.target.value)
                          }
                        />
                        <button
                          className="px-3 py-1   text-white rounded-md"
                          onClick={() => handleDeleteTextArea(index)}
                        >
                          <DeleteIcon sx={{ color: "#757C8D" }} />
                        </button>
                      </div>
                    ))}
                    <div className="flex w-full justify-end items-center">
                      <button
                        className="px-1 py-1 mt-4 bg-[#097E52] text-white rounded-md w-[15%] max-[566px]:w-[30%] justify-center items-center 	"
                        onClick={handleAddTextArea}
                      >
                        Add Rule
                      </button>
                    </div>
                  </div>{" "}
                  {groundData?.rules?.length == 0 && !valData ? (
                    <div className="text-[13px] text-red-600 text-left">
                      *Enter rules
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg pb-2" id="Amenities">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Amenities{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails>
                <Stack
                  direction={"row"}
                  gap={2}
                  sx={{ flexWrap: "wrap", px: 1 }}
                >
                  {amenities?.map((item) => (
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ color: "#757C8D" }}
                        control={
                          <Checkbox
                            checked={
                              groundData?.amenities?.includes(item)
                                ? true
                                : false
                            }
                            size="small"
                            sx={{
                              color: "#192335",

                              fontWeight: 400,

                              "&.Mui-checked": {
                                color: "#097E52",
                              },
                            }}
                          />
                        }
                        label={item}
                        onChange={() => handleamenitiesclick(item)}
                      />
                    </FormGroup>
                  ))}
                </Stack>
                {groundData?.amenities?.length == 0 && !valData ? (
                  <div className="text-[13px] text-red-600 text-left">
                    *Select amenities
                  </div>
                ) : (
                  ""
                )}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg" id="Gallery">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Gallery{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />
              <AccordionDetails>
                <div className="flex flex-col items-start gap-2 w-full px-4 mb-4">
                  <input
                    id="uploadgalimg"
                    type="file"
                    className="hidden"
                    onChange={(e) => handleGalleryClick(e)}
                  />
                  <label
                    htmlFor="uploadgalimg"
                    className="h-[100px] w-full border-dashed border-[2px] flex flex-col gap-1 justify-center items-center rounded-md border-[#8F96A3] cursor-pointer"
                  >
                    <img
                      src={uploadicon}
                      alt="img icon"
                      className="w-[45px] h-[45px]"
                    />
                    <span className="text-[#707889] text-[14px]">
                      Upload Image
                    </span>
                  </label>
                </div>
                {groundData?.gallery?.length == 0 && !valData ? (
                  <div className="text-[13px] text-red-600 text-left">
                    *Select images for gallery
                  </div>
                ) : (
                  ""
                )}
                <div className=" flex justify-start gap-2">
                  {groundData?.gallery?.map((item, index) => {
                    return (
                      <div className="relative ">
                        <img
                          src={deleteIcons}
                          alt="delt Icon"
                          onClick={() => handlegalleryImageDelete(item, index)}
                          className="absolute top-3 right-4"
                        />
                        <img
                          src={item}
                          alt="review image"
                          className="w-[100px] h-[100px] object-cover	"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="items-start flex flex-col text-[#757C8D] text-[14px]">
                  <p>Put the main picture as first image</p>
                  <p>
                    Images should be minimum 270*312 supported file format JPG,
                    PNG, JPEG, WEBP
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="bg-white  w-[90%] rounded-lg pb-2" id="Location">
            <Accordion
              defaultExpanded
              sx={{ width: "100%", borderRadius: "8px", padding: "0 0 4px 0" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Location{" "}
              </AccordionSummary>
              <Divider variant="middle" sx={{ mb: 3 }} />

              <AccordionDetails>
                <Stack
                  sx={{ width: "100%" }}
                  direction={{ sm: "row", xs: "column" }}
                  gap={"16px"}
                >
                  {/* <Maps /> */}
                  <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                    <Typography align="left">State</Typography>
                    <CustomTextField
                      value={_.startCase(groundData?.state)}
                      onChange={(e) => {
                        setGroundData({
                          ...groundData,
                          state: e,
                        });
                      }}
                      sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                    />
                    {!groundData?.state?.length && !valData ? (
                      <div className="text-[13px] text-red-600 text-left">
                        *Enter state
                      </div>
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Stack sx={{ width: { sm: "50%", xs: "100%" } }}>
                    <Typography align="left">City</Typography>
                    <CustomTextField
                      value={_.capitalize(groundData?.city)}
                      onChange={(e) => {
                        setGroundData({
                          ...groundData,
                          city: e,
                        });
                      }}
                      sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                    />
                    {!groundData?.city?.length && !valData ? (
                      <div className="text-[13px] text-red-600 text-left">
                        *Enter city
                      </div>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Stack>
                <Stack
                  sx={{ width: "100%" }}
                  direction={{ sm: "row", xs: "column" }}
                  gap={"16px"}
                >
                  <Stack
                    sx={{
                      width: { sm: "50%", xs: "100%" },
                      marginTop: { xs: "8px" },
                    }}
                  >
                    <Typography align="left">Street Address</Typography>
                    <CustomTextField
                      value={_.startCase(groundData?.street_address)}
                      onChange={(e) => {
                        setGroundData({
                          ...groundData,
                          street_address: e,
                        });
                      }}
                      sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                    />
                    {!groundData?.street_address?.length && !valData ? (
                      <div className="text-[13px] text-red-600 text-left">
                        *Enter street address
                      </div>
                    ) : (
                      ""
                    )}
                  </Stack>
                  <Stack
                    sx={{
                      width: { sm: "50%", xs: "100%" },
                      marginTop: { xs: "8px" },
                    }}
                  >
                    <Typography align="left">Pincode</Typography>
                    <CustomTextField
                      value={groundData?.pincode}
                      onChange={(e) => {
                        setGroundData({
                          ...groundData,
                          pincode: e,
                        });
                      }}
                      sx={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        color: "#B7B9BF",
                        border: "none",
                        "& fieldset": { border: "none" },
                        borderRadius: "8px",
                      }}
                    />
                    {!groundData?.pincode?.length && !valData ? (
                      <div className="text-[13px] text-red-600 text-left">
                        *Enter pincode
                      </div>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="flex  min[769px]:w-[80%] ">
            {data == null ? (
              <div className="flex gap-2 whitespace-nowrap w-[90%] justify-end">
                <CustomButton
                  text={"Cancel"}
                  onClick={() => {
                    setGroundData(createtempgroundData);
                  }}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
                <CustomButton
                  text={"Add Arena"}
                  onClick={() => createground(groundData)}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-row gap-2 whitespace-nowrap ">
                <CustomButton
                  text={"Reset"}
                  onClick={() => {
                    handleChange("Basic Details");
                    setGroundData(tempstate);
                  }}
                  sx={{
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                />
                <Button
                  onClick={updateground}
                  sx={{
                    px: 1,
                    py: 1,
                    my: 2,
                    fontFamily: "Outfit",
                    "&.Mui-disabled": {
                      color: "#FFF",
                      backgroundColor: "#A9A9A9",
                    },
                    backgroundColor: "#192335",
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#192335" },
                  }}
                >
                  Update arena
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <CircularProgress style={{ color: "#018352" }} />
      )}{" "}
      <ToastContainer />
    </div>
  );
}

export default CourtDetails;
