import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import LeftArrowIcon from '../../images/LeftArrowIcon.svg'
import RightArrowIcon from '../../images/RightArrowIcon.svg'

const CustomPagination = ({ currentPage, totalPages, onPageChange, ItemsPerPages, onChange }) => {


    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const handleItemsPerPageChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            onChange(value);
        }
    };

    return (
        <Stack direction="row" justifyContent={'space-between'} sx={{ fontFamily:'Outfit' }}>
            <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography>Show</Typography>
                <TextField
                    type="number"
                    value={ItemsPerPages}
                    onChange={handleItemsPerPageChange}
                    sx={{
                        '& input[type=number]': {
                            '-moz-appearance': 'textfield'
                        },
                        '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                        },
                        '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0
                        },
                        width:'100px',
                        padding:'0 !important',
                        ml:2,
                        borderRadius:'12px'
                    }}
                />
            </Stack>
            <Box>
                <Button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                    <img src={LeftArrowIcon} alt='leftArrow' />
                </Button>
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <Button key={page} sx={{ borderRadius: '50%', backgroundColor: ` ${currentPage === page ? '#097E52' : '#FAFAFA'}`, height: '27px !important', width: '27px !important', minWidth: '27px', color: ` ${currentPage === page ? '#FFF' : '#000'}`, mx: '4px' }} onClick={() => handlePageChange(page)}>
                        {page}
                    </Button>
                ))}

                <Button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                    <img src={RightArrowIcon} alt='leftArrow' />

                </Button>
            </Box>

        </Stack>
    );
};

CustomPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
