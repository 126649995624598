import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

export const statusMap = {
  Awaiting: {
    icon: <AccessTimeOutlinedIcon sx={{ fontSize: "12px" }} />,
    bgColor: "#E4DDF4",
    color: "#7756C9",
    cardColor: "#E4DDF4",
  },
  Ongoing: {
    icon: <AccessTimeOutlinedIcon sx={{ fontSize: "12px" }} />,
    bgColor: "#E0F6FF",
    color: "#45AEF4",
  },
  Completed: {
    icon: <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "12px" }} />,
    bgColor: "#D1F0D6",
    color: "#097E52",
  },
  Accepted: {
    icon: <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "12px" }} />,
    bgColor: "#D1F0D6",
    color: "#097E52",
    cardColor: "#D1F0D6",
  },
  Cancelled: {
    icon: <HighlightOffOutlinedIcon sx={{ fontSize: "12px" }} />,
    bgColor: "#FFF0F0",
    color: "#F50303",
  },
};
